import { useState } from 'react';
import { toast } from 'react-toastify';
import useCopyTextHook from '../../../hooks/useCopyTextHook';
import { usePlatformAction } from '../../../store/platform/platformAction';
import { RotatingLines } from 'react-loader-spinner';
import { ENV_VARS } from '../../../utils/constants';
import moment from 'moment';
import {
  CopyVoucher,
  QuantWiseLOgo,
  RedeemVoucher,
  ViewOffVoucher,
  ViewOnVoucher,
} from '../../../images/svgs';
import {
  voucherBgLines,
  voucherBottom,
  voucherLine1,
  voucherLine2,
  voucherSeperator,
  voucherStars,
  voucherTop,
} from '../../../images';
import { Meteors } from '../../../components/ui/Meteors';
import { useTranslation } from 'react-i18next';

interface Props {
  backgroundColor?: string;
  voucher: {
    expiryAt: Date;
    createdAt: Date;
    voucherCode: string;
    purchasedAt: string;
    redeemedAt: string;
    type: string;
    validity: string;
    _id: string;
  };
  redeemBtn: boolean;
  color: any;
}

const VoucherCardNew = ({ voucher, redeemBtn, color }: Props) => {
  const [visible, setVisible] = useState(false);
  const { writeClipboardText } = useCopyTextHook();
  const { platformLogin } = usePlatformAction();
  const [loading, setLoading] = useState(false);
  const handleCopy = async () => {
    writeClipboardText(voucher.voucherCode);
    toast.success('Copied to clipboard');
  };
  const handlePlatformLogin = async () => {
    setLoading(true);
    const res = await platformLogin(ENV_VARS.QUANTWISE_CONSTANT.externalId);
    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }
    setLoading(false);
  };
  const { t } = useTranslation();

  return (
    <div className="border rounded-3xl bg-[#fafafa] p-6 max-w-[385px] relative">
      <div
        className="flex flex-col gap-4 py-8 px-5 items-center relative overflow-hidden"
        style={{ backgroundImage: color.primary }}
      >
        <Meteors
          number={20}
          className="before:from-white before:!important"
        ></Meteors>
        <img
          src={voucherBgLines}
          alt="Voucher BG Lines"
          className={`absolute top-0 z-0 w-full`}
        />
        <img
          src={voucherStars}
          alt="Voucher starts"
          className={`absolute top-0 right-0 z-0`}
        />
        {/* <img
                    src={voucherLine1}
                    alt="Voucher Line 1"
                    className={`absolute -top-16 left-8 z-0`}
                />
                <img
                    src={voucherLine2}
                    alt="Voucher Line 2"
                    className={`absolute right-8 top-8 z-0`}
                /> */}
        <img
          src={voucherTop}
          alt="Voucher top"
          className={`absolute top-0 z-10 w-full h-auto px-1`}
        />
        <img
          src={voucherSeperator}
          alt="Voucher Seperator"
          className={`absolute bottom-20 z-20 w-full`}
        />
        <img
          src={voucherBottom}
          alt="Voucher bottom"
          className={`absolute -bottom-[2px] z-10 w-full h-auto px-1`}
        />
        <div className="z-10">
          <div
            className="p-3 rounded-2xl backdrop-blur-34"
            style={{ backgroundColor: color.mainBox }}
          >
            <div
              className="p-5 rounded-2xl shadow-voucherInnerShadow"
              style={{ backgroundColor: color.secondBox }}
            >
              <QuantWiseLOgo />
            </div>
          </div>
        </div>
        <div className="font-baumans font-normal text-lg z-10">
          {voucher?.type?.toUpperCase()}
        </div>
        <div
          className={`border border-white rounded-3xl flex flex-col w-full p-1 justify-center items-center z-10 ${redeemBtn ? 'gap-1' : ''}`}
        >
          <span className="font-normal text-sm text-darkGrey">
            {t('My Vouchers.card.voucherCode')}
          </span>
          <div className="flex flex-wrap gap-1">
            <span
              className={`font-semibold text-sm text-secondaryBlack ${!visible ? 'relative top-1' : ''}`}
            >
              {visible || !redeemBtn ? voucher.voucherCode : '*********'}
            </span>
            {visible && redeemBtn && (
              <button onClick={handleCopy}>
                <CopyVoucher className="flex-shrink-0" />
              </button>
            )}
            {redeemBtn && (
              <button onClick={() => setVisible(!visible)}>
                {visible ? (
                  <ViewOffVoucher className="flex-shrink-0" />
                ) : (
                  <ViewOnVoucher className="flex-shrink-0" />
                )}
              </button>
            )}
          </div>
        </div>
        <div className="flex gap-2 z-10">
          <span className="font-normal text-sm text-darkGrey">
            {t('My Vouchers.card.dateOfPurchase')}
          </span>
          <span className="font-semibold text-sm text-secondaryBlack">
            {moment(voucher.createdAt).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className="flex gap-2 z-10">
          <span className="font-normal text-sm text-darkGrey">
            {t('My Vouchers.card.validity')}
          </span>
          <span className="font-semibold text-sm text-secondaryBlack">
            {voucher?.validity?.toUpperCase()}
          </span>
        </div>
        <button
          className={`flex justify-center gap-2 rounded-3xl px-10 py-3 border backdrop-blur-174 drop-shadow-redeem_inset w-full z-10 mt-10 h-11 ${redeemBtn ? 'cursor-pointer' : 'bg-white cursor-default'}`}
          onClick={redeemBtn ? handlePlatformLogin : undefined}
        >
          {loading ? (
            <RotatingLines
              visible={true}
              width="15"
              strokeWidth="3"
              animationDuration="0.75"
              strokeColor={color.secondBox}
            />
          ) : (
            <>
              <span
                className={`font-bold text-sm ${redeemBtn ? 'text-darkGrey' : 'text-secondaryGreen'}`}
              >
                {redeemBtn ? t('general.redeem') : t('general.redeemed')}
              </span>
              {redeemBtn && <RedeemVoucher className="flex-shrink-0" />}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default VoucherCardNew;
