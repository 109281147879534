import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../images/svgs';
import { FEATURES } from '../../../utils/constants';

const AuthComponent = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const cloudKpassword = localStorage.getItem('cloudkPass');

  React.useEffect(() => {
    if (FEATURES.cloudKPassword) {
      if (!cloudKpassword) {
        navigate('/defi-360/cloudk/auth');
      }
    }
  }, []);

  return !FEATURES.cloudKPassword ? (
    <div>{children}</div>
  ) : cloudKpassword ? (
    <div>{children}</div>
  ) : (
    <div className="w-full h-32 flex items-center justify-center">
      <Spinner className="h-4 animate-spin text-primary" />
    </div>
  );
};

export default AuthComponent;
