import Marquee from 'react-fast-marquee';
import { useWindowSize } from '../../../utils/helper';
import { profileImg } from '../../../images';
import { LeaderCardLoader } from './LoadingShimmer';
import { useSelector } from 'react-redux';
import { selectLeaderBoard } from '../../../store/leadersboard/leaderBoardSlice';
import { useEffect } from 'react';
import { useLeaderBoardActions } from '../../../store/leadersboard/leaderBoardAction';
import ImageWithFallback from '../../Defi/Cloudk/Common/ImageWithFallback/ImageWithFallback';

const MarqueeLeaders = ({ selectedMainTab, selectedNestedTab }: { selectedMainTab: string, selectedNestedTab: string }) => {
  const {
    topTenLeadersLoading,
    topTenLeaders,
  } = useSelector(selectLeaderBoard);
  const { getTopTenLeaders } = useLeaderBoardActions()

  useEffect(() => {
    fetchTopFiftyLeaders();
  }, [selectedMainTab, selectedNestedTab])
  const fetchTopFiftyLeaders = () => {
    const filter =
      selectedMainTab === 'rewardSpecificLeaders'
        ? { type: selectedNestedTab, limit: 50, page: 1, filter: 'year' }
        : { limit: 50, page: 1, filter: 'year' };
    getTopTenLeaders(filter);
  }
  const size = useWindowSize();
  return (
    <Marquee
      gradient={size.width > 700}
      gradientColor="#FAFAFA"
      className="leaders"
      autoFill
      pauseOnHover
    >
      {topTenLeadersLoading ? (
        <div className="relative h-full w-full">
          < LeaderCardLoader />
        </div>
      ) : (
        topTenLeaders?.list?.map((leader: any, index: any) => (
          <LeaderCard
            name={leader?.user?.username}
            bid={leader?.user?.blockchainId}
            reward={leader?.tokenAmount}
            rank={leader?.rank}
            image={leader?.profilePicture || profileImg}
            tokenName={leader?.tokenName}
          />
        ))
      )
      }
    </Marquee>
  );
};

const LeaderCard = ({
  name,
  bid,
  reward,
  rank,
  image,
  tokenName
}: {
  name: string;
  bid: number;
  reward: number;
  rank: string;
  image: string;
  tokenName: string
}) => {
  return (
    <div className="p-4 bg-white rounded-lg border border-borderBlue w-72 shadow-lg mx-2">
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <div className="w-12 h-12 rounded-full overflow-hidden border border-primary">
            {/* <img
              src={image}
              alt="Image"
              className="object-cover w-ful h-full"
            /> */}
            <ImageWithFallback
              src={image}
              alt="Image"
              classNames="object-cover w-full h-full"
              fallbackSrc={profileImg}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{name}</span>
            <span className="text-xs text-primaryGrey">ID: {bid}</span>
            <span className="text-primary font-semibold italic">{Number(reward).toFixed(3)}<span className='text-xs ml-1'>{tokenName}</span></span>
          </div>
        </div>

        <div className="font-bold text-3xl italic text-primary/50">{rank}</div>
      </div>
    </div>
  );
};

export default MarqueeLeaders;
