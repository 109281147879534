import { JSX, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthHeader from './AuthHeader';
import PrelineSetup from '../PrelineSetup';
import { useSelector } from 'react-redux';
import { selectUserSlice } from '../../store/user/userSlice';
import { PAGES } from '../../utils/routers';
import { extractSSOParams } from '../../utils/helper';
import { FEATURES } from '../../utils/constants';

export default function AuthLayout(): JSX.Element {
  const navigate = useNavigate();
  const { user } = useSelector(selectUserSlice);

  useEffect(() => {
    if (user) {
      const ssoParams = localStorage.getItem('ssoUrl');
      if (ssoParams) {
        let params: any = extractSSOParams(ssoParams?.toString() as string);
        const { redirect_uri, scope, response_type, client_id } = params;
        if (redirect_uri && scope && response_type && client_id) {
          return navigate('/sso' + ssoParams, {
            replace: true,
          });
        }
      }
      navigate(PAGES.DASHBOARD);
    }
  }, [user]);

  return (
    <PrelineSetup>
      <div className="flex flex-col items-center min-h-screen gap-4 py-4 px-4 sm:px-12 lg:px-8">
        <AuthHeader />
        <div className="flex items-center justify-between w-full max-w-[1300px] p-0 md:gap-28 lg:gap-48 relative">
          <Outlet />
        </div>
      </div>
    </PrelineSetup>
  );
}
