import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallekActions } from '../store/wallek/walleKAction';
import PreLoader from '../components/Global/PreLoader';
import { selectWallekPoolSlice } from '../store/wallek/walleKSlice';
import { useSelector } from 'react-redux';

type ComponentProps = Record<string, any>;

const withWalleKAccess = <P extends ComponentProps>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    const [hasAccess, setHasAccess] = useState<boolean | null>(null);
    const navigate = useNavigate();

    const { getWallekStakeDetails } = useWallekActions();
    const { wallekStakedList } = useSelector(selectWallekPoolSlice);

    useEffect(() => {
      const getWallekStake = async () => {
        try {
          const response = await getWallekStakeDetails();

          const data = response?.data;
          if (response.status && data?.result?.length) {
            setHasAccess(true);
          } else {
            navigate('/');
          }
        } catch (error) {
          navigate('/');
        }
      };
      if (wallekStakedList && wallekStakedList?.result?.length) {
        setHasAccess(true);
      } else {
        getWallekStake();
      }
    }, []);

    if (hasAccess === null) {
      return <PreLoader />;
    }

    return <Component {...props} />;
  };
};

export default withWalleKAccess;
