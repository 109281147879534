import React, { ReactElement } from 'react';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';
import Tooltips from '../../../components/Tooltips';
import { InfoNobgIcon, PercentageUp, Spinner } from '../../../images/svgs';
import SuperNodeFilterTabs from './SuperNodeFilterTabs';
import { useTranslation } from 'react-i18next';

export interface RewardsMainCardProps {
  mainTitle: string;
  statistics: {
    title: string;
    tooltipContent: string;
    amount: number;
    statusPercentage: number;
  }[];
  layoutId: string;
  getData: any;
  isLoading?: boolean;
  selectedFilterTab?: any;
}

function RewardsMainCard({
  mainTitle,
  statistics,
  layoutId,
  getData,
  isLoading,
  selectedFilterTab,
}: RewardsMainCardProps): ReactElement {
  const onFilterChange = (event: string): void => {
    getData({ period: event });
  };
  const { t } = useTranslation();

  return (
    <HoverBorderAnimation className="flex flex-col flex-1 min-h-full rounded-[8px] border border-solid border-borderBlue bg-white radius-12px">
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-wrap justify-between items-center bg-gradient-primary-blue rounded-t-[8px] px-4 py-2 space-y-3 md:space-y-0">
          <p className="w-auto font-medium text-white text-md">
            {mainTitle}
          </p>
          <SuperNodeFilterTabs
            layoutID={layoutId}
            onFilterChange={onFilterChange}
            hoverTextColor={true}
            selectedFilter={selectedFilterTab}
          />
        </div>
        <div className="relative flex flex-wrap items-center justify-between px-4">
          {statistics.map(
            (stat, index): ReactElement => (
              <div key={index} className="py-4 space-y-2.5 w-auto">
                <div className="flex items-center gap-2">
                  <span className="text-xs">{stat.title}</span>
                  <Tooltips
                    postionClass={'mt-[-3px]'}
                    content={
                      <p className="p-2 text-xs bg-white rounded-lg shadow-md w-[150px] text-wrap">
                        {stat.tooltipContent}
                      </p>
                    }
                    classContainer={{
                      content: ['w-auto'],
                    }}
                    placement={index === 0 ? 'right' : 'left'}
                  >
                    <InfoNobgIcon className="w-3 h-3" />
                  </Tooltips>
                </div>
                <div className="font-medium xxs:text-xl xs:text-2xl md:text-lg lg:text-3xl xl:text-lg 2xl:text-3xl">
                  {stat.amount}{' '}
                  <span className="text-xs font-medium">
                    LYK-W
                  </span>
                </div>
                {/* <p className='flex gap-1 items-center font-rubik text-[12px]  font-normal'><span><PercentageUp className='w-7' /></span><span className="text-[#00B69B]">8.5%</span><span className="text-primaryGrey"> Up from Yesterday</span></p> */}
              </div>
            )
          )}
          {isLoading && (
            <div className="opacity-70 w-[100%] bg-white h-[100%] absolute top-1 flex justify-center items-center z-[50] ">
              <Spinner className="h-6 text-primary animate-spin" />
            </div>
          )}
        </div>
      </div>
    </HoverBorderAnimation>
  );
}

export default RewardsMainCard;
