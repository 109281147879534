import { SwiperSlide } from 'swiper/react';
import SimpleSlider from '../../../../../../../components/Slider/CardSlider';
import { Navigation } from 'swiper/modules';
import LicenseItem from '../../HardwareDetailModal/LicenseItem';
import { useCloudkActions } from '../../../../../../../store/cloudk/cloudkAction';
import { useEffect, useState } from 'react';
import { MinterDetails } from '../../../../Common/types';
import GlobalSkeleton from '../../../../../../../components/Global/GlobalSkeleton';
import { EmptyLicenseList } from '../../../../../../../images/wallets';
import { useTranslation } from 'react-i18next';

const MinterLinkedLicenses = ({
  minter,
  refreshLicenses,
  onRefresh,
}: {
  minter: MinterDetails;
  refreshLicenses: any;
  onRefresh?: () => void;
}) => {
  const [licenses, setLicenses] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [licenseLoading, setLicenseLoading] = useState<string | null>();
  const { getUserMinterLinkedLicenses, deLinkLicenseFunc } = useCloudkActions();
  const { t } = useTranslation();

  useEffect(() => {
    fetchLicenses();
  }, [refreshLicenses]);

  const fetchLicenses = async () => {
    setLoading(true);
    const response = await getUserMinterLinkedLicenses({
      page: 1,
      id: minter._id,
    });
    if (response.data.data?.list) {
      setLicenses(response.data.data.list);
    }
    setLoading(false);
  };

  const handleDelinkLicense = async (data: any) => {
    data.type = minter?.type;
    setLicenseLoading(data.licenseId);
    await deLinkLicenseFunc(data);
    setLicenseLoading(null);
    fetchLicenses();
    onRefresh?.();
  };

  const NoDataCompoent = (
    <div className="flex flex-col items-center w-full mt-3">
      <img
        src={EmptyLicenseList}
        alt="empty transactions"
        className="h-auto w-28"
      />
      <span className="mt-4 text-cardSecondaryText">
        {t('defi.myCloudk.licenses.noLinkedLicenses')}
      </span>
    </div>
  );

  return (
    <div className="">
      {licenses?.length ? (
        <SimpleSlider
          onSwiper={(swiper) => {}}
          className="w-full"
          spaceBetween={8}
          slidesPerView={'auto'}
          modules={[Navigation]}
          navigation={false} // Disable default Swiper navigation
          breakpoints={{
            768: {
              slidesPerView: 'auto',
            },
          }}
        >
          {loading
            ? renderLoading()
            : licenses.map((license, index) => (
                <SwiperSlide
                  key={index}
                  className="!w-[220px] h-[271px] cursor-grab bg-[#FFFFFF]"
                >
                  <LicenseItem
                    license={license}
                    deLinkLoading={true || licenseLoading === license._id}
                    deLinkLicenseFunc={(data) => handleDelinkLicense(data)}
                  />
                </SwiperSlide>
              ))}
        </SimpleSlider>
      ) : (
        NoDataCompoent
      )}
    </div>
  );

  function renderLoading() {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <SwiperSlide className="!w-[220px] !h-[262px]">
          <div className="h-[262px] w-[220px] border rounded-lg flex flex-col justify-center items-center">
            <GlobalSkeleton width={70} height={90} />
            <GlobalSkeleton width={120} height={16} />
            <GlobalSkeleton width={140} height={30} />
            <GlobalSkeleton width={140} height={60} className="mt-auto" />
          </div>
        </SwiperSlide>
      ));
  }
};

export default MinterLinkedLicenses;
