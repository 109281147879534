import { JSX } from 'react';

type TListingMemberDetails = {
  content: any;
  data: any;
};

export default function ListingMemberDetails({
  content,
  data,
}: TListingMemberDetails): JSX.Element {
  return <div className="flex flex-col gap-2 font-montserrat ">{content}</div>;
}
