/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useUserCheck from '../../hooks/useUserCheck';
import PrelineSetup from '../PrelineSetup';
import AuthHeader from './AuthHeader';
import AuthSliderWrapper from './AuthSliderWrapper';

export default function VerificationLayout(): JSX.Element {
  const { user, checkLoginValidation, fetchUser } = useUserCheck();
  useEffect(() => {
    checkLoginValidation();
  }, [user]);
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <PrelineSetup>
      <div className="flex flex-col gap-4 max-w-[1200px] lg:px-20 md:px-10 sm:px-8 px-4 items-center m-auto py-4">
        <AuthHeader />
        <div className="flex items-center justify-between w-full p-0 md:gap-20 lg:gap-32 py-14">
          <Outlet />
          <AuthSliderWrapper />
        </div>
      </div>
    </PrelineSetup>
  );
}
