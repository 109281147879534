import { NoDataImage } from '../../../images/Dashboard';

const NoData = () => {
    return (
        <div className="flex-1 flex justify-center h-full items-center w-full xxs:px-2 sm:px-0 md:py-10 lg:py-24">
            <div className="flex flex-col items-center justify-center gap-6">
                <div className="flex items-center justify-center h-auto xxs:w-40 sm:w-44">
                    <img src={NoDataImage} alt="empty transactions" />
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                    <span className="text-primary xxs:text-sm sm:text-[18px] font-medium">
                        Sorry, No Data Found
                    </span>
                </div>
            </div>
        </div>
    );
};

export default NoData;
