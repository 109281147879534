import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface user {
  _id: string;
  email: string;
  blockchainId: string;
  username: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
}

interface userList {
  rank: number;
  tokenName: string;
  profilePicture: string;
  user: user;
  tokenAmount: number;
  totalReward: number;
  createdAt: string;
}

interface topLeadersList {
  currentPage: number;
  list: userList[];
  totalCount: number;
  totalPages: number;
}

interface ILeaderBoardInitialState {
  topLeadersList: topLeadersList | null;
  topTenLeaders: topLeadersList | null;
  topThreeLeaders: topLeadersList | null;
  barGraphData: any | null;
  topLeadersListLoading: boolean;
  topTenLeadersLoading: boolean;
  topThreeLeadersLoading: boolean;
  barGraphDataLoading: boolean;
}

export const LEADER_BOARD_SLICE_NAME = 'leaderboard';

const initialState: ILeaderBoardInitialState = {
  topLeadersList: null,
  topTenLeaders: null,
  topThreeLeaders: null,
  barGraphData: null,
  topLeadersListLoading: false,
  topTenLeadersLoading: false,
  topThreeLeadersLoading: false,
  barGraphDataLoading: false,
};

const leaderBoardSlice = createSlice({
  name: LEADER_BOARD_SLICE_NAME,
  initialState,
  reducers: {
    setTopLeadersList: (state, action: PayloadAction<any>) => {
      return { ...state, topLeadersList: action.payload };
    },
    setMoreTopLeaders: (state, action: PayloadAction<any>) => {
      const leaderList =
        state.topLeadersList && state.topLeadersList?.list?.length
          ? state.topLeadersList?.list
          : [];

      const newData = action.payload.list || [];

      const uniqueDataMap = new Map();

      leaderList.forEach((item) => {
        uniqueDataMap.set(item.user._id, item);
      });

      newData.forEach((item: any) => {
        uniqueDataMap.set(item.user._id, item);
      });

      const mergedList = Array.from(uniqueDataMap.values());

      return {
        ...state,
        topLeadersList: {
          ...state.topLeadersList,
          ...action.payload,
          list: mergedList,
        },
      };
    },

    setTopTenLeaders: (state, action: PayloadAction<any>) => {
      return { ...state, topTenLeaders: action.payload };
    },
    setTopThreeLeaders: (state, action: PayloadAction<any>) => {
      return { ...state, topThreeLeaders: action.payload };
    },
    setTopLeadersLoading: (state, action: PayloadAction<any>) => {
      return { ...state, topLeadersListLoading: action.payload };
    },
    setTopTenLoading: (state, action: PayloadAction<any>) => {
      return { ...state, topTenLeadersLoading: action.payload };
    },
    setTopThreeLoading: (state, action: PayloadAction<any>) => {
      return { ...state, topThreeLeadersLoading: action.payload };
    },
    setGraphData: (state, action: PayloadAction<any>) => {
      return { ...state, barGraphData: action.payload };
    },
    setGraphDataLoading: (state, action: PayloadAction<any>) => {
      return { ...state, barGraphDataLoading: action.payload };
    },
  },
});

export const {
  setTopLeadersList,
  setGraphData,
  setGraphDataLoading,
  setTopLeadersLoading,
  setTopTenLoading,
  setTopTenLeaders,
  setMoreTopLeaders,
  setTopThreeLoading,
  setTopThreeLeaders,
} = leaderBoardSlice.actions;

export const selectLeaderBoard = (state: {
  [LEADER_BOARD_SLICE_NAME]: ILeaderBoardInitialState;
}) => state[LEADER_BOARD_SLICE_NAME];

export default leaderBoardSlice.reducer;
