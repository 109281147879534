import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface userList {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
}

interface myFirstLineUsers {
  currentPage: number;
  users: userList[];
  totalCount: number;
  totalPages: number;
}

interface IMyFirstLineUsersInitialState {
  myFirstLineUsers: myFirstLineUsers | null;
  myFirstLineUsersLoading: boolean;
}

export const MY_FIRST_LINE_USERS_SLICE_NAME = 'myFirstLineUsers';

const initialState: IMyFirstLineUsersInitialState = {
  myFirstLineUsers: null,
  myFirstLineUsersLoading: false,
};

const myFirstLineUsersSlice = createSlice({
  name: MY_FIRST_LINE_USERS_SLICE_NAME,
  initialState,
  reducers: {
    setMyFirstLineUsers: (state, action: PayloadAction<any>) => {
      return { ...state, myFirstLineUsers: action.payload };
    },
    setMyFirstLineUsersLoading: (state, action: PayloadAction<any>) => {
      return { ...state, myFirstLineUsersLoading: action.payload };
    },
  },
});

export const { setMyFirstLineUsers, setMyFirstLineUsersLoading } =
  myFirstLineUsersSlice.actions;

export const selectMyFirstLineUsers = (state: {
  [MY_FIRST_LINE_USERS_SLICE_NAME]: IMyFirstLineUsersInitialState;
}) => state[MY_FIRST_LINE_USERS_SLICE_NAME];

export default myFirstLineUsersSlice.reducer;
