import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PANDA_DOCS } from '../../utils/network/ApiEndpoints';
import { EmptyWithdrawHistoryGif } from '../../images/wallets';
// import { createEditorSession } from './createEditorSession';

const PandaDocs = () => {
    const [sessionId, setSessionId] = useState("tCN2smPTbfHQjSVfzfKJNL");
    const docsId = "mcGScubXhBQzMmyNvKTMBP"
    const recipient = "myzeest+pandadoc-1@gmail.com"

    const createEditorSession = async () => {
        const response = await axios.post(
            `${PANDA_DOCS}/${docsId}/session`,
            {
                recipient: recipient,
                lifetime: 900,
            },
            {
                headers: {
                    Authorization: `API-Key ${process.env.REACT_APP_PANDA_DOCS_API_KEY}`, // Replace with your API key
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data.id;
    };


    useEffect(() => {
        const fetchSessionId = async () => {
            const sessionId = await createEditorSession();
            setSessionId(sessionId);
        };

        fetchSessionId();
    }, []);

    if (!sessionId) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {sessionId ? <iframe
                src={`https://app.pandadoc.com/s/${sessionId}`} //session id comes from API
                width="100%"
                height="800px"
                frameBorder="0"
                title="PandaDoc Editor"
            /> :
                <div className="flex-1 flex justify-center items-center w-full xxs:px-2 sm:px-0 py-5 md:py-10 lg:py-24">
                    <div className="flex flex-col gap-6 items-center">
                        <div className="xxs:w-40 sm:w-44 h-auto">
                            <img
                                src={EmptyWithdrawHistoryGif}
                                alt="empty transactions"
                            />
                            <p className='text-center'>No Docs Found!</p>
                        </div>

                    </div>
                </div>
            }
        </>

    );
};

export default PandaDocs;