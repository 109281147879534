/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import ScoreHistoryCommunity from './_components/ScoreHistoryCommunity';
import UserSNGPCommunity from './_components/UserSNGPCommunity';
import SNGPRecords from './_components/SNGPRecords';
import TotalSNGP from './_components/TotalSNGP';
import SNGPPie from './_components/SNGPPie';
import { useSNGPPoolActions } from '../../../store/sngpPool/sngpPoolActions';
import { selectSNGPPoolSlice } from '../../../store/sngpPool/sngpPoolSlice';
import BannerComp from './_components/BannerComp';
import GlobalHeader from '../../../components/Global/GlobalHeader';
import { useTranslation } from 'react-i18next';

export default function SNGP(): JSX.Element {
  const {
    activity,
    activityLoading,
    totalClaimed,
    totalSngp,
    sngpRecords,
    userSNGPCommunityLoading,
    userSNGPCommunity,
    history,
    historyLoading,
    sngpBannerLoading,
    sngpBannerData,
    claimLoading,
    claimedDetails,
  } = useSelector(selectSNGPPoolSlice);

  const {
    getSNGPActivity,
    getSNGPCommunityGraph,
    getSNGPScoreHistory,
    getSNGPBannerData,
  } = useSNGPPoolActions();

  const fetchSNGPBanner = async () => {
    if (!sngpBannerLoading && !sngpBannerData) {
      await getSNGPBannerData();
    }
  };

  const fetchSNGPActivity = async () => {
    if (!activityLoading && !activity) {
      await getSNGPActivity();
    }
  };

  // const fetchSNGPScoreHistory = async () => {
  //   if (!historyLoading) {
  //     await getSNGPScoreHistory({ page: 1, limit: 10 });
  //   }
  // };
  const fetchSNGPCommunityGraph = async () => {
    if (!userSNGPCommunityLoading && !userSNGPCommunity) {
      await getSNGPCommunityGraph({ timeline: 'weekly' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSNGPBanner();
    fetchSNGPActivity();
    // fetchSNGPScoreHistory();
    fetchSNGPCommunityGraph();
  }, []);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full justify-between flex-col lg:flex-row gap-3">
        <div className="w-full lg:w-1/3">
          <GlobalHeader title={t('GlobalPool.sngpPools')} />
        </div>
        <div className="flex w-full lg:w-2/3 justify-between gap-3">
          <TotalSNGP
            title={t('GlobalPool.totalSngp')}
            totalSNGP={totalSngp?.totalSngp || 0}
            loading={activityLoading}
          />
          <TotalSNGP
            title={t('GlobalPool.totalSngpGenerated')}
            totalSNGP={totalClaimed?.globalSngpUsed || 0}
            loading={activityLoading}
          />
        </div>
      </div>
      {sngpBannerData?.totalSngpPoints ? <BannerComp /> : <></>}
      <div className="flex flex-row gap-4">
        <SNGPPie
          data={
            activity
              ? activity
              : {
                  balance: 0,
                  generated: 0,
                }
          }
          loading={activityLoading}
        />
        <SNGPRecords
          title={t('GlobalPool.sngpStatistics')}
          data={sngpRecords || []}
          loading={activityLoading}
          totalClaimed={totalClaimed?.globalSngpUsed || 0}
          claimedDetails={
            claimedDetails
              ? claimedDetails
              : {
                  claimedAmount: 0,
                  claimedDate: '',
                  rewardToken: '',
                }
          }
        />
      </div>
      <UserSNGPCommunity
        title={t('GlobalPool.userSngpIYCommunity')}
        data={userSNGPCommunity || []}
        loading={userSNGPCommunityLoading}
      />
      <ScoreHistoryCommunity
        title={t('GlobalPool.yourScoreHistory')}
        data={history}
        loading={historyLoading}
      />
    </div>
  );
}
