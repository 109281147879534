import { useCallback, useState } from 'react';
import Tabs from '../../components/Tabs';
import MarqueeLeaders from './_components/MarqueeLeaders';
import LeadersLineChart from './_components/LeadersLineChart';
import TopThreeLeaders from './_components/TopThreeLeaders';
import AllLeaders from './_components/AllLeaders';
import GlobalHeader from '../../components/Global/GlobalHeader';

const LeaderBoard = () => {
  const [selectedMainTab, setSelectedMainTab] =
    useState<string>('overallLeaders');
  const [selectedNestedTab, setSelectedNestedTab] =
    useState<string>('base-referral');

  const handleTabChange = useCallback(
    async (buttonText: string): Promise<void> => {
      setSelectedMainTab(buttonText);
    },
    []
  );
  const handleNestedTabChange = useCallback(
    async (buttonText: string): Promise<void> => {
      setSelectedNestedTab(buttonText);
    },
    []
  );

  const tabs: { key: string; label: string }[] = [
    {
      key: 'overallLeaders',
      label: 'Overall Leaders',
    },
    {
      key: 'rewardSpecificLeaders',
      label: 'Reward Specific Leaders',
    },
  ];

  const nestedTabs: { key: string; label: string }[] = [
    {
      key: 'base-referral',
      label: 'Base Referral',
    },
    {
      key: 'builder-generational',
      label: 'Builder Generational',
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <GlobalHeader title={'Leaders Board'} />
      <div className="flex flex-col items-start justify-between sm:items-center sm:flex-row">
        <Tabs
          layoutId="outerTab"
          selectedButton={selectedMainTab}
          handleButtonClick={handleTabChange}
          parentClassName="!px-0 !py-0 !text-nowrap !text-sm"
          className="sm:!py-2 !px-2 !text-bold"
          tabs={tabs}
        />
      </div>
      {selectedMainTab === 'rewardSpecificLeaders' && (
        <div className="flex flex-col items-start justify-between sm:items-center sm:flex-row">
          <Tabs
            layoutId="outerTab"
            selectedButton={selectedNestedTab}
            handleButtonClick={handleNestedTabChange}
            parentClassName="!px-0 !py-0 !text-nowrap !text-sm"
            className="sm:!py-2 !px-2 !text-bold"
            tabs={nestedTabs}
          />
        </div>
      )}
      <div className="flex flex-col gap-4 xl:flex-row">
        <LeadersLineChart
          selectedMainTab={selectedMainTab}
          selectedNestedTab={selectedNestedTab}
        />
        <TopThreeLeaders
          selectedMainTab={selectedMainTab}
          selectedNestedTab={selectedNestedTab}
        />
      </div>
      <MarqueeLeaders
        selectedMainTab={selectedMainTab}
        selectedNestedTab={selectedNestedTab}
      />
      <AllLeaders
        selectedMainTab={selectedMainTab}
        selectedNestedTab={selectedNestedTab}
      />
    </div>
  );
};

export default LeaderBoard;
