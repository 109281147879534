import { useDispatch } from 'react-redux';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  setMyFirstLineUsers,
  setMyFirstLineUsersLoading,
} from './myFirstLineSlice';
import { GET_MY_FIRST_LINE_USERS } from '../../utils/network/ApiEndpoints';

export const useMyFirstLineUsersAction = () => {
  const dispatch = useDispatch();

  const getMyFirstLineUsers = async (data?: any) => {
    const url = GET_MY_FIRST_LINE_USERS;
    dispatch(setMyFirstLineUsersLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
        params: data,
      })
      .then((response: any) => {
        const { data } = response;

        dispatch(setMyFirstLineUsers(data));
        return data;
      })
      .catch((error: any) => {
        dispatch(setMyFirstLineUsersLoading(false));
        return error;
      })
      .finally(() => {
        dispatch(setMyFirstLineUsersLoading(false));
      });
  };

  return {
    getMyFirstLineUsers,
  };
};
