/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import {
  selectSetting,
  TPlatformSettings,
} from '../../../store/setting/settingSlice';
import { TextHoverEffect } from '../../../utils/SvgHoverEffect';
import { Spinner } from '../../../images/svgs';
import { ReactComponent as DashboardBannerSVG } from '../../../SVGIcons/Dashboard/dashboardBanner.svg';

type Props = {
  membership: boolean;
  activeStep: string;
};

const DashboardBanner = ({ membership }: Props): ReactElement => {
  const [videoURL, setVideoURL] = useState<string>('');
  const { t } = useTranslation();

  const { platformSettings, getPlatformSettingsLoading } =
    useSelector(selectSetting);

  const isXl: boolean = useMediaQuery({ query: '(min-width: 1280px)' });

  useEffect((): void => {
    let VideoSource: string =
      platformSettings?.find(
        (video: TPlatformSettings): boolean =>
          video.category === 'dashboard' &&
          video.type === 'general' &&
          video.name === 'featured-video-url'
      )?.value || '';

    if (!VideoSource || !ReactPlayer.canPlay(VideoSource)) {
      VideoSource = 'https://www.youtube.com/watch?v=iVHY0JlhjXk';
    }
    setVideoURL(VideoSource);
  }, [platformSettings]);

  return (
    <>
      <section className=" relative h-auto xl:h-[376px] py-10">
        {/* Background SVG */}
        <div className="absolute inset-0 z-10 w-full h-full">
          <TextHoverEffect duration={0.1} svgElement={<DashboardBannerSVG />} />
        </div>
        {/* Gradient Overlay */}
        <div className="absolute inset-0 z-00 bg-gradient-to-t from-[#DEF7FF] to-white opacity-30" />

        <div className="w-full 2xl:w-[1200px] px-10 2xl:mx-auto flex lg:flex-row  items-center justify-between gap-6 2xl:gap-28 flex-col z-00 pointer-events-auto">
          <article className="relative flex flex-col justify-between w-full gap-2 pointer-events-auto z-00">
            <h1 className="text-3xl font-medium sm:text-3xl font-baumans">
              {t('dashboard.welcomeSection.welcomeTitle1')} <br />{' '}
              {t('dashboard.welcomeSection.welcomeTitle2')}
            </h1>
            <p className="text-base font-normal text-primary">
              {t('dashboard.welcomeSection.welcomeMessage')}
            </p>
            {/* {membership ? (
              <>
                <h1 className="text-3xl font-medium sm:text-3xl font-baumans">
                  Welcome to the World of LayerK and HomniFi!
                </h1>
                <p className="text-lg font-normal text-primary">
                  If you're reading these lines, it means you're ready to
                  actively step into the world of Crypto and Minting. I'll guide
                  you through the 5 fundamental steps that will allow you to
                  make technology work for you. Follow each step carefully and
                  start being part of this technological revolution!
                </p>
              </>
            ) : (
              <>
                <p className="text-[#0E173F] font-normal text-lg">
                  {t('dashboard.banner.journey_text_1')}
                </p>
                <h1 className="text-3xl font-normal sm:text-3xl font-baumans">
                  {t('dashboard.banner.journey_text_2')}
                  <br />
                  {t('dashboard.banner.journey_text_3')}
                  <br />
                </h1>
                <p className="text-[#0E173F] font-normal text-lg">
                  {t('dashboard.banner.journey_text_4')}
                </p>
                <p className="text-[#1B9FDA] font-normal text-lg">
                  {t('dashboard.banner.journey_text_5')}
                </p>
              </>
            )} */}
          </article>
          <article className="relative z-10 flex items-center justify-center w-full h-full overflow-hidden rounded-lg">
            {getPlatformSettingsLoading && !platformSettings ? (
              <Spinner className="w-16 h-16 animate-spin text-primary" />
            ) : (
              <ReactPlayer
                url={videoURL}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '24px',
                  zIndex: '99999',
                }}
                width={'100%'}
                height={isXl ? '200px' : '200px'}
                onError={(e): void => {
                  console.warn('A video error occurred:', e);
                }}
              />
            )}
          </article>
        </div>
      </section>
    </>
  );
};

export default DashboardBanner;
