import React, { useEffect } from 'react';

import { ReactComponent as ArrowDownIcon } from '../../SVGIcons/arrowDown.svg';
import { SelectBox } from '../ui/SelectBoxAnimations';
import { RotatingLines } from 'react-loader-spinner';
import UpgradeStakeButton from '../../pages/Defi/Cloudk/Common/UpgradeStakeButton';
import { useTranslation } from 'react-i18next';
// '../../pages/Defi/Cloudk/Common/UpgradeStakeButton/UpgradeStakeButton';

const GlobalSelectMui = ({
  name,
  label,
  required,
  placeholder,
  options,
  firstAutoSelected = true,
  setSelectedOption,
  selectedOption,
  placeHolder,
  disabled = false,
  showPropName = 'name',
  className = '',
  selectedValueClassName = '',
  loading = false,
  upgradeStakeBtn = false,
  upgradeStakeText = '',
}: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleSelectValue = (option: any) => {
    if (option) {
      setSelectedOption(option);
      setIsOpen(false);
    }
  };

  const filterOptions = (input: string) => {
    const filtered = options.filter((option: any) =>
      option.name.toLowerCase().includes(input.toLocaleLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    filterOptions(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedValueCheck = () => {
    if (firstAutoSelected)
      return options.length ? options[0][showPropName] : placeHolder;
    else return selectedOption ? selectedOption[showPropName] : placeHolder;
  };

  return (
    <div className="flex flex-col gap-2 text-sm">
      {label && (
        <div className="text-sm font-medium text-defaultBlack">
          {label} {required && <span className="text-redDanger">*</span>}
        </div>
      )}
      <div className="relative select-none">
        <SelectBox
          className={`border border-blueGrey py-2.5 px-2 text-xs rounded-lg w-full cursor-pointer truncate ${className}`}
          onClick={(e) => {
            if (!disabled) {
              toggleDropdown(e);
            }
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <span className={`${selectedValueClassName}`}>
            {selectedValueCheck()}
          </span>
        </SelectBox>

        <div
          ref={dropdownRef}
          className={`text-xs flex flex-col w-full rounded-xl shadow-lg absolute top-10 left-0 border border-blueGrey bg-white overflow-auto mt-2 max-h-[170px] ${isOpen ? 'translate-y-0 visible opacity-100' : '-translate-y-10 invisible opacity-0'} transition-all ease-in-out duration-300`}
          style={{ zIndex: 99 }}
        >
          <input
            type="text"
            placeholder={t('general.searchWithDots')}
            className="p-2 border-b outline-none border-blueGrey"
            value={searchTerm}
            onChange={handleInputChange}
          />

          {placeHolder && (
            <button
              disabled
              className="p-2 transition-all duration-300 ease-in-out border-b border-blueGrey hover:bg-gray-100"
              onClick={() => {}}
            >
              {placeHolder}
            </button>
          )}
          {filteredOptions.map((option: any, index: number) => {
            return (
              <button
                key={index}
                className="p-2 transition-all duration-300 ease-in-out border-b border-blueGrey hover:bg-gray-100"
                onClick={() => handleSelectValue(option)}
              >
                <div className="flex items-center gap-4">
                  {option.widget ?? option[showPropName]}
                  {upgradeStakeBtn && (
                    <>
                      <div> -</div>
                      <UpgradeStakeButton text={upgradeStakeText} />
                    </>
                  )}
                </div>
              </button>
            );
          })}
        </div>
        <div
          className="absolute transform -translate-y-1/2 right-4 top-1/2"
          style={{ zIndex: 10 }}
        >
          {loading ? (
            <div className="relative">
              <RotatingLines
                width="16"
                strokeWidth="3"
                animationDuration="0.75"
                strokeColor={'#382F10'}
              />
            </div>
          ) : (
            <ArrowDownIcon
              className={`w-3 h-3 transform ${isOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalSelectMui;
