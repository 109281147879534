import React from 'react';

const Note = () => {
  return (
    <ol className="list-[lower-alpha] px-4 text-xs flex flex-col gap-3">
      <li>
        <h5 className="uppercase">Note:</h5>
        <ol className="list-decimal">
          <li>
            To use certain tokens in TAG after converting them to T Balance, the
            ratio to the TAG deposit must be 50/50. *Example: To use $50 of T
            Balance in TAG, an additional $50 must be deposited into the
            corresponding TAG account. This corresponds to a total qualifying
            TAG deposit of $100. Additionally, a 12x account can be requested
            for the total amount. The same rule applies to any available account
            size.
          </li>
        </ol>
      </li>
      <li>
        <h5 className="uppercase">Procedure:</h5>
        <ol className="list-decimal">
          <li>
            Deposit the desired amount of USDT on TAG via USDT or bank transfer.
          </li>
          <li>Exchange the desired tokens for T-balance.</li>
          <li>
            Select the desired a account size and enter your TAG account details
            (pop -up window).
          </li>
          <li>
            Request the 12x account option (if you wish to use this feature) via
            TAG Support in the TAG back office
          </li>
        </ol>
      </li>
    </ol>
  );
};

export default Note;
