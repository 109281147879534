'use client';
import React, { useRef, useEffect, useState, ReactElement } from 'react';
import { motion } from 'framer-motion';

export const TextHoverEffect = ({
  svgElement, // SVG passed as a prop
  duration,
}: {
  svgElement: ReactElement; // Accept React element for SVG
  duration?: number;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);
  const [maskPosition, setMaskPosition] = useState({ cx: '50%', cy: '50%' });

  // Update mask position based on cursor
  useEffect(() => {
    if (svgRef.current && cursor?.x !== null && cursor?.y !== null) {
      const svgRect = svgRef.current.getBoundingClientRect();
      const cxPercentage = ((cursor?.x - svgRect.left) / svgRect.width) * 100;
      const cyPercentage = ((cursor?.y - svgRect.top) / svgRect.height) * 100;
      setMaskPosition({
        cx: `${cxPercentage}%`,
        cy: `${cyPercentage}%`,
      });
    }
  }, [cursor]);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseMove={(e) => setCursor({ x: e.clientX, y: e.clientY })}
      className="relative w-full h-full select-none"
    >
      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0 w-full h-full"
      >
        <defs>
          <linearGradient
            id="textGradient"
            gradientUnits="userSpaceOnUse"
            cx="50%"
            cy="50%"
            r="25%"
          >
            <stop offset="0%" stopColor={'#1B9FDA'} />
            <stop offset="100%" stopColor={'#1B9FDA'} />
          </linearGradient>
          {/* Define the radial gradient for glowing effect */}
          <motion.radialGradient
            id="revealMask"
            gradientUnits="userSpaceOnUse"
            r="20%"
            animate={maskPosition}
            transition={{ duration: duration ?? 0.3, ease: 'easeOut' }}
          >
            <stop offset="0%" stopColor="white" />
            <stop offset="100%" stopColor="black" />
          </motion.radialGradient>

          <mask id="svgMask">
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#revealMask)"
            />
          </mask>
        </defs>

        {svgElement}

        {hovered && (
          <g mask="url(#svgMask)">
            {React.cloneElement(
              svgElement as React.ReactElement<React.SVGProps<SVGElement>>,
              {
                fill: 'url(#textGradient)',
                stroke: '#1B9FDA',
              }
            )}
          </g>
        )}
      </svg>
    </div>
  );
};
