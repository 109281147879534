import { useDispatch, useSelector } from 'react-redux';
import {
  DAILY_SUPER_NODE_REWARDS,
  FRIENDS_ALL_HISTORY,
  FRIENDS_TOTAL_REWARDS,
  REWARDS_CHART,
  SUPER_NODE_PRODUCTION,
  SUPERNODE_REWARD_ANALYTICS,
  TOTAL_LOSS,
  TOTAL_GASK,
  SUPER_NODE_DAILY_REWARD,
  SUPER_NODE_FIRSTLINE_USERS,
  SUPER_NODE_MY_TREE,
  SUPER_NODE_SNGP_REWARDS,
  SUPER_NODE_MY_TREE_REWARDS,
  USER_DAILY_REWARDS,
  USER_Chart_Detail,
  USER_REWARD_HISTORY,
  SUPER_NODE_SUPER_NODE_REWARDS,
  SUPER_NODE_ACTIIVE_USER,
  TOTAL_PRODUCTION_SUMMARY_REWARDS,
  TOTAL_REWARD_SUMMARY,
  REWARD_SUMMARY,
  SUPER_NODE_BONUS_REPORTS,
  SUPERNODE_MY_REPORTS,
  SUPERNODE_BONUS_TOTAL,
  SUPER_NODE_TOTAL_REWARDS_CLAIMED,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import { toast } from 'react-toastify';
import {
  selectSuperNodeSlice,
  setSuperNodeSlice,
  setActiveFirstLineUser,
  setActiveFirstLineBuilderUser,
  setsuperNodeTotalRewadsData,
  setSuperNodeFilter,
} from './superNodeSlice';
import {
  setDepositSettings,
  setDepositStackSettings,
} from '../walletData/walletDataSlice.ts';

type TPaginateType = {
  page?: number;
  limit?: number;
  status?: string;
};

interface IGetMyFriendsHistory extends TPaginateType {
  query?: string;
  toDate?: any;
  fromDate?: any;
  type?: string;
  storeRedux?: boolean;
  level?: number;
  rewardLevel?: number;
  treeLevel?: number;
  date?: string;
  fromUser?: string;
  cloudKtrx?: string;
}

interface IGetRewardsTree extends IGetMyFriendsHistory {
  depth?: number | string;
  userId?: string | null;
  machine?: any;
}

export const useSuperNodeActions = () => {
  const dispatch = useDispatch();
  const { myTree } = useSelector(selectSuperNodeSlice);

  const getMyFriendsHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    storeRedux,
  }: IGetMyFriendsHistory) => {
    dispatch(
      setSuperNodeSlice({
        getMyFriendsHistoryLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: FRIENDS_ALL_HISTORY,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            allHistory: data,
            getMyFriendsHistoryLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getMyFriendsHistoryLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getFriendsTotalRewards = async () => {
    dispatch(
      setSuperNodeSlice({
        getTotalRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: FRIENDS_TOTAL_REWARDS,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            totalRewards: data,
            getTotalRewardsLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getTotalRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getRewardsChart = async (key?: string) => {
    return await ApiRequestHomnifi()
      .request(
        !key
          ? {
              method: 'GET',
              url: REWARDS_CHART,
            }
          : {
              method: 'GET',
              url: REWARDS_CHART,
              params: {
                timeline: key,
              },
            }
      )
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(setSuperNodeSlice({ rewardChart: data }));
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getTotalProduction = async () => {
    dispatch(
      setSuperNodeSlice({
        getProductionLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_PRODUCTION,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setSuperNodeSlice({
            superNodeProductData: data,
            getProductionLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getProductionLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getRewardAnalytics = async () => {
    dispatch(
      setSuperNodeSlice({
        getRewardAnalyticsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPERNODE_REWARD_ANALYTICS,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(setSuperNodeSlice({ superNodeRewardsAnalytics: data }));
        dispatch(
          setSuperNodeSlice({
            getRewardAnalyticsLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getMyFriendsHistoryLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getMyTree = async () => {};

  const getCurrentLossOfRewards = async ({ period = 'yesterday' } = {}) => {
    dispatch(
      setSuperNodeSlice({
        getCurrentLossOfRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: TOTAL_LOSS,
        params: {
          period: period,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            currentLossOfRewardsLoss: data,
            getCurrentLossOfRewardsLoading: false,
            currentLossOfRewardsLossFilter: period,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getCurrentLossOfRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getDailySuperNodeRewards = async () => {
    dispatch(
      setSuperNodeSlice({
        getDailySuperNodeRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: DAILY_SUPER_NODE_REWARDS,
      })
      .then((response) => {
        const { data } = response;
        dispatch(
          setSuperNodeSlice({
            dailySuperNodeRewards: data,
            getDailySuperNodeRewardsLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getDailySuperNodeRewardsLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getSuperNodeTotalRewardsClaimed = async () => {
    dispatch(
      setSuperNodeSlice({
        getSuperNodeTotalRewardsClaimedLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_TOTAL_REWARDS_CLAIMED,
      })
      .then((response) => {
        const { data } = response;
        dispatch(
          setSuperNodeSlice({
            superNodeTotalRewardsClaimed: data?.data,
            getSuperNodeTotalRewardsClaimedLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getSuperNodeTotalRewardsClaimedLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getTotalProductionSummary = async ({ period = 'yesterday' } = {}) => {
    dispatch(
      setSuperNodeSlice({
        getTotalProductionSummaryLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: TOTAL_PRODUCTION_SUMMARY_REWARDS,
        params: {
          period: period,
        },
      })
      .then((response) => {
        dispatch(
          setSuperNodeSlice({
            totalProductionSummaryRewards: response.data.data,
            getTotalProductionSummaryLoading: false,
            totalProductionSummaryRewardsFilter: period,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getTotalProductionSummaryLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getTotalRewardSummary = async ({ period = 'yesterday' } = {}) => {
    dispatch(
      setSuperNodeSlice({
        getTotalRewardSummaryLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: TOTAL_REWARD_SUMMARY,
        params: {
          period: period,
        },
      })
      .then((response) => {
        dispatch(
          setSuperNodeSlice({
            totalRewardSummary: response.data.data,
            getTotalRewardSummaryLoading: false,
            totalRewardSummaryFilter: period,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getTotalRewardSummaryLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getRewardSummary = async ({
    type = 'base-referral',
    period = 'yesterday',
  }) => {
    if (type === 'base-referral') {
      dispatch(
        setSuperNodeSlice({
          getRewardSummaryBaseReferralLoading: true,
        })
      );
    } else {
      dispatch(
        setSuperNodeSlice({
          getRewardSummaryBuilderGenerationalLoading: true,
        })
      );
    }

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: REWARD_SUMMARY,
        params: {
          type,
          period: period,
        },
      })
      .then((response) => {
        if (type === 'base-referral') {
          dispatch(
            setSuperNodeSlice({
              RewardSummaryBaseReferral: response.data.data,
              getRewardSummaryBaseReferralLoading: false,
              RewardSummaryBaseReferralFilter: period,
            })
          );
        } else {
          dispatch(
            setSuperNodeSlice({
              RewardSummaryBuilderGenerational: response.data.data,
              getRewardSummaryBuilderGenerationalLoading: false,
              RewardSummaryBuilderGenerationalFilter: period,
            })
          );
        }

        return response;
      })
      .catch((error) => {
        // dispatch(
        //   setSuperNodeSlice({
        //     getRewardSummaryLoading: false,
        //   })
        // );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getMyReport = async (params: any) => {
    return await ApiRequestHomnifi()
      .get(SUPERNODE_MY_REPORTS, { params: params ?? {} })
      .then((response) => {
        dispatch(setsuperNodeTotalRewadsData(response.data.data));
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getTotalGask = async () => {
    dispatch(
      setSuperNodeSlice({
        getTotalGaskLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: TOTAL_GASK,
      })
      .then((response) => {
        const {
          data: {
            data: { totalGaskAmout },
          },
        } = response;

        dispatch(
          setSuperNodeSlice({
            totalGask: totalGaskAmout,
            lastDayGask: response.data.data.lastDay.totalGaskAmout,
            getTotalGaskLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getTotalGaskLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getTotalBonus = async (params: any) => {
    dispatch(
      setSuperNodeSlice({
        getTotalBonusLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .get(SUPERNODE_BONUS_TOTAL, { params: params ?? {} })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setSuperNodeSlice({
            totalBonus: data.result,
            getTotalBonusLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getTotalBonusLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getSuperNodeDailyRewards = async () => {
    dispatch(
      setSuperNodeSlice({
        getSuperNodeRewardLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_DAILY_REWARD,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setSuperNodeSlice({
            dailySuperNodeRewards: data,
            getSuperNodeRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getTotalGaskLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getActiveFirstLineUsers = async ({
    page = 1,
    limit = 3,
    type = 'base-referral',
  }) => {
    dispatch(
      setSuperNodeSlice({
        getActiveFirstLineUsersLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_FIRSTLINE_USERS,
        params: {
          page,
          limit,
          type,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        if (type === 'base-referral') {
          dispatch(setActiveFirstLineUser(response.data.data));
        } else {
          dispatch(setActiveFirstLineBuilderUser(response.data.data));
        }
        dispatch(
          setSuperNodeSlice({
            getActiveFirstLineUsersLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getActiveFirstLineUsersLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const claimReward = async () => {
    dispatch(
      setSuperNodeSlice({
        claimRewardLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: '/supernode/claim',
      })
      .then((response) => {
        dispatch(
          setSuperNodeSlice({
            claimRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            claimRewardLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getBuilderReferralGrowth = async () => {
    const data = [
      { month: 'JAN', eligible: 3, notEligible: 0 },
      { month: 'FEB', eligible: 0, notEligible: 4 },
      { month: 'MAR', eligible: 8, notEligible: 0 },
      { month: 'APR', eligible: 0, notEligible: 4 },
      { month: 'MAY', eligible: 10, notEligible: 0 },
      { month: 'JUN', eligible: 0, notEligible: 6 },
      { month: 'JUL', eligible: 7, notEligible: 0 },
      { month: 'AUG', eligible: 0, notEligible: 1 },
      { month: 'SEP', eligible: 0, notEligible: 3 },
      { month: 'OCT', eligible: 12, notEligible: 0 },
      { month: 'NOV', eligible: 0, notEligible: 8 },
      { month: 'DEC', eligible: 0, notEligible: 1 },
    ];
    return data;
  };

  const getRewardsTableData = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    type,
    level,
    rewardLevel,
    treeLevel,
    storeRedux = true,
  }: IGetMyFriendsHistory) => {
    dispatch(
      setSuperNodeSlice({
        superNodeSuperNodeRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_SUPER_NODE_REWARDS,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
          type,
          level,
          rewardLevel,
          treeLevel,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        storeRedux &&
          dispatch(
            setSuperNodeSlice({
              superNodeSuperNodeRewards: data,
            })
          );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setSuperNodeSlice({
            superNodeSuperNodeRewardsLoading: false,
          })
        );
      });
  };

  const getBonusReports = async ({
    fromUser,
    page = 1,
    limit = 10,
    query,
    cloudKtrx,
    fromDate,
    toDate,
    date,
    type,
    level,
  }: IGetMyFriendsHistory) => {
    dispatch(
      setSuperNodeSlice({
        superNodeBonusReportsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_BONUS_REPORTS,
        params: {
          fromUser,
          page,
          limit,
          query,
          fromDate,
          toDate,
          date,
          type,
          level,
          cloudKtrx,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setSuperNodeSlice({
            superNodeBonusReports: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setSuperNodeSlice({
            superNodeBonusReportsLoading: false,
          })
        );
      });
  };

  const getRewardsTree = async ({
    page,
    limit,
    depth,
    userId,
  }: IGetRewardsTree) => {
    dispatch(
      setSuperNodeSlice({
        rewardTreeLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_MY_TREE,
        params: {
          depth,
          userId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        if (userId && data) {
          const updateChildren = (
            tree: any,
            userId: string,
            newChildren: any
          ) => {
            return tree.map((node: any) => {
              if (node.user._id === userId) {
                return {
                  ...node,
                  children: newChildren,
                };
              } else if (node.children && node.children.length > 0) {
                return {
                  ...node,
                  children: updateChildren(node.children, userId, newChildren),
                };
              } else {
                return node;
              }
            });
          };
          dispatch(
            setSuperNodeSlice({
              myTree: updateChildren(myTree, userId, data),
              rewardTreeLoading: false,
            })
          );
        } else {
          dispatch(
            setSuperNodeSlice({
              myTree: data,
              rewardTreeLoading: false,
            })
          );
        }

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getMyTreeLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getMyTreeRewards = async () => {
    dispatch(
      setSuperNodeSlice({
        getMyTreeLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_MY_TREE_REWARDS,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setSuperNodeSlice({
            myTreeData: data,
            getMyTreeLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getMyTreeLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getSngpScore = async () => {
    dispatch(
      setSuperNodeSlice({
        getSngpScoreLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPER_NODE_SNGP_REWARDS,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            sngpScoreResponse: data,
            getSngpScoreLoading: false,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getSngpScoreLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getUserDailyRewards = async (userId: string) => {
    dispatch(
      setSuperNodeSlice({
        getUserDailyRewardLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${USER_DAILY_REWARDS}/${userId}`,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            userDailyReward: data,
            getUserDailyRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getDailySuperNodeRewardsLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getUserChartDetail = async (key: string, userId: string | null) => {
    dispatch(
      setSuperNodeSlice({
        getUserChartDetailLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${USER_Chart_Detail}`,
        params: {
          timeline: key,
          id: userId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            userChartDetail: data,
            getUserChartDetailLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getUserChartDetailLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getUserRewardHistory = async ({
    userId,
    page = 1,
    limit = 10,
    fromDate,
    toDate,
    machine,
    type,
  }: IGetRewardsTree) => {
    dispatch(
      setSuperNodeSlice({
        getUserRewardHistoryLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${USER_REWARD_HISTORY}/${userId}`,
        params: {
          page,
          limit,
          fromDate,
          toDate,
          machine,
          type,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            userRewardHistory: data,
            getUserRewardHistoryLoading: false,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            getUserRewardHistoryLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getUserStatus = async () => {
    dispatch(
      setSuperNodeSlice({
        isUserLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${SUPER_NODE_ACTIIVE_USER}`,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setSuperNodeSlice({
            userActiveStatus: data,
            isUserLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setSuperNodeSlice({
            isUserLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const setSuperNodeFilters = (filters: any) => {
    dispatch(setSuperNodeFilter({ ...filters }));
  };
  return {
    getMyFriendsHistory,
    getFriendsTotalRewards,
    getRewardsChart,
    getTotalProduction,
    getMyTree,
    getSngpScore,
    getCurrentLossOfRewards,
    getDailySuperNodeRewards,
    getTotalGask,
    getActiveFirstLineUsers,
    getSuperNodeTotalRewardsClaimed,
    // getCurrentLossOfRewards,
    getMyTreeRewards,
    getBuilderReferralGrowth,
    getRewardsTableData,
    getRewardsTree,
    getRewardAnalytics,
    getSuperNodeDailyRewards,
    claimReward,
    getUserDailyRewards,
    getUserChartDetail,
    getUserRewardHistory,
    getUserStatus,
    getTotalProductionSummary,
    getTotalRewardSummary,
    getRewardSummary,
    getBonusReports,
    getMyReport,
    setSuperNodeFilters,
    getTotalBonus,
  };
};
