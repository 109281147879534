import React from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { totalLicienceWaterMark } from '../../../../../images/cloudk';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { useTranslation } from 'react-i18next';

const TotalLicenses = () => {
  const {
    totalLicenses,
    activeLicenses,
    inActiveLicenses,
    expiredLicenses,
    totalCountLoading,
  } = useSelector(selectCloudSlice);
  const { t } = useTranslation();

  return (
    <CardLayout className="md:col-span-6 xl:col-span-3 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow relative bg-[#FFFFFF]">
      {totalCountLoading && totalLicenses === 0 ? (
        <div className="flex justify-center items-center flex-1  h-full w-full">
          <div className="justify-center items-center self-center">
            <div className="flex flex-col gap-5">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        </div>
      ) : (
        <section className="flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <span className="font-medium text-[#616161]">
              {t('defi.myCloudk.rewardsSection.tNL')}:{' '}
            </span>
            <span className="text-4xl font-medium text-[#001540]">
              {totalLicenses}
            </span>
          </div>

          <div className="flex gap-2 flex-wrap">
            <div className="px-2 py-1 text-sm rounded-[100px] bg-[#E7F8F0] border border-[#0E9255] flex justify-center items-center text-[#0E9255]">
              {activeLicenses} {t('general.active')}
            </div>
            <div className="px-2 py-1 text-sm rounded-[100px] bg-[#FFF4F3] border border-[#F04438] flex justify-center items-center text-[#F04438]">
              {inActiveLicenses} {t('general.inactive')}
            </div>
            <div className="px-2 py-1 text-sm rounded-[100px] bg-[#FFF4F3] border border-[#af3f37b8] flex justify-center items-center text-[#af4139]">
              {expiredLicenses} {t('general.expired')}
            </div>
          </div>
        </section>
      )}

      <img
        src={totalLicienceWaterMark}
        alt={'water-mark'}
        className="absolute bottom-0 right-2 h-full"
      />
    </CardLayout>
  );
};

export default TotalLicenses;
