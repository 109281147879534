import GaugeChart from '../../../../../../components/Cloud/Test1Chart';
import CloudKButton from '../../../Common/CloudkButton/CloudkButton';
import LinkIcon from '@mui/icons-material/Link';
import CloudKTabs from '../../../Common/CloudKTabs';
import MinterDailyActivities from './components/MinterDailyActivities';
import MinterLinkedLicenses from './components/MinterLinkedLicenses';
import MinterRewardHistory from './components/MinterRewardHistory';
import { MinterDetails } from '../../../Common/types';
import MinterRewards from './components/MinterRewards';
import { useState } from 'react';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import GlobalModal from '../../../../../../components/Global/GlobalModal';
import LinkedLicense from '../../../LinkedLicense/LinkedLicense';
import { Button, Tooltip } from '@nextui-org/react';
import Tooltips from '../../../../../../components/Tooltips';
import MinterConnections from './components/MinterConnections';
import { useTranslation } from 'react-i18next';

const HardwareDetailsModalBody = ({
  minter,
  onRefresh,
}: {
  minter: MinterDetails;
  onRefresh?: () => void;
}) => {
  const [rebootLoading, setRebootLoading] = useState(false);
  const [linkLicenseModalOpen, setLinkLicenseModalOpen] = useState(false);
  const [refreshLicenses, setrefreshLicenses] = useState<any>({}); //pointer to refresh licenses
  const [refreshRewards, setRefreshRewards] = useState<any>({}); //pointer to refresh rewards
  const { rebootMinter } = useCloudkActions();

  const handleReboot = async () => {
    setRebootLoading(true);
    await rebootMinter(minter._id);
    setRebootLoading(false);
  };
  const { t } = useTranslation();
  const nftStakePercentage =
    100 - (minter?.totalLockNft / minter?.minterData.maxNFTCount) * 100;

  return (
    <>
      <div>
        <div className="px-[25px] py-[20px]">
          <div className="flex items-center gap-4">
            <div className="">
              <h1 className="text-lg md:text-[30px] font-medium">
                {minter?.minterData?.name}
              </h1>
            </div>
            <div className="flex gap-1">
              {minter?.type !== 'phone' && (
                <CloudKButton
                  text={t('defi.myCloudk.Hardwares.details.reboot')}
                  outlined
                  onClick={handleReboot}
                  loading={rebootLoading}
                />
              )}
              <Tooltips
                wrapperClassName="bg-white p-3 rounded-lg shadow text-sm"
                content={t('defi.myCloudk.Hardwares.details.toolTip')}
                isDisabled={nftStakePercentage > 0}
              >
                <CloudKButton
                  text={t('general.Link')}
                  disabled={nftStakePercentage <= 0}
                  onClick={() => setLinkLicenseModalOpen(true)}
                  prefixIcon={<LinkIcon fontSize="small" />}
                />
              </Tooltips>
            </div>
          </div>
          <div className="text-[#939393] pt-1 mt-2 md:mt-0">
            {t('defi.myCloudk.Hardwares.details.sl')}: {minter.serialNumber}
          </div>
          <hr className="my-4 md:my-6" />
          <MinterRewards
            minter={minter}
            onRefreshRewards={() => {
              setRefreshRewards({});
              onRefresh?.();
            }}
          />
          <hr className="my-4 md:my-6" />
          <div className="pt-6">
            <CloudKTabs
              tabs={[
                { key: '1', label: t('defi.myCloudk.Hardwares.details.daily') },
                {
                  key: '2',
                  label: t('defi.myCloudk.Hardwares.details.linked'),
                },
                {
                  key: '3',
                  label: t('defi.myCloudk.Hardwares.details.rewards'),
                },
                {
                  key: '4',
                  label: t('defi.myCloudk.Hardwares.details.connections'),
                },
              ]}
              minHeight={180}
              tabContnets={{
                1: <MinterDailyActivities minter={minter} />,
                2: (
                  <MinterLinkedLicenses
                    minter={minter}
                    refreshLicenses={refreshLicenses}
                    onRefresh={onRefresh}
                  />
                ),
                3: (
                  <MinterRewardHistory
                    minter={minter}
                    refreshRewards={refreshRewards}
                  />
                ),
                4: (
                  <MinterConnections
                    minter={minter}
                    refreshConnections={refreshLicenses}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <GlobalModal
        isOpen={linkLicenseModalOpen}
        size="3xl"
        title={t('defi.myCloudk.connectALicense')}
        headerClassName="!p-6 font-medium text-lg text-[#0E173F]"
        onClose={() => setLinkLicenseModalOpen(false)}
        classNames={{
          backdrop: '!z-[1000] bg-[#292f46]/50 backdrop-opacity-40',
          wrapper: '!z-[1001]',
        }}
      >
        <LinkedLicense
          minter={minter}
          handleClose={() => {
            setLinkLicenseModalOpen(false);
            setrefreshLicenses({});
            onRefresh?.();
          }}
        />
      </GlobalModal>
    </>
  );
};

export default HardwareDetailsModalBody;
