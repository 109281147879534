import { useEffect } from 'react';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHardwareMapColumns } from '../shared/ActivityHistoryTable/utils';
import HardwareTransactionHistory from '../CloudOverview/components/HarwareTransactionHistory/HardwareTransactionHistory';
import { t } from 'i18next';
import AuthComponent from '../../Auth/AuthComponent';

const RewardHistory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get('type');
  const hardwareMapColumns = useHardwareMapColumns();
  const { getMinterLicenses } = useCloudkActions();
  const navigate = useNavigate();
  useEffect(() => {
    getMinterLicenses({ minterId: undefined });
  }, []);

  return (
    <AuthComponent>
      <div>
        <div className="mb-11 flex flex-wrap items-center">
          <span
            onClick={() => navigate('/defi-360/cloudk')}
            className="text-xl font-baumans cursor-pointer"
          >
            {t('defi.myCloudk.overView')} {'>'} &nbsp;
          </span>
          <span className="text-2xl font-baumans">
            {`${t('dashboard.worksSection.subTitle_1')} ${t('defi.nodek.tHistory')}`}
          </span>
        </div>

        <HardwareTransactionHistory
          tabsData={hardwareMapColumns}
          limit={10}
          type={type}
        />
      </div>
    </AuthComponent>
  );
};

export default RewardHistory;
