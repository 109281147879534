/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useModalHook from '../../hooks/useModalHook';
import {
  FooterFacebook,
  FooterInstagram,
  FooterLinkedin,
  FooterYoutube,
  Logo,
  Spinner,
  TrustPilotIcon,
} from '../../images/svgs';
import { useCloudActions } from '../../store/Cloudk2/cloudk2Actions';
import { selectCloudSlice } from '../../store/Cloudk2/clouldk2Slice';
import { FOOTER_SOCIALS } from '../../utils/constants';
import DashboardBanner from './_components/DashboardBanner';
import MembershipPurchased from './_components/MembershipPurchased';
import Steps from './_components/Steps';
import { FloatingDock } from '../../components/ui/FloatingDock';
import TrustPilotModal from '../../components/Modal/TrustPilotModal';
import StarRating from '../../components/StarRating';
import { useWalletDataAction } from '../../store/walletData/walletDataAction';
import { useGeneralActions } from '../../store/general/generalActions';
import { useSuperNodeActions } from '../../store/superNode/superNodeActions';
import { selectSuperNodeSlice } from '../../store/superNode/superNodeSlice';
import { selectConvertedPriceLoading } from '../../store/general/generalSlice';
import { useSettingAction } from '../../store/setting/settingActions.ts';
import { selectSetting } from '../../store/setting/settingSlice.ts';
import XKComputerSteps from './_components/XKComputerSteps.tsx';
import { selectDashboard } from '../../store/dashboard/dashboardSlice.tsx';
import { useDashbaordActions } from '../../store/dashboard/dashboardActions.tsx';

const Dashboard = (): ReactElement => {
  const { userProducts } = useSelector(selectCloudSlice);

  const { getPlatformSettingsLoading, platformSettings } =
    useSelector(selectSetting);

  const { isUserCompletionStepsLoading, userCompletionSteps } =
    useSelector(selectDashboard);

  const { getUserCompletionSteps } = useDashbaordActions();

  const { getPlatformSettings } = useSettingAction();

  const { t } = useTranslation();

  const {
    machinePurchased,
    userPurchasedMachinesLoading,
    getAllProductsLoading,
    userMachinesLoading,
    machineList,
  } = useSelector(selectCloudSlice);

  const { isUserLoading, userActiveStatus } = useSelector(selectSuperNodeSlice);

  const isConvertedPriceLoading: boolean = useSelector(
    selectConvertedPriceLoading
  );

  const { getWalletDataWalletsList } = useWalletDataAction();

  const { getUserStatus } = useSuperNodeActions();

  const { getAllProducts, getUserMachinesList, getUserPurchasedMachines } =
    useCloudActions();

  const { GetConvertedPrice } = useGeneralActions();

  const { handleOpen } = useModalHook();
  const [activeStep, setActiveStep] = React.useState<string>('start');
  const [openTrustPilot, setOpenTrustPilot] = useState<boolean>(false);

  const fetchUserMachines = async (): Promise<void> => {
    await getUserPurchasedMachines();
  };

  useLayoutEffect((): void => {
    if (!machinePurchased) {
      fetchUserMachines();
      handleOpen();
    }
  }, []);

  useEffect((): void => {
    if (!userProducts) {
      getWalletDataWalletsList();

      if (!getPlatformSettingsLoading && !platformSettings) {
        getPlatformSettings();
      }
      if (!getAllProductsLoading && !userProducts) {
        getAllProducts();
      }
      if (!userMachinesLoading && !machineList) {
        getUserMachinesList();
      }
      if (!isConvertedPriceLoading) {
        GetConvertedPrice();
      }
      if (!isUserLoading && !userActiveStatus) {
        getUserStatus();
      }

      if (!isUserCompletionStepsLoading && !userCompletionSteps) {
        getUserCompletionSteps();
      }
    }
  }, []);

  const dockerItems: { title: string; icon: ReactElement; href: string }[] = [
    {
      title: 'Facebook',
      icon: <FooterFacebook className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.facebook || '',
    },
    {
      title: 'Instagram',
      icon: <FooterInstagram className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.instagram || '',
    },
    {
      title: 'LinkedIn',
      icon: <FooterLinkedin className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.linkedIn || '',
    },
    {
      title: 'Youtube',
      icon: <FooterYoutube className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.youtube || '',
    },
  ];

  return (
    <section className="flex flex-col justify-between w-full min-h-screen gap-8">
      <TrustPilotModal
        isOpen={openTrustPilot}
        onClose={() => {
          setOpenTrustPilot(false);
        }}
        title={t('trustPilot.yourFeedbackMattters')}
        message={t('trustPilot.greetsForChoosingHomnifi')}
        showConfetti={false}
        subMessage={t('trustPilot.striveToProvideTheBestExperience')}
      />
      <div>
        <DashboardBanner
          membership={
            machinePurchased ? machinePurchased.isMachinePurshaed : false
          }
          activeStep={activeStep}
        />
        <XKComputerSteps
          machinePurchased={
            machinePurchased ? machinePurchased.isMachinePurshaed : false
          }
        />
      </div>

      {/* {userPurchasedMachinesLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner className="w-16 h-16 animate-spin text-primary" />
        </div>
      ) : machinePurchased?.isMachinePurshaed ? (
        <MembershipPurchased />
      ) : (
        <Steps
          setStep={(value: string): void => {
            setActiveStep(value);
          }}
          activeStep={activeStep}
        />
      )} */}
      <div className="flex flex-col">
        <div className="flex items-center w-full h-20 lg:h-32 bg-[#DEF7FF]">
          <div className="flex justify-center w-full 2xl:w-[1200px] m-auto px-10">
            <Logo className="h-6 lg:h-10" />
          </div>
        </div>
        <div className="relative items-end w-full h-30 screen510:flex bg-gradient-to-r from-primary to-primaryLight">
          <div className="flex justify-center flex-grow sm:justify-normal">
            <FloatingDock items={dockerItems}></FloatingDock>
          </div>
          <div
            className="flex items-end justify-center gap-2 pb-2 pr-3 cursor-pointer sm:py-2"
            onClick={() => {
              setOpenTrustPilot(true);
            }}
          >
            <div className="text-sm font-normal text-white whitespace-nowrap">
              {t('alerts.Leave Your Review')}
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-end gap-1">
                <TrustPilotIcon />
                <span className="text-sm font-medium text-white sm:text-xl">
                  Trustpilot
                </span>
              </div>
              <StarRating rating={4.5} />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center 2xl:w-[1200px] m-auto px-10 py-2 text-xs md:text-sm text-center">
          {t("footer.copyright.text", { PLATFORM_NAME: "HOMNIFI" })}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
