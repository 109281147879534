import React from 'react';

const EditButton = ({ isDisabled, onClick, text, icon }: any) => {
  return (
    <button
      onClick={isDisabled ? null : onClick}
      className={`flex items-center justify-center border gap-2 rounded-md md:py-2 h-9 md:h-10 font-normal text-xs py-[10px] px-5 min-w-max
                ${isDisabled ? 'bg-gray-200 text-gray-500 border-gray-300 cursor-not-allowed opacity-50' : 'bg-white text-primary border-primary hover:bg-gray-100'}`}
      disabled={isDisabled}
    >
      {React.cloneElement(icon, {
        className: ` ${isDisabled ? 'fill-gray-500' : 'fill-primary'}`, // Adjust the icon color based on the state
      })}
      {text}
    </button>
  );
};

export default EditButton;
