import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { TBalanceWalletList } from '../types/tBalance.types';
import { setDecimalPlaces } from '../utils/helper';

interface CustomSelectProps {
  options: TBalanceWalletList[];
  placeholder?: string;
  onChange: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder,
  onChange,
}) => {
  const DROPDOWN_WIDTH = 300;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [dropdownStyles, setDropdownStyles] = useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });

  const handleSelect = (value: string) => {
    setSelected(value);
    onChange(value);
    setIsOpen(false);
  };
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Calculate dropdown position
  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      let left = rect.left + window.scrollX;

      // Ensure dropdown does not overflow off the screen
      const viewportWidth = window.innerWidth;
      if (left + DROPDOWN_WIDTH > viewportWidth) {
        left = viewportWidth - DROPDOWN_WIDTH - 10; // Adjust to fit inside the screen
      }

      setDropdownStyles({
        top: rect.bottom + window.scrollY, // Adjust for scroll position
        left,
      });
    }
  }, [isOpen]);

  return (
    <>
      <button
        ref={buttonRef}
        type="button"
        className="px-6 py-2 text-base font-medium text-black bg-white border border-gray-300 rounded shadow-sm text-nowrap"
        onClick={() => setIsOpen(!isOpen)}
      >
        {placeholder}
      </button>

      {isOpen &&
        createPortal(
          <div
            ref={dropdownRef}
            className="absolute z-50 bg-white border border-gray-200 rounded-lg shadow-lg"
            style={{
              top: dropdownStyles.top,
              left: dropdownStyles.left,
              minWidth: `${DROPDOWN_WIDTH}px`, // Custom width
            }}
          >
            {options.length > 0
              ? options?.map((option, index) => (
                  <div
                    key={index}
                    className="flex justify-between px-4 py-2 rounded-lg cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSelect(option?.walletBalance?.symbol)}
                  >
                    <span>{option?.walletBalance?.name}</span>
                    <span className="text-xs text-gray-500">
                      Balance:{' '}
                      {setDecimalPlaces(option?.walletBalance?.balance, 2)}
                    </span>
                  </div>
                ))
              : []}
          </div>,
          document.body
        )}
    </>
  );
};

export default CustomSelect;
