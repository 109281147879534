import { useEffect } from 'react';
import AllHardwares from './components/AllHardwares';
import AllLicenses from './components/AllLicenses';
import Heading from './components/Heading';
import RewardAnalytics from './components/RewardAnalytics';
import RewardGenerated from './components/RewardGenerated';
import TopMachines from './components/TopMachines';
import TotalLicenses from './components/TotalLicenses';
import TotalLinkedMlyk from './components/TotalLinkedMlyk';
import TotalRewardGenerated from './components/TotalRewardGenerated';
import TransactionHistory from './components/TransactionHistory/TransactionHistory';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../store/cloudk/cloudkSlice';
import ActivityHistoryTable from '../shared/ActivityHistoryTable';
import { ReactComponent as ClockAndCalendar } from '../../../../SVGIcons/cloud/clockAndCalendar.svg';
import { usePlatformAction } from '../../../../store/platform/platformAction';
import { ENV_VARS } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';

const CloudkOverview = () => {
  const { claimableRewardData, totalLicenses, totalStacked, allHArdwaresData } =
    useSelector(selectCloudSlice);
  const {
    getActiveLicensesCountFunc,
    getTotalLinkedFunc,
    cloudKClaimableReward,
  } = useCloudkActions();
  useEffect(() => {
    if (!claimableRewardData) {
      cloudKClaimableReward();
    }
    if (!totalLicenses) {
      getActiveLicensesCountFunc();
    }

    if (Object.keys(totalStacked).length === 0) {
      getTotalLinkedFunc();
    }
  }, []);

  return (
    <>
      {allHArdwaresData?.find(
        (item: any) => item.softwareUpgradeStatus === 'pending'
      ) && <UpgradeSoftwareBanner />}
      <div className="flex flex-col gap-4 p-4">
        <Heading />
        <article className="grid-cols-12 gap-4 space-y-5 lg:space-y-0 lg:grid">
          <RewardAnalytics />
          <RewardGenerated />
        </article>

        <article className="grid-cols-12 gap-4 space-y-5 lg:space-y-0 lg:grid">
          <TotalLicenses />
          <TotalRewardGenerated />
          <TotalLinkedMlyk />
          <TopMachines />
          {/* <TotalLinked /> */}
          {/* <TotalStaked /> */}
        </article>
        <article className="grid-cols-12 gap-4 space-y-5 lg:space-y-0 lg:grid">
          <AllLicenses />
        </article>

        <article className="grid-cols-12 gap-4 space-y-5 lg:space-y-0 lg:grid">
          <AllHardwares />
        </article>
        {/* <TransactionHistory /> */}
        <ActivityHistoryTable limit={5} />
      </div>
    </>
  );
};

const UpgradeSoftwareBanner = () => {
  const { platformLogin } = usePlatformAction();
  const { t } = useTranslation();

  const loginAndRedirectToHorysMall = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const res = await platformLogin(ENV_VARS.HORYSMALL_CONSTANT.externalId);
    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }
  };

  return (
    <div className="bg-[#FFF9DD] flex flex-col md:flex-row items-center gap-x-3 gap-y-2 md:gap-y-0 py-3 md:py-2 px-4 w-full">
      <div className="flex items-center gap-4">
        <div className="w-8 h-8">
          <ClockAndCalendar />
        </div>
        <p className="text-[#B79600] text-xs">
          {t('defi.myCloudk.upgradeSoftwareBannerMessage')}
        </p>
      </div>
      <button
        onClick={loginAndRedirectToHorysMall}
        className="bg-[#B79600] text-white text-xs py-2 px-6 rounded"
      >
        {t('defi.myCloudk.upgradeSoftwareBannerButton')}
      </button>
    </div>
  );
};

export default CloudkOverview;
