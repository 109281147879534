import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import lock from '../../../images/Dashboard/lock.png';
import Divider from '../../../components/Divider';
import { selectDashboard } from '../../../store/dashboard/dashboardSlice';
import XKComputerStepsData from './XKComputerStepsData';
import { Spinner } from '../../../images/svgs';
import { tBalanceWatermark } from '../../../images/wallets';

interface Props {
  machinePurchased: boolean;
}

const XKComputerSteps = ({ machinePurchased }: Props) => {
  const { t } = useTranslation();

  const stepsData = XKComputerStepsData(false);

  const { isUserCompletionStepsLoading, userCompletionSteps } =
    useSelector(selectDashboard);

  const [selectedStep, setSelectedStep] = useState<number | null>(null);

  const selectedStepIndex = useMemo(() => {
    if (!isUserCompletionStepsLoading && userCompletionSteps) {
      const firstIncompleteIndex = userCompletionSteps.findIndex(
        (step) => !step.status
      );
      return firstIncompleteIndex !== -1
        ? firstIncompleteIndex
        : userCompletionSteps.length - 1;
    }
    return 0;
  }, [isUserCompletionStepsLoading, userCompletionSteps]);

  return (
    <div
      className={`px-4 ${machinePurchased ? 'xl:-mt-16' : 'xl:-mt-5'} lg:px-8`}
    >
      <div
        className="relative z-10 flex flex-col w-full gap-4 p-4 m-auto overflow-hidden bg-white shadow-md rounded-3xl md:p-12"
        style={{ boxShadow: '0px 3px 13.9px 0px rgba(27, 159, 218, 0.30)' }}
      >
        <div className="flex items-center justify-center h-[70px] sm:h-[100px] w-full">
          {stepsData?.map((step, index) => {
            const isFuture = index > selectedStepIndex;
            const isCompleted = index < selectedStepIndex;
            const isSelected = index === (selectedStep ?? selectedStepIndex);

            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <div className="flex justify-center w-full mx-1">
                    <div
                      className={`w-full h-1 rounded-full lg:h-2 ${isFuture ? 'bg-gray-300' : 'bg-primary'}`}
                    />
                  </div>
                )}
                <div className="flex items-center justify-center w-full gap-1">
                  <div
                    className="relative h-[100px] flex justify-center items-center cursor-pointer"
                    onClick={() => {
                      if (!isFuture || (isFuture && index < 2)) {
                        setSelectedStep(index);
                      }
                    }}
                  >
                    <img
                      src={step.image}
                      className={`w-[120px] ${isFuture ? 'grayscale opacity-50' : ''} ${isSelected ? 'drop-shadow-[0_0_1rem_#89D5ED]' : ''}`}
                    />
                    {isFuture && index > 1 && (
                      <img
                        src={lock}
                        className="absolute top-11 sm:top-10 lg:top-7 h-[10px] sm:h-[20px] lg:h-[40px]"
                      />
                    )}
                    <div
                      className={`absolute top-6 md:top-4 lg:-top-2 left-0 ${isFuture ? 'grayscale opacity-50' : ''} ${
                        isCompleted
                          ? 'bg-primary text-white'
                          : 'border border-primary text-primary'
                      } rounded-full flex items-center justify-center text-[10px] w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 lg:text-sm`}
                    >
                      {index + 1}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>

        <div className="mt-0 lg:mt-5">
          <Divider />
        </div>

        <div className="relative">
          <h2 className="mt-3 mb-5 text-lg font-semibold md:text-2xl">
            <span className="text-primary">
              {t('dashboard.step') + ' '}
              {(selectedStep ?? selectedStepIndex) + 1}:
            </span>{' '}
            {stepsData[selectedStep ?? selectedStepIndex]?.title}
          </h2>
          <span className="relative z-10">
            {stepsData[selectedStep ?? selectedStepIndex]?.innerHTML}
          </span>
          <img
            src={tBalanceWatermark}
            alt="watermark"
            className="absolute top-0 right-0 z-0"
          />
        </div>

        {isUserCompletionStepsLoading && (
          <div className="opacity-70 w-[95%] bg-white h-full absolute top-1 flex justify-center items-center z-[50]">
            <Spinner className="h-6 text-primary animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

export default XKComputerSteps;
