import Tooltips from '../../../components/Tooltips';
import {
  CommunityIconStroke,
  InfoNobgIcon,
  PercentageUp,
} from '../../../images/svgs';

export interface BonusStatisticsProps {
  title: string;
  tooltipContent: string;
  amount: number;
  statusPercentage?: number;
  startIcon?: any;
  endIcon?: any;
}

function BonusStatistics({
  title,
  tooltipContent,
  amount,
  statusPercentage,
  startIcon,
  endIcon,
}: BonusStatisticsProps) {
  return (
    <div className="py-4 w-auto space-y-2.5">
      <div className="flex items-center gap-2">
        <span className="text-xs">{title}</span>
        <Tooltips
          postionClass={'mt-[-3px]'}
          content={
            <p className="p-2 text-xs bg-white rounded-lg shadow-md w-[150px] text-wrap">
              {tooltipContent}
            </p>
          }
          classContainer={{
            content: ['w-auto'],
          }}
          // placement={index === 0 ? 'right' : 'left'}
        >
          <InfoNobgIcon className="w-3 h-3" />
        </Tooltips>
      </div>
      <div
        className={` ${startIcon && 'flex items-center gap-2'} font-medium xxs:text-xl xs:text-2xl md:text-lg lg:text-3xl xl:text-lg 2xl:text-3xl`}
      >
        {startIcon && <span>{startIcon} </span>} {amount}{' '}
        {endIcon && <span className="ml-1 text-xs font-medium">{endIcon}</span>}
      </div>
      {/* <p className='flex gap-1 items-center font-rubik text-[12px]  font-normal'><span><PercentageUp className='w-7' /></span><span className="text-[#00B69B]">8.5%</span><span className="text-primaryGrey"> Up from Yesterday</span></p> */}
    </div>
  );
}

export default BonusStatistics;
