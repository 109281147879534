import React, { ReactElement } from 'react';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';
import SuperNodeFilterTabs from './SuperNodeFilterTabs';
import BonusStatistics from './BonusStatistics';
import { Spinner } from '../../../images/svgs';
import { useTranslation } from 'react-i18next';

export interface BonusMainCardProps {
  mainTitle: string;
  statisticsUser: {
    title: string;
    tooltipContent: string;
    amount: number;
    statusPercentage: number;
    startIcon?: any;
    endIcon?: any;
  }[];
  statisticsRewards: {
    title: string;
    tooltipContent: string;
    amount: number;
    statusPercentage: number;
    startIcon?: any;
    endIcon?: any;
  }[];
  layoutId: string;
  getData: (event: string) => void;
  isLoading?: boolean;
  selectedFilterTab?: any;
}

function BonusMainCard({
  mainTitle,
  statisticsUser,
  statisticsRewards,
  layoutId,
  getData,
  isLoading,
  selectedFilterTab,
}: BonusMainCardProps): ReactElement {
  const { t } = useTranslation();

  return (
    <HoverBorderAnimation className="min-h-full flex flex-col flex-1 rounded-[8px] border border-solid border-borderBlue bg-white radius-12px">
      <div className="box-border flex flex-col justify-between">
        <div className="flex flex-wrap justify-between items-center bg-gradient-primary-blue rounded-t-[8px] px-4 py-2 space-y-3 lg:space-y-0">
          <p className="w-auto font-medium text-white text-md">
            {mainTitle}
          </p>
          <SuperNodeFilterTabs
            layoutID={layoutId}
            onFilterChange={(event: string): void => getData(event)}
            hoverTextColor={true}
            selectedFilter={selectedFilterTab}
          />
        </div>
        <div className="relative">
          <div className="flex flex-wrap items-center justify-between px-4">
            {statisticsUser.map((stat, index) => (
              <BonusStatistics
                key={index}
                title={stat.title}
                amount={stat.amount}
                tooltipContent={stat.tooltipContent}
                startIcon={stat.startIcon}
                endIcon={stat.endIcon}
              />
            ))}
          </div>
          <hr />
          <div className="flex flex-wrap items-center justify-between px-4">
            {statisticsRewards.map((stat, index) => (
              <BonusStatistics
                key={index}
                title={stat.title}
                amount={stat.amount}
                tooltipContent={stat.tooltipContent}
                startIcon={stat.startIcon}
                endIcon={stat.endIcon}
              />
            ))}
          </div>
          {isLoading && (
            <div className="opacity-70 w-[100%] bg-white h-[100%] absolute top-1 flex justify-center items-center z-[50] ">
              <Spinner className="h-6 text-primary animate-spin" />
            </div>
          )}
        </div>
      </div>
    </HoverBorderAnimation>
  );
}

export default BonusMainCard;
