import { useState } from 'react';
import { copy, tick } from '../../images/others';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type Props = {
  cellValue: any;
  copyAllowed?: boolean;
};

const CopyItemsView = ({ cellValue, copyAllowed = true }: Props) => {
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);
  const writeClipboardText = async (text: string) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
        toast.success(t('alerts.copiedSuccessfully'));
      } else {
        fallbackWriteClipboardText(text);
      }
    } catch (error) {
      fallbackWriteClipboardText(text);
    }
  };

  const fallbackWriteClipboardText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // For mobile devices

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error('Fallback copy to clipboard failed: ', error);
    }

    document.body.removeChild(textArea);
  };
  return (
    <div className="flex items-center gap-2">
      {cellValue?.length > 4
        ? cellValue.slice(0, 4) +
        '......' +
        cellValue.slice(Math.max(6, cellValue?.length - 8))
        : !cellValue
          ? '-'
          : cellValue}
      {cellValue && copyAllowed && (

        <img
          src={isCopied ? tick : copy}
          alt="icon"
          className="cursor-pointer"
          onClick={(event) => {
            // stopping the event propagation
            event.stopPropagation();
            // Write the cell value to the clipboard
            writeClipboardText(cellValue || '');

            // Set the copied state to true to show the tick icon
            setIsCopied(true);

            // Reset the copied state back to false after 1 second
            setTimeout(() => {
              setIsCopied(false);
            }, 1000);
          }}
        />

      )}
    </div>
  );
};

export default CopyItemsView;
