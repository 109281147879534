import { useEffect, useState } from 'react';
import MemberCard from '../MemberCard';
import Subordinates from '../Subordinates';
import { useRandomColor } from '../../../hooks/useRandomColor';
import {
  selectMyTreeSlice,
  setTreeExpand,
} from '../../../store/myTree/myTreeSlice';
import { useDispatch, useSelector } from 'react-redux';
import useUserCheck from '../../../hooks/useUserCheck';

const MemberContainer = ({ member, isPopup, expand = false }: any) => {
  const randomColor = useRandomColor();

  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [childs, setChilds] = useState<any>(member.children);

  const { user } = useUserCheck();
  const { supernodeTreeSearch } = useSelector(selectMyTreeSlice);

  const toggleCollapse = async (data: any) => {
    setCollapsed(!collapsed);

    if (collapsed && data?.firstLineNode > 0) {
      setChilds(data?.children);

      return;
    }
  };

  useEffect(() => {
    if (member?.firstLineNode > 0) {
      (async () => {
        if (member?.firstLineNode > 0) {
          setChilds(member?.children);

          return;
        }
      })();
    }
  }, [expand, member]);

  useEffect(() => {
    if (supernodeTreeSearch) {
      if (!expand) setCollapsed(true);
      if (expand && member?.children?.length > 0) setCollapsed(false);
    } else {
      if (!expand) {
        setCollapsed(true);
      }
    }
  }, [expand, supernodeTreeSearch]);

  useEffect(() => {
    if (user?.id === member.blockchainId && collapsed) {
      dispatch(setTreeExpand(false));
    }
  }, [collapsed]);

  return (
    <div className="flex flex-col items-center justify-center pb-3">
      <MemberCard
        member={member}
        collapsed={collapsed}
        toggleCollapse={toggleCollapse}
        isPopup={isPopup}
        randomColor={randomColor}
      />
      {!collapsed ? (
        <div className="border-l-2 border-current border-dashed h-9"></div>
      ) : (
        ''
      )}
      {!collapsed ? (
        <div
          className="border-t-2 border-current border-dashed"
          style={{ width: 'calc(100% - 250px)' }}
        ></div>
      ) : (
        ''
      )}
      {!collapsed ? (
        <div>
          {member?.firstLineNode > 0 ? (
            <Subordinates members={childs} isPopup={isPopup} />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default MemberContainer;
