export const ShimmerLoader = () => {
  return (
    <div className="flex flex-col gap-2 w-full min-w-[1000px]">
      {/* Desktop View */}
      <div className="hidden md:flex w-full justify-between items-center border border-primary rounded-lg px-4 py-2 shadow-myFirstLineCardShadow animate-pulse">
        <div className="flex gap-3 items-center flex-1">
          <div className="w-[40px] h-[40px] bg-gray-300 rounded-full border border-primary"></div>
          <div className="w-24 h-4 bg-gray-300 rounded"></div>
        </div>
        <div className="flex flex-col gap-1 flex-1 items-center">
          <div className="w-12 h-3 bg-gray-300 rounded"></div>
          <div className="w-20 h-4 bg-gray-400 rounded"></div>
        </div>
        <div className="flex flex-col gap-1 flex-1 items-center">
          <div className="w-12 h-3 bg-gray-300 rounded"></div>
          <div className="w-32 h-4 bg-gray-400 rounded"></div>
        </div>
        <div className="flex flex-col gap-1 flex-1 items-center">
          <div className="w-12 h-3 bg-gray-300 rounded"></div>
          <div className="w-16 h-4 bg-gray-400 rounded"></div>
        </div>
        {/* <div className="flex flex-col gap-1 flex-1 items-center">
                    <div className="w-12 h-3 bg-gray-300 rounded"></div>
                    <div className="w-24 h-6 bg-gray-400 rounded"></div>
                </div> */}
      </div>

      {/* Mobile View */}
      <div className="screen480:flex screen480:flex-row flex-shrink-0 items-center gap-5 md:hidden w-full rounded-lg border-2 border-primary px-6 py-3 animate-pulse">
        <div className="w-[70px] h-[70px] bg-gray-300 rounded-full border-2 border-primary"></div>
        <div className="flex flex-col gap-1 mt-2 screen480:mt-0">
          <span className="w-32 h-4 bg-gray-300 rounded"></span>
          <span className="w-24 h-3 bg-gray-300 rounded"></span>
          <span className="w-40 h-3 bg-gray-300 rounded"></span>
          <span className="w-20 h-3 bg-gray-300 rounded"></span>
          {/* <span className="w-32 h-6 bg-gray-400 rounded"></span> */}
        </div>
      </div>
    </div>
  );
};
