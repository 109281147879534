import { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import MinterPlaceholder from '../../../../../assets/minter-placeholder.png';
import CardLayout from '../../../../../components/Cards/CardLayout';
import GlobalModal from '../../../../../components/Global/GlobalModal';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import useModalHook from '../../../../../hooks/useModalHook';
import { EmptyHardwareList } from '../../../../../images/wallets';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { ReactComponent as LinkIcon } from '../../../../../SVGIcons/cloud/link.svg';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import ConnectNewMinter from './ConnectNewMinter/ConnectNewMinter';
import ConnectThroughQR from './ConnectThroughQR/ConnectThroughQR';
import HardwareDetailModal from './HardwareDetailModal/HardwareDetailModal';
import { ReactComponent as ArrowRight } from '../../../../../SVGIcons/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import HardwareDetailsModalBody from './HardwareDetailsModalV2';
import { useTranslation } from 'react-i18next';
import { usePlatformAction } from '../../../../../store/platform/platformAction';
import { ENV_VARS } from '../../../../../utils/constants';
const AllHardwares = () => {
  const sliderRef = useRef<any>(null);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [minter, setMinter] = useState<any>({});
  const [phoneMinter, setPhoneMinter] = useState({});

  const {
    open: openConnectQRModal,
    handleClose: handleCloseConnectQRModal,
    handleOpen: handleOpenConnectQRModal,
  } = useModalHook();

  const {
    open: openConnectModal,
    handleClose: handleCloseConnectModal,
    handleOpen: handleOpenConnectModal,
  } = useModalHook();
  const {
    open: openDetailModal,
    handleClose: handleCloseDetailModal,
    handleOpen: handleOpenDetailModal,
  } = useModalHook();

  const { allHardwareLoading, allHArdwaresData } =
    useSelector(selectCloudSlice);

  const { getAllHardwares } = useCloudkActions();

  const { platformLogin } = usePlatformAction();

  const handlePrev = () => {
    if (sliderRef.current && currentIndex > 0) {
      sliderRef.current.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (sliderRef.current && !isAtEnd) {
      sliderRef.current.slideNext();
      setCurrentIndex(currentIndex + 1);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (!allHArdwaresData) {
      getAllHardwares();
    }
  }, []);
  useEffect(() => {
    if (minter?._id) {
      // Find the updated minter from the new `allHArdwaresData`
      const updatedMinter = allHArdwaresData?.find(
        (item: any) => item._id === minter._id
      );
      if (updatedMinter) {
        setMinter(updatedMinter); // Update the state with the latest `minter`
      }
    }
  }, [allHArdwaresData, minter?._id]);

  const loginAndRedirectToHorysMall = async () => {
    const res = await platformLogin(ENV_VARS.HORYSMALL_CONSTANT.externalId);
    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }
  };

  return (
    <CardLayout className=" col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow bg-[#FFFFFF]">
      <section className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-secondaryBlack sm:text-lg">
            {t('defi.myCloudk.hardwares')}
          </h1>
          {allHArdwaresData?.length > 0 && (
            <div
              className=" top-5 justify-end right-5 bottom-[-6px] md:bottom-5 md:right-[10px] gap-1 flex cursor-pointer"
              onClick={() => {
                navigate('/defi-360/cloudk/minters');
              }}
            >
              <span className="text-sm font-normal">{t('general.vAll')}</span>
              <div className="w-5 h-5">
                <ArrowRight />
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="relative">
        <div className="flex gap-2 overflow-x-hidden ">
          {allHardwareLoading && allHArdwaresData?.length === 0 ? (
            <div className="flex items-center justify-center flex-1 w-full c">
              <div className="items-center self-center justify-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="h-6 animate-spin" />
                </div>
              </div>
            </div>
          ) : allHArdwaresData?.length > 0 ? (
            <>
              <SimpleSlider
                onSwiper={(swiper) => {
                  sliderRef.current = swiper;
                }}
                onSlideChange={(swiper) => {
                  setCurrentIndex(swiper.activeIndex);
                  setIsAtEnd(swiper.isEnd); // Update end status here
                }}
                className="w-full cursor-grab"
                spaceBetween={10}
                slidesPerView={'auto'}
                modules={[Navigation]}
                navigation={false} // Disable default Swiper navigation
                breakpoints={{
                  768: {
                    slidesPerView: 'auto',
                  },
                }}
              >
                {allHArdwaresData
                  ?.slice(0, 10)
                  .map((item: any, index: number) => {
                    return (
                      <SwiperSlide
                        key={index}
                        className="!w-[220px] h-full pt-8 bg-[#FFFFFF]"
                      >
                        <div
                          style={{
                            background:
                              item.softwareUpgradeStatus === 'pending'
                                ? 'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)'
                                : '',
                            border:
                              item.softwareUpgradeStatus !== 'pending'
                                ? '1px solid #D9E4F1'
                                : '',
                          }}
                          className={` p-[2px] relative rounded-lg h-64`}
                        >
                          {item.softwareUpgradeStatus === 'pending' && (
                            <div
                              style={{
                                background:
                                  'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)',
                              }}
                              className="absolute z-20 flex items-center justify-center w-32 min-h-6 h-auto -translate-x-1/2 shadow-lg cursor-pointer -top-3 left-1/2 rounded-xl "
                              onClick={loginAndRedirectToHorysMall}
                            >
                              {' '}
                              <span className="text-xs font-medium text-white">
                                {t('defi.myCloudk.upgradeSoftwareBannerButton')}
                              </span>
                            </div>
                          )}
                          <div
                            key={index}
                            className={`bg-white p-3 w-[216px] h-full rounded-lg relative flex flex-col justify-between gap-3`}
                          >
                            <div className="flex justify-center w-full mt-2">
                              <img
                                src={
                                  item.minterData?.documents[0]?.link ||
                                  MinterPlaceholder
                                }
                                alt="machine-image"
                                className="h-[103px] w-[176px] object-contain"
                                onError={(e: any) => {
                                  e.target.src = MinterPlaceholder;
                                }}
                              />
                            </div>

                            <div className="flex flex-col items-center w-full gap-2">
                              <div className="flex flex-col items-center justify-center gap-2">
                                <div className="flex items-center gap-1">
                                  <span className="font-medium text-[#121212]">
                                    {item?.minterData?.name}
                                  </span>
                                  <div className="relative w-2 h-2 ml-1">
                                    <span className="flex w-2 h-2 gap-2">
                                      <span
                                        className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
                                          item?.minterData?.status === 'active'
                                            ? 'bg-primaryGreen'
                                            : 'bg-primaryRed'
                                        }`}
                                      ></span>
                                      <span
                                        className={`relative inline-flex rounded-full h-2 w-2 ${
                                          item?.minterData?.status === 'active'
                                            ? 'bg-primaryGreen'
                                            : 'bg-primaryRed'
                                        }`}
                                      ></span>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex items-center justify-center gap-1 my-1">
                                <div className="">
                                  <span className=" text-sm font-medium text-[#939393]">
                                    {t('dynamic.wallet.withdraw.label.Linked')}
                                    {` NFTS`}:
                                  </span>
                                </div>

                                <div className="">
                                  <span className="font-medium text-sm text-[#121212]">
                                    {`${item?.totalLockNft ?? 0}/${item?.minterData?.maxNFTCount ?? 0}`}
                                  </span>
                                </div>
                              </div>

                              {
                                // false
                                item?.status === 'connected' ? (
                                  <span
                                    className="text-xs text-[#484848] underline cursor-pointer"
                                    onClick={() => {
                                      setMinter(item);
                                      handleOpenDetailModal();
                                    }}
                                  >
                                    {item.type === 'phone'
                                      ? t('defi.myCloudk.licenses.viewPhone')
                                      : t('defi.myCloudk.licenses.viewMinter')}
                                  </span>
                                ) : (
                                  <button
                                    // disabled={item.disable}
                                    type="button"
                                    // className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${!item.lock && item.disable ? 'bg-[#C0D5DF] cursor-not-allowed' : 'hover:bg-primary'}  bg-[#00A5EF] `}
                                    className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${'hover:bg-primary'}  bg-[#00A5EF] `}
                                    onClick={() => {
                                      if (item.type === 'phone') {
                                        setPhoneMinter(item);
                                        handleOpenConnectQRModal();
                                      } else {
                                        setMinter(item);
                                        handleOpenConnectModal();
                                      }
                                    }}
                                  >
                                    <div className="w-3 h-3">
                                      <LinkIcon />
                                    </div>{' '}
                                    {t('general.Connect')}
                                  </button>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </SimpleSlider>
            </>
          ) : (
            <div className="flex items-center justify-center flex-1 w-full py-3 xxs:px-2 sm:px-0">
              <div className="flex flex-col items-center gap-2 ">
                <div className="h-auto w-44 lg:w-64">
                  <img src={EmptyHardwareList} alt="empty transactions" />
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
                    {t('defi.myCloudk.tNoProducts', {
                      property: t('dashboard.worksSection.subTitle_1'),
                    })}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {allHArdwaresData?.length > 0 && (
          <>
            <div className="absolute top-0 right-0 h-full w-[10%] pointer-events-none bg-gradient-to-l from-white md:block hidden"></div>
            <div className="flex gap-4 mt-2 md:hidden">
              <button
                onClick={handlePrev}
                disabled={currentIndex === 0}
                className={` rounded-full ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowBack size={20} />
              </button>
              <button
                onClick={handleNext}
                disabled={isAtEnd}
                className={` rounded-full ${isAtEnd ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowForward size={20} />
              </button>
            </div>
          </>
        )}
      </div>

      <GlobalModal
        isOpen={openConnectModal}
        onClose={handleCloseConnectModal}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll"
        title={t('defi.myCloudk.Hardwares.title')}
        noHeader={false}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
      >
        <ConnectNewMinter
          minter={minter}
          handleCloseConnectModal={handleCloseConnectModal}
        />
      </GlobalModal>

      {openDetailModal && (
        <GlobalModal
          isOpen={openDetailModal}
          onClose={handleCloseDetailModal}
          size="3xl"
          childrenClass="!px-0 !py-0 small-scroll"
          // title={'Connect New Minter'}
          noHeader={true}
          headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
        >
          {/* <HardwareDetailModal minter={minter} /> */}
          <HardwareDetailsModalBody minter={minter} />
        </GlobalModal>
      )}

      <GlobalModal
        isOpen={openConnectQRModal}
        onClose={handleCloseConnectQRModal}
        size="2xl"
        closeButtonClass={'!z-10'}
        childrenClass=" !px-2 sm:!px-6 !py-6  small-scroll relative"
        noHeader={false}
        headerClassName="!p-0  font-medium text-lg text-[#0E173F]"
      >
        <ConnectThroughQR
          phoneMinter={phoneMinter}
          handleClose={handleCloseConnectQRModal}
        />
      </GlobalModal>
    </CardLayout>
    // )
  );
};

export default AllHardwares;
