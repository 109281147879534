import { useDispatch } from 'react-redux';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  setGraphData,
  setGraphDataLoading,
  setMoreTopLeaders,
  setTopLeadersList,
  setTopLeadersLoading,
  setTopTenLeaders,
  setTopTenLoading,
  setTopThreeLeaders,
  setTopThreeLoading,
} from './leaderBoardSlice';
import {
  GET_LEADERS_BAR_GRAPH,
  GET_TOP_LEADERS,
} from '../../utils/network/ApiEndpoints';

export const useLeaderBoardActions = () => {
  const dispatch = useDispatch();

  const getTopLeaders = async (data?: any, hasMore = false) => {
    const url = GET_TOP_LEADERS;
    dispatch(setTopLeadersLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
        params: data,
      })
      .then((response: any) => {
        const {
          data: { data },
        } = response;
        if (hasMore) dispatch(setMoreTopLeaders(data));
        else dispatch(setTopLeadersList(data));
        return data;
      })
      .catch((error: any) => {
        dispatch(setTopLeadersLoading(false));
        return error;
      })
      .finally(() => {
        dispatch(setTopLeadersLoading(false));
      });
  };

  const getTopTenLeaders = async (data?: any) => {
    const url = GET_TOP_LEADERS;
    dispatch(setTopTenLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
        params: data,
      })
      .then((response: any) => {
        const {
          data: { data },
        } = response;
        dispatch(setTopTenLeaders(data));
        return data;
      })
      .catch((error: any) => {
        dispatch(setTopTenLoading(false));
        return error;
      })
      .finally(() => {
        dispatch(setTopTenLoading(false));
      });
  };

  const getTopThreeLeaders = async (data?: any) => {
    const url = GET_TOP_LEADERS;
    dispatch(setTopThreeLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
        params: data,
      })
      .then((response: any) => {
        const {
          data: { data },
        } = response;
        dispatch(setTopThreeLeaders(data));
        return data;
      })
      .catch((error: any) => {
        dispatch(setTopThreeLoading(false));
        return error;
      })
      .finally(() => {
        dispatch(setTopThreeLoading(false));
      });
  };

  const getBarGraphData = async (data?: any) => {
    const url = GET_LEADERS_BAR_GRAPH;
    dispatch(setGraphDataLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
        params: data,
      })
      .then((response: any) => {
        const {
          data: { data },
        } = response;
        dispatch(setGraphData(data));
        return data;
      })
      .catch((error: any) => {
        dispatch(setGraphDataLoading(false));
        return null;
      })
      .finally(() => {
        dispatch(setGraphDataLoading(false));
      });
  };

  return {
    getTopLeaders,
    getBarGraphData,
    getTopTenLeaders,
    getTopThreeLeaders,
  };
};
