import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { upIcon } from '../../../../../images/cloudk';
import { toFixedFormat } from '../../../../../utils/utils';
import { ReactComponent as LinkIcon } from '../../../../../SVGIcons/cloud/link.svg';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { toast } from 'react-toastify';
import LicensePlaceholder from '../../../../../assets/license-placeholder.png';
import { setDecimalPlaces } from '../../../../../utils/helper';
import { useTranslation } from 'react-i18next';

const LicenseCard = ({
  item,
  handleMintingToken,
  handleOpen,
  handleOpenStakePeriod,
}: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { unlockLicense } = useCloudkActions();
  const { t } = useTranslation();

  const handleUnlock = async () => {
    if (item.linkStatus === 'LOCKED') {
      setLoading(true);
      const { status } = await unlockLicense(item._id);
      setLoading(false);
      if (status === 200) {
        toast.success(t('defi.myCloudk.licenses.toast.unlockToast'));
        navigate(`/defi-360/cloudk/license-unlock/${item._id}`, {
          state: item.name,
        });
      }
    } else {
      handleOpen();
      handleMintingToken(item);
    }
  };

  const isStakePeriodUpgradable: any = (license: any) =>
    !license?.isExpired &&
    license.linkStatus !== 'LOCKED' &&
    license.linkStatus !== 'UNLOCKED' &&
    !isNaN(license.lockedPeriod) &&
    Number(license.lockedPeriod) > 0 &&
    Number(license.lockedPeriod) < 36;

  return (
    <div className="border border-[#D9E4F1] rounded-lg w-56 h-92 relative p-3 flex flex-col gap-6">
      {isStakePeriodUpgradable(item) && (
        <div
          onClick={() => {
            handleOpenStakePeriod();
            handleMintingToken(item);
          }}
          className="shadow-lg p-1 absolute right-3 top-3 border border-[#00A5EF] rounded-xl bg-white flex justify-center items-center"
        >
          <div className="h-[9px] w-[9px] mr-[2px]">
            <img src={upIcon} alt="icon" className="w-full h-full" />
          </div>
          <span className="font-medium text-[#00A5EF] text-[9px]">
            {t('general.Upgrade')}
          </span>
        </div>
      )}

      <div className="flex justify-center w-full mt-3">
        <img
          src={item.image || LicensePlaceholder}
          alt="machine-image"
          className="h-[120px] w-[86px]"
        />
      </div>

      <div className="flex flex-col items-center w-full gap-2">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex items-center gap-1">
            <span className="font-medium text-[#121212] flex items-center">
              <span className="truncate max-w-36">{item?.name}</span>{' '}
              <span className="text-sm text-[#939393]">x2</span>
            </span>
            <div className="relative w-2 h-2 ml-1">
              <span className="flex w-2 h-2 gap-2">
                <span
                  className={` absolute inline-flex h-full w-full rounded-full opacity-75 ${
                    item.isExpired || item.status === 'inactive'
                      ? 'bg-primaryRed'
                      : 'bg-primaryGreen'
                  }`}
                ></span>
              </span>
            </div>
          </div>
        </div>

        <span className="text-xs font-medium text-[#939393]">
          {t('defi.myCloudk.licenses.Linked mLYK')}:{' '}
          <span className="text-xs font-medium text-[#121212]">
            {setDecimalPlaces(Number(item?.staked_mlyk || 0), 3)} mLYK
          </span>
        </span>
        <span className="text-xs font-medium text-[#939393]">
          {t('general.Capacity')}:{' '}
          <span className="text-xs font-medium text-[#121212]">
            {item.totalLockedNft} / {item.nft}
          </span>
        </span>

        {item.linkStatus === 'UNLOCKED' ? (
          <span
            className="text-xs text-[#484848] underline cursor-pointer"
            onClick={() =>
              navigate(`/defi-360/cloudk/license-unlock/${item._id}`, {
                state: {
                  name: item.name,
                  licenseCard: false,
                },
              })
            }
          >
            {t('defi.myCloudk.viewDetails', { type: t('defi.myCloudk.li') })}
          </span>
        ) : (
          <button
            onClick={handleUnlock}
            disabled={item.isExpired || loading}
            type="button"
            className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${
              item.isExpired
                ? 'bg-[#C0D5DF] cursor-not-allowed'
                : 'bg-[#00A5EF] hover:bg-primary'
            }`}
          >
            {loading ? (
              <Spinner className="h-4 animate-spin" />
            ) : item.linkStatus === 'LOCKED' ? (
              <>{t('general.Unlock')}</>
            ) : item.isExpired ? (
              t('general.expired')
            ) : (
              <>
                <div className="w-3 h-3">
                  <LinkIcon />
                </div>
                {t('general.Link')}
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default LicenseCard;
