import { useSelector } from 'react-redux';
import LineChart from '../../../components/Charts/LineChart';
import { selectLeaderBoard } from '../../../store/leadersboard/leaderBoardSlice';
import { LeaderBoardDefs, LeaderBoardFill } from '../constants';
import LeaderBoardFilterTab from './LeaderBoardFilterTab';
import NoData from './NoData';
import { NivoLineChartLoader } from './LoadingShimmer';
import { useEffect, useState } from 'react';
import { useLeaderBoardActions } from '../../../store/leadersboard/leaderBoardAction';

const LeadersLineChart = ({ selectedMainTab, selectedNestedTab }: { selectedMainTab: string, selectedNestedTab: string }) => {
  const { barGraphData, barGraphDataLoading } = useSelector(selectLeaderBoard)
  const { getBarGraphData } = useLeaderBoardActions();
  const [selectedGraphFilter, setSelectedGraphFilter] =
    useState<string>('weekly');
  let transformedBarGraphData = [
    {
      id: 'LeaderBoardPoints',
      data: barGraphData
    }
  ]
  useEffect(() => {
    setSelectedGraphFilter('weekly')
    fetchBarGraphData('weekly');
  }, [selectedMainTab, selectedNestedTab])
  const fetchBarGraphData = async (timeline: string) => {
    const filter =
      selectedMainTab === 'rewardSpecificLeaders'
        ? { timeline: timeline, type: selectedNestedTab }
        : { timeline: timeline };
    const data = await getBarGraphData(filter);
    if (data) {
      setSelectedGraphFilter(timeline)
    }

  }
  return (
    <div className="w-full xl:w-1/2 bg-white rounded-lg border border-borderBlue">
      <div className="w-full flex justify-between items-center py-2 px-3 border-b border-borderBlue gap-3">
        <span className='font-medium text-md sm:text-xl flex-1'>Top 50 Rewards</span>
        <LeaderBoardFilterTab
          disabled={barGraphDataLoading}
          layoutID={'rewards-graph'}
          onFilterChange={(event: string): void => {
            fetchBarGraphData(event)
          }}
          // setSelectedFilter={setSelectedGraphFilter}
          selectedFilter={selectedGraphFilter}
          hoverTextColor={true}
          filterType={[
            { label: 'Week', value: 'weekly' },
            { label: 'Month', value: 'monthly' },
            { label: 'Year', value: 'yearly' },
          ]}
          loader={barGraphDataLoading}
        />
      </div>

      <div className="w-full h-[300px]">
        {barGraphDataLoading ? (
          <div className="relative h-full w-full">
            <NivoLineChartLoader />
          </div>
        ) : !barGraphData || !barGraphData.length ? (
          <NoData />
        ) : (
          <LineChart
            data={transformedBarGraphData}
            isLeaderBoardChart={true}
            curve='basis'
            margin={{ top: 50, right: 25, bottom: 30, left: 25 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: '0',
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            enablePoints={false}
            colors={['#197AFD85']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: 'middle',
              truncateTickAt: 0,
            }}
            axisLeft={null}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableGridX={false}
            gridYValues={[0]}
            enableGridY={false}
            legends={[]}
            enableArea={true}
            areaOpacity={0.3}
            defs={LeaderBoardDefs}
            fill={LeaderBoardFill}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                    fill: "#808080",
                  }
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LeadersLineChart;
