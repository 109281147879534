import { useState } from 'react';
import { toast } from 'react-toastify';
import QrCode from '../../../../../../components/QrCode';
import useSocketEvent from '../../../../../../hooks/socketIO/useSocketEvent';
import useUserCheck from '../../../../../../hooks/useUserCheck';
import Error from '../../../../../../SVGIcons/Message/errorIcon.svg';
import Success from '../../../../../../SVGIcons/Message/successIcon.svg';
import { MobileConnectType } from './ConnectThroughQRTypes';

const Step4 = ({ orderId, handleClose }: any) => {
  const { user } = useUserCheck();
  const [isConneted, setIsConnected] = useState(false);
  const [isScanned, setIsScanned] = useState(false);

  useSocketEvent<MobileConnectType>({
    eventName: `${user?.id}`,
    onData: (data: any) => {
      if (data?.body?.eventAction == 'PHONE_CONNECTION_SUCCESS') {
        if (data?.body?.isConnected) {
          setIsConnected(true);
          setIsScanned(true);
          toast.success(data?.message);
          setTimeout(() => {
            handleClose();
          }, 5000);
        } else {
          toast.error(data?.message);
        }
      }
    },
  });

  return (
    <div className="flex flex-col items-center w-full gap-2 my-auto">
      <div
        className={`w-full flex justify-center ${isScanned ? 'mt-14 md:mt-20' : 'mt-6'} mb-2 md:mb-6`}
      >
        {!isScanned ? (
          <QrCode value={orderId} size={'300px'} />
        ) : isConneted ? (
          <img src={Success} alt="" className="w-32 h-auto md:w-44" />
        ) : (
          <img src={Error} alt="" className="w-32 h-auto md:w-44" />
        )}
      </div>
      {!isScanned ? (
        <>
          <p className="text-center ">
            Open the Homnifi app on the phone with the serial number given
            below:{' '}
          </p>
          <p className="text-[#F0AD47] text-sm">
            Note : Undo the shutter of the camera of the phone
          </p>
        </>
      ) : isConneted ? (
        <p>Mobile Connected Successfully</p>
      ) : (
        <p>Mobile Connection Error</p>
      )}
    </div>
  );
};

export default Step4;
