import React from 'react';

export default React.memo(function GlobalPageWrapper({
  children,
  containerClass = '',
}: {
  children: React.ReactNode;
  containerClass?: string;
}) {
  return (
    <div
      className={`w-full p-4  bg-white rounded-xl border border-borderBlue ${containerClass}`}
    >
      {children}
    </div>
  );
});
