import { useDispatch } from 'react-redux';
import {
  LINK_LIST,
  PLATFORM_SETTINGS,
  SHOW_PAGE,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  setLink,
  setPagesEnable,
  setPlatformSettings,
  setSettingSlice,
} from './settingSlice';
import { toast } from 'react-toastify';
import { INVALID_TOKEN, UNAUTHORIZED } from '../../utils/constants';

export const useSettingAction = () => {
  const dispatch = useDispatch();

  const getLinkList = async (): Promise<any> => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: LINK_LIST,
      })
      .then((response: any) => {
        dispatch(setLink(response.data.data.list));
        return response;
      })
      .catch((error: any) => error);
  };

  const getPagesEnable = async (): Promise<any> => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SHOW_PAGE,
      })
      .then((response: any) => dispatch(setPagesEnable(response?.data?.data)))
      .catch((error: any) => error);
  };

  const getPlatformSettings = async (): Promise<any> => {
    dispatch(
      setSettingSlice({
        getPlatformSettingsLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: PLATFORM_SETTINGS,
      })
      .then((response: any): void => {
        dispatch(setPlatformSettings(response?.data?.data));

        dispatch(
          setSettingSlice({
            getPlatformSettingsLoading: false,
          })
        );
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.data.message) {
          toast.error(error?.data?.data?.message);
        }

        dispatch(
          setSettingSlice({
            getPlatformSettingsLoading: false,
          })
        );
        return error;
      });
  };

  return {
    getLinkList,
    getPagesEnable,
    getPlatformSettings,
  };
};
