import React from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { totalRewardWaterMark } from '../../../../../images/cloudk';
import { toFixedFormat } from '../../../../../utils/utils';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { setDecimalPlaces } from '../../../../../utils/helper';
import { useTranslation } from 'react-i18next';

const data = [
  {
    token: 'LYR-W',
    amount: 4562124,
    textColor: '#7583FF',
  },
  {
    token: 'LYR-R',
    amount: 4562124,
    textColor: '#7FC9FF',
  },
];
const TotalRewardGenerated = () => {
  const { totalCountLoading, rewardGeneratedTokens } =
    useSelector(selectCloudSlice);
  const { t } = useTranslation();

  return (
    <CardLayout className="md:col-span-6 xl:col-span-3 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow relative bg-[#FFFFFF]">
      {totalCountLoading && rewardGeneratedTokens?.length === 0 ? (
        <div className="flex justify-center items-center flex-1  h-full w-full">
          <div className="justify-center items-center self-center">
            <div className="flex flex-col gap-5">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        </div>
      ) : (
        <section className="flex flex-col gap-4 ">
          <span className="font-medium text-[#616161]">
            {t('defi.myCloudk.tGenerated')}
          </span>
          <div className="flex flex-col gap-4">
            {rewardGeneratedTokens?.map((item: any) => {
              return (
                <div
                  className="flex items-center justify-between gap-2 flex-wrap"
                  key={item?._id}
                >
                  <span
                    className="font-medium"
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.tokenName}
                  </span>
                  <span className="text-2xl font-medium text-[#001540]">
                    {toFixedFormat(Number(item.totalAmount || 0), 3)}
                  </span>
                </div>
              );
            })}
          </div>
        </section>
      )}
      <img
        src={totalRewardWaterMark}
        alt={'water-mark'}
        className="absolute bottom-0 right-2 h-full"
      />
    </CardLayout>
  );
};

export default TotalRewardGenerated;
