/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSocketEvent from '../../hooks/socketIO/useSocketEvent';
import useNotificationToggler from '../../hooks/useNotificationToggler';
import {
  FooterTelegram,
  GoldenVerifiedBtn,
  Hamburger,
  Logo,
  NewsLetterIcon,
  NotificationIcon,
  ProBadge,
  VerifiedIcon,
} from '../../images/svgs';
import { toggleDrawer } from '../../store/drawer/drawerSlice';
import { useNotificationsActions } from '../../store/notifications/notificationActions';
import {
  selectNotification,
  setIsNotificationOpen,
} from '../../store/notifications/notificationsSlice';
import { useUserActions } from '../../store/user/userActions';
import { selectUserSlice, setUserSlice } from '../../store/user/userSlice';
import {
  DepositTransactionResponse,
  NewNotificationType,
  TypeDueBalanceClearedResponse,
  TypeDueBalanceDebtResponse,
  TypeMachinePurchaseSuccessResponse,
  WithdrawTransactionResponse,
} from '../../types/wallet.types';
import { TransactionEvents } from '../../utils/allConstants';
import { useWindowSize } from '../../utils/helper';
import { getAccessToken } from '../../utils/storage/protectedStorage';
import GlobalAutoComplete from '../Global/GlobalAutoComplete';
import GlobalButton from '../Global/GlobalButton';
import GlobalIconWrap from '../Global/GlobalIconWrap';
import GlobalModal from '../Global/GlobalModal';
import CustomSwitch from '../Global/GlobalSwitch';
import NotificationsPopOver from '../Notifications/NotificationsPopOver';
import ProfilePopOver from '../Profile/ProfilePopOver';
import ProfileQR from './ProfileQR';

import useUserCheck from '../../hooks/useUserCheck';
import { profileImg } from '../../images';
import { useNewsletterActions } from '../../store/newsLetter/newLetterActions';
import {
  selectNewsletter,
  setIsNewsletterOpen,
} from '../../store/newsLetter/newsLetterSlice';
import { FEATURES, FOOTER_SOCIALS } from '../../utils/constants';
import NewsletterPopOver from '../Notifications/NewsletterPopOver';
import { selectSuperNodeSlice } from '../../store/superNode/superNodeSlice';
import { useWalletActions } from '../../store/wallets/walletsAction';
import { useWalletDataAction } from '../../store/walletData/walletDataAction';
import { INewsletterList } from '../../types/newsletter.types';
import TrustPilotModal from '../Modal/TrustPilotModal';
import { useCloudActions } from '../../store/Cloudk2/cloudk2Actions';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useGeneralActions } from '../../store/general/generalActions';
import LanguagePopOver from '../Notifications/LanguagePopOver';

type Props = {
  drawer: boolean;
};

const Navbar = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const size = useWindowSize();
  const {
    homnifiNotificationList,
    unReadNotificationCount,
    isNotificationOpen,
  } = useSelector(selectNotification);

  const { newsletterList, unReadNewsletterCount, isNewsletterOpen } =
    useSelector(selectNewsletter);
  const { userInfo } = useSelector(selectUserSlice);

  const isMobileView = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const { t, i18n } = useTranslation();

  const { user } = useUserCheck();

  const { notificationPopupToggler, newsLetterPopupToggler } =
    useNotificationToggler();
  const { GetDueBalance } = useGeneralActions();
  const { getHomnifiNotification, readAllHomnifiNotifications } =
    useNotificationsActions();
  const { getNewsletter, readNewsletter } = useNewsletterActions();

  const [profile, setProfile] = React.useState(false);
  const [openTrustPilot, setOpenTrustPilot] = useState<boolean>(false);
  const languageRef = React.useRef<HTMLDivElement>(null);
  const notificationRef = React.useRef<HTMLDivElement>(null);
  const newsletterRef = React.useRef<HTMLDivElement>(null);
  const profileRef = React.useRef<HTMLDivElement>(null);
  const telgramRef = React.useRef<HTMLDivElement>(null);

  const [businessCard, setBusinessCard] = React.useState(false);
  const { userActiveStatus } = useSelector(selectSuperNodeSlice);

  const { getWithdrawHistory } = useWalletActions();

  const { userLogout } = useUserActions();
  const { signOutFromAllDevice } = useUserActions();
  const [isAllDevicesLogout, setIsAllDevicesLogout] = useState(false);
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);
  const [selectedNewsLetter, setSelectedNewsLetter] =
    useState<INewsletterList | null>(null);

  const {
    getWalletDataDepositHistory,
    getWalletDataWalletsList,
    getWalletDataWithdrawAndTransferHistory,
  } = useWalletDataAction();

  const { getUserMachineRewards, getUserMachinesList } = useCloudActions();

  const markAllAsReadNotifications = async () => {
    await readAllHomnifiNotifications();
  };

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer(!props.drawer));
    localStorage.setItem('drawer', JSON.stringify(!props.drawer));
  };
  const onReadNewsletterHandler = async (id: string) => {
    await readNewsletter(id);
  };
  const goToViewProducts = () => {
    setOpenTrustPilot(false);
    navigate('/defi-360/nodek');
  };
  const unReadNewsLetter = useMemo(() => {
    return newsletterList?.filter((newsletter) => !newsletter.read);
  }, [newsletterList, unReadNewsletterCount]);

  const logoutHanlder = async () => {
    if (isAllDevicesLogout) {
      const response = await signOutFromAllDevice(
        getAccessToken() || '',
        'logout'
      );

      if (response?.data?.status) {
        dispatch(
          setUserSlice({
            isProfileCompletePercentage: false,
          })
        );
      } else {
        if (response.data.message) {
          toast.error(response?.data?.message);
        }
      }
    } else {
      userLogout();
      dispatch(
        setUserSlice({
          isProfileCompletePercentage: false,
        })
      );
    }
  };

  function isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  useSocketEvent<WithdrawTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (
        data.body.eventAction === TransactionEvents.WITHDRAW_TRANSACTION_SUCCESS
      ) {
        // getWithdrawHistory({ page: 1 }, false);
        getWalletDataWalletsList();
        getWalletDataWithdrawAndTransferHistory({
          page: 1,
          limit: 10,
        });

        getWalletDataDepositHistory({
          page: 1,
          limit: 10,
        });
      }
      // toast.success(data.body.message);
    },
  });

  useSocketEvent<TypeMachinePurchaseSuccessResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (
        data.body.eventAction === TransactionEvents.MACHINE_PURCHASE_SUCCESS
      ) {
        // getWithdrawHistory({ page: 1 }, false);
        getWalletDataWalletsList();
        getWalletDataWithdrawAndTransferHistory({
          page: 1,
          limit: 10,
        });
        getUserMachineRewards({
          limit: 10,
          page: 1,
        });
        getWalletDataDepositHistory({
          page: 1,
          limit: 10,
        });
        getUserMachinesList();
        if (!userInfo?.userProfile?.trustpilotReviewed) {
          setOpenTrustPilot(true);
        }
      }
      // toast.success(data.body.message);
    },
  });

  useSocketEvent<DepositTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      const event = data.body.eventAction.trim();

      const successEvents = [
        TransactionEvents.DEPOSIT_TRANSACTION_SUCCESS.trim(),
        TransactionEvents.DEPOSIT_AND_STAKE_TRANSACTION_SUCCESS.trim(),
      ];

      const failureEvents = [
        TransactionEvents.DEPOSIT_TRANSACTION_FAILURE.trim(),
        TransactionEvents.DEPOSIT_AND_STAKE_TRANSACTION_FAILED.trim(),
      ];

      const isSuccessEvent = successEvents.includes(event);
      const isFailureEvent = failureEvents.includes(event);

      if (isSuccessEvent || isFailureEvent) {
        Promise.all([
          getWalletDataDepositHistory({ page: 1, limit: 10 }),
          getHomnifiNotification(),
          getWalletDataWalletsList(),
          // getWithdrawHistory({ page: 1 }, false),
          getWalletDataWithdrawAndTransferHistory({
            page: 1,
            limit: 10,
          }),
        ])
          .then(() => {
            if (isSuccessEvent) {
              toast.success(data.body.message);
            } else if (isFailureEvent) {
              toast.error(data.body.message);
            }
          })
          .catch(() => {
            toast.error(t('alerts.anErrorOccured'));
          });
      }
    },
  });

  useSocketEvent<NewNotificationType>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (data.body.eventAction === TransactionEvents.NEW_NOTIFICATION_EVENT) {
        getHomnifiNotification();
      }
    },
  });

  useSocketEvent<TypeDueBalanceClearedResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (data.body.eventAction === TransactionEvents.DUE_BALANCE_CLEARED) {
        toast.success(data.body.message);
        GetDueBalance();
      }
    },
  });

  useSocketEvent<TypeDueBalanceDebtResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (data.body.eventAction === TransactionEvents.DUE_BALANCE_DEBT) {
        toast.success(data.body.message);
        GetDueBalance();
      }
    },
  });
  // useSocketEvent<NewMachineType>({
  //   eventName: `${user?.id}`,
  //   onData: (data) => {
  //     if (data.body.eventAction === TransactionEvents.MACHINE_PURCHASE) {
  //       getUserMachinesList();
  //       setOpenTrustPilot(true)
  //     }
  //   },
  // });

  React.useEffect(() => {
    if (homnifiNotificationList.length === 0) {
      getHomnifiNotification(1);
      // getHomnifiAllNotification();
    }
  }, [isNotificationOpen]);

  React.useEffect(() => {
    if (newsletterList.length === 0) {
      getNewsletter(1);
      // getAllNewsletter();
    }
  }, [isNewsletterOpen]);

  React.useEffect(() => {
    if (isNotificationOpen || location.pathname === '/notification') {
      if (size.width > 0) {
        notificationPopupToggler();
      }
    } else if (isNewsletterOpen || location.pathname === '/newsletter') {
      if (size.width > 0) {
        newsLetterPopupToggler();
      }
    }
  }, [size]);

  useEffect(() => {
    // Set the first unread newsletter when the list changes
    if (unReadNewsLetter.length > 0) {
      setSelectedNewsLetter(unReadNewsLetter[0]);
    }
  }, [unReadNewsLetter]);

  const handleNewsletterModalClose = () => {
    if (user?.isImpersonateMode) {
      setSelectedNewsLetter(null);
      return;
    }
    if (unReadNewsLetter.length > 0) {
      onReadNewsletterHandler(unReadNewsLetter[0]._id);
      setSelectedNewsLetter(unReadNewsLetter[1] || null); // Set the next newsletter, or null if none left
    } else {
      setSelectedNewsLetter(null);
    }
  };

  return (
    <>
      <TrustPilotModal
        isOpen={openTrustPilot}
        onClose={() => {
          setOpenTrustPilot(false);
        }}
        goToViewProducts={goToViewProducts}
        title={t('trustPilot.yourFeedbackMattters')}
        message={t('trustPilot.greetingsOnMachinePurchase')}
        viewProducts={true}
        subMessage={t('trustPilot.striveToProvideTheBestExperience')}
      />
      <header
        className={`flex flex-wrap sm:justify-start sm:flex-nowrap z-[51] w-full bg-white border-b border-gray-200 text-sm h-[80px] sticky ${user?.isImpersonateMode ? 'top-10' : 'top-0'}`}
      >
        <nav className="relative flex items-center justify-between w-full h-full px-6 mx-auto lg:px-12">
          <div className="flex items-center justify-between w-full gap-6 lg:gap-12">
            <div className="flex items-center gap-6 lg:gap-12">
              <Link
                className="relative flex-none text-xl font-semibold"
                to="https://homnifi.com/"
                aria-label="Brand"
                target="_blank"
              >
                <Logo className="h-10" />
              </Link>
              <div className="hidden lg:block">
                <button
                  type="button"
                  className="flex items-center justify-center text-sm font-semibold text-gray-800 rounded-lg size-9 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                  onClick={handleToggleDrawer}
                >
                  <Hamburger />
                </button>
              </div>
              <div className="relative items-center group z-[999] lg:flex hidden">
                <GlobalAutoComplete />
              </div>
            </div>
            <div className="flex items-center justify-center gap-2 lg:hidden">
              {isMobileView && (
                <div className="relative md:hidden" ref={telgramRef}>
                  <Link
                    className="font-medium text-blue-600"
                    to="#"
                    aria-current="page"
                    onClick={() =>
                      window.open(FOOTER_SOCIALS.telegram, '_blank')
                    }
                  >
                    <div className="flex items-center justify-center border rounded-full border-primary round ">
                      <FooterTelegram className="w-8 h-8" fill="currentColor" />
                    </div>
                  </Link>
                </div>
              )}
              <button
                type="button"
                className="flex items-center justify-center text-sm font-semibold text-gray-800 border rounded-full size-9 border-borderLightGray hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none md:hidden"
                onClick={handleToggleDrawer}
              >
                <Hamburger />
              </button>
            </div>
          </div>

          <div className="flex-1 hidden w-full h-full transition-all duration-300 hs-collapse basis-full grow md:block">
            <div className="flex flex-col w-full h-full gap-2 px-2 sm:flex-row sm:items-center sm:justify-end">
              <div className="relative" ref={telgramRef}>
                <Link
                  className="font-medium text-blue-600"
                  to="#"
                  aria-current="page"
                  onClick={() => window.open(FOOTER_SOCIALS.telegram, '_blank')}
                >
                  <div className="flex items-center justify-center border rounded-full border-primary round ">
                    <FooterTelegram className="w-10 h-10" fill="currentColor" />
                  </div>
                </Link>
              </div>
              {/* ? Language  dropdown */}
              {FEATURES.showTranslation && <LanguagePopOver />}
              <div className="relative" ref={newsletterRef}>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    dispatch(setIsNewsletterOpen(false));
                  }}
                >
                  <Link
                    className="font-medium text-blue-600"
                    to="#"
                    aria-current="page"
                    onClick={() => {
                      dispatch(setIsNewsletterOpen(!isNewsletterOpen));
                    }}
                  >
                    <GlobalIconWrap
                      notificationCount={unReadNewsletterCount}
                      active={isNewsletterOpen}
                    >
                      <NewsLetterIcon className="w-4 h-4" />
                    </GlobalIconWrap>
                  </Link>
                  <AnimatePresence>
                    {isNewsletterOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.2 }}
                        className="relative"
                      >
                        <NewsletterPopOver />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </OutsideClickHandler>
              </div>
              <div className="relative" ref={notificationRef}>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    dispatch(setIsNotificationOpen(false));
                  }}
                >
                  <Link
                    className="font-medium text-blue-600"
                    to="#"
                    aria-current="page"
                    onClick={() => {
                      if (unReadNotificationCount > 0) {
                        markAllAsReadNotifications();
                      }
                      dispatch(setIsNotificationOpen(!isNotificationOpen));
                    }}
                  >
                    <GlobalIconWrap
                      notificationCount={unReadNotificationCount}
                      active={isNotificationOpen}
                    >
                      <NotificationIcon className="w-4 h-4" />
                    </GlobalIconWrap>
                  </Link>
                  <AnimatePresence>
                    {isNotificationOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.2 }}
                        className="relative"
                      >
                        <NotificationsPopOver />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </OutsideClickHandler>
              </div>
              <div className="flex items-center align-middle">
                <div className="relative" ref={profileRef}>
                  <Link
                    className="font-medium text-blue-600"
                    to="#"
                    aria-current="page"
                    onClick={() => setProfile(!profile)}
                  >
                    {/* <GlobalIconWrap
                    active={location.pathname === '/profile'}
                    status={userActiveStatus.isActive}
                  >
                    <UserIcon className="w-4 h-4" />
                  </GlobalIconWrap> */}
                    <div
                      className={`flex justify-start gap-2 items-start border rounded-full hover:text-primary hover:border-primary/80 text-defaultBlack border-borderBlue transition ease-in-out `}
                    >
                      <div className="flex flex-row w-full gap-2 relative">
                        <div className="flex flex-col items-start justify-center pl-4">
                          <div className=" text-secondaryGrey leading-3 text-[.6rem] flex items-center gap-2 font-medium">
                            <span>ID: {user?.id && user?.id}</span>
                            {/* {user?.kycApproved && <ProBadge className='absolute w-8 h-5 -top-2 right-[3px] z-10' />} */}
                            {/* <img
                            src={isCopied ? tick : copy}
                            alt="icon"
                            className="cursor-pointer w-2.5"
                            onClick={handleCopy}
                          /> */}
                          </div>
                          <div className="text-xs leading-3 font-medium  flex items-center gap-2 text-[.6rem]">
                            @{user?.username}
                          </div>
                        </div>
                        <div>
                          <div className="relative w-10 h-10">
                            {isBoolean(userActiveStatus?.isActive) &&
                              location.pathname === '/community/super-node' && (
                                <span className="absolute -top-[2px] right-[5px] flex gap-2 h-2 w-2">
                                  <span
                                    className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${userActiveStatus?.isActive
                                      ? 'bg-primaryGreen'
                                      : 'bg-primaryRed'
                                      }`}
                                  ></span>
                                  <span
                                    className={`relative inline-flex rounded-full h-2 w-2 ${userActiveStatus?.isActive
                                      ? 'bg-primaryGreen'
                                      : 'bg-primaryRed'
                                      }`}
                                  ></span>
                                </span>
                              )}
                            <img
                              src={
                                user?.profilePicture
                                  ? user?.profilePicture
                                  : profileImg
                              }
                              alt="Profile"
                              className="object-cover w-full h-full rounded-full"
                            />
                            <span className="absolute bottom-0 right-0">
                              {user?.subscriptionType === 'DISTRIBUTOR' ? (
                                FEATURES.showDistributorMembership ? (
                                  <GoldenVerifiedBtn className="w-[.9rem] h-[.9rem]" />
                                ) : (
                                  user?.kycApproved && <VerifiedIcon className="w-[.9rem] h-[.9rem]" />
                                )
                              ) : (
                                user?.kycApproved && <VerifiedIcon className="w-[.9rem] h-[.9rem]" />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setProfile(false);
                    }}
                  >
                    <AnimatePresence>
                      {profile && (
                        <motion.div
                          initial={{ opacity: 0, y: -50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -50 }}
                          transition={{ duration: 0.2 }}
                          className="relative"
                        >
                          <ProfilePopOver
                            setProfile={setProfile}
                            setBusinessCard={setBusinessCard}
                            setIsLogoutClicked={setIsLogoutClicked}
                            status={userActiveStatus?.isActive || null}
                            isBoolean={isBoolean}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </OutsideClickHandler>
                </div>
              </div>
              <ProfileQR
                businessCard={businessCard}
                setBusinessCard={setBusinessCard}
              />
              <GlobalModal
                size="sm"
                isOpen={isLogoutClicked}
                onClose={() => setIsLogoutClicked(false)}
                hideCloseButton={true}
                noHeader={true}
                childrenClass="items-center justify-center"
              >
                <div className="flex flex-col gap-8 p-4 text-sm font-light text-primaryGrey">
                  <div className="text-sm font-medium text-defaultBlack">
                    {t('auth.areYouSureToLogout')}
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="text-black">
                      {t('auth.logoutFromAllDevices')}
                    </span>
                    <CustomSwitch
                      checked={isAllDevicesLogout}
                      onChange={(checked) => setIsAllDevicesLogout(checked)}
                      className="reset-pass-switch"
                      parentClassName="reset-pass-switch-checked"
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <GlobalButton
                      text={t('auth.forms.label.logOut')}
                      type="button"
                      onClick={() => {
                        logoutHanlder();
                      }}
                      classNames="!text-sm"
                    />
                    <GlobalButton
                      outlined
                      text={t('auth.forms.label.close')}
                      type="button"
                      onClick={() => setIsLogoutClicked(false)}
                      classNames="!text-sm"
                    />
                  </div>
                </div>
              </GlobalModal>
              <GlobalModal
                isOpen={
                  selectedNewsLetter != null &&
                  selectedNewsLetter.showsOn?.login &&
                  !selectedNewsLetter.showsOn?.supernode
                }
                onClose={handleNewsletterModalClose}
                size="2xl"
                title={selectedNewsLetter?.newsDetails?.title}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedNewsLetter?.newsDetails?.body ?? '',
                  }}
                ></div>
              </GlobalModal>
              <button
                type="button"
                className="flex items-center justify-center text-sm font-semibold text-gray-800 border rounded-full size-9 border-borderLightGray hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none lg:hidden"
                onClick={handleToggleDrawer}
              >
                <Hamburger />
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
