import { useEffect, useState } from 'react';
import Divider from '../Divider';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { ReactComponent as ActiveIcon } from '../../SVGIcons/MyTree/active.svg';
import { ReactComponent as InactiveIcon } from '../../SVGIcons/MyTree/inactive.svg';
import { copy, tick } from '../../images/others';
import SimpleSlider from '../Slider/CardSlider';
import { useMediaQuery } from 'react-responsive';
import { profileImg } from '../../images';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMyTreeSlice,
  setMyTreeSlice,
} from '../../store/myTree2/myTreeSlice';
import {
  formatAlphaNumericDate,
  truncateToSixDecimals,
} from '../../utils/utils';
import { FEATURES } from '../../utils/constants';
import { Spinner } from '../../images/svgs';
import { setDecimalPlaces } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useCopyTextHook from '../../hooks/useCopyTextHook';

const DetailsSection = ({
  title,
  details,
  icon,
  button,
}: {
  title: string;
  details: any;
  icon?: any;
  button?: any;
}) => (
  <div className="flex flex-col items-start justify-between w-full gap-3 lg:gap-8 md:items-center md:flex-row">
    <span className="text-sm font-medium">{title}</span>
    <span className="text-sm font-normal flex jus items-center gap-1 text-[#5D6E82] break-all">
      {icon}
      {details}
      {button}
    </span>
  </div>
);

const SectionWrapper: React.FC<{
  title: string;
  children: React.ReactNode;
  bgColor?: string;
}> = ({ title, children, bgColor }) => (
  <>
    <h3 className="text-lg font-semibold">{title}</h3>
    <Divider />
    <div
      className={`flex flex-col items-center justify-center w-full gap-3 p-3 border rounded-lg ${
        bgColor ? `bg-[${bgColor}]` : ''
      } border-primary`}
    >
      {children}
    </div>
  </>
);

const SelectedUserInfo = ({ data }: any) => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const { t } = useTranslation();
  const { writeClipboardText } = useCopyTextHook();
  const dispatch = useDispatch();
  const [isRefCodeCopied, setIsRefCodeCopied] = useState(false);
  const {
    getSupernodeTreeChildrenDetails,
    supernodeTreeParentInfo,
    personalAndMachineDetails,
    getPersonalAndMachineDetailsLoading,
    getMachineStakeDetailsLoading,
  } = useSelector(selectMyTreeSlice);
  const personalData = data;
  const machineList = personalAndMachineDetails?.machineList;
  const personalReWard = personalAndMachineDetails?.personalReWard;
  const community = getSupernodeTreeChildrenDetails;

  const handleCopy = () => {
    setIsRefCodeCopied(true);
    writeClipboardText(personalData?.referralCode || '');
    toast.success('Referral Code Copied');
    setTimeout(() => {
      setIsRefCodeCopied(false);
    }, 2000);
  };
  return (
    <div className="relative flex flex-col items-center justify-center w-full border rounded-lg border-primary">
      <div
        className="relative flex items-center justify-center w-full rounded-lg h-28"
        style={{
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(27, 159, 218, 0.80) 100%)',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="140"
          height="140"
          viewBox="0 0 156 151"
          fill="none"
          className="absolute -bottom-8"
        >
          <path
            d="M153.536 75.5C153.536 116.332 119.539 149.5 77.5179 149.5C35.4968 149.5 1.5 116.332 1.5 75.5C1.5 34.668 35.4968 1.5 77.5179 1.5C119.539 1.5 153.536 34.668 153.536 75.5Z"
            stroke="url(#paint0_linear_6839_123942)"
            stroke-width="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6839_123942"
              x1="110.45"
              y1="144"
              x2="110.45"
              y2="108"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#00C1FF" />
              <stop offset="1" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>
        <img
          src={personalData?.profile_picture || profileImg}
          alt="user icon"
          className="w-[130px] absolute -bottom-7 mx-auto right-0 left-0 h-[130px] rounded-full p-[2px]"
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-1 mt-9">
        <span className="text-xl font-medium break-all">
          {personalData?.username}
        </span>
        <span className="text-sm font-normal text-[#7D7D7D]">
          BID: {personalData?.blockchainId}
        </span>
      </div>
      <div className="flex flex-col items-start justify-center w-full gap-3 p-4 mt-4">
        <SectionWrapper title={t('general.Personal Data')} bgColor="#EFF6FF">
          <DetailsSection
            title={t('MyCommunity.Date Joined')}
            details={`${personalData?.dateJoined ? formatAlphaNumericDate(personalData.dateJoined) : '-'}`}
          />
          {data?.depth - supernodeTreeParentInfo?.depth <
            Number(FEATURES?.myTreeEmailLevel) && (
            <DetailsSection title="Email" details={personalData?.email} />
          )}
          <DetailsSection
            title={t('general.Referral Code')}
            details={personalData?.referralCode ?? '-'}
            button={
              personalData?.referralCode && (
                <button
                  className="flex items-center justify-center w-5 h-5 bg-white border rounded-md border-primary/50"
                  onClick={handleCopy}
                >
                  <img
                    src={isRefCodeCopied ? tick : copy}
                    alt="icon"
                    className="cursor-pointer"
                  />
                </button>
              )
            }
          />
          <DetailsSection
            title={t('general.Membership')}
            details={personalData?.membership_expiry ? 'Active' : 'Inactive'}
            icon={
              personalData?.membership_expiry ? (
                <ActiveIcon />
              ) : (
                <InactiveIcon />
              )
            }
          />
          <DetailsSection
            title={t('MyCommunity.Base Referral')}
            details={personalData?.isBaseReferralActive ? 'Active' : 'Inactive'}
            icon={
              personalData?.isBaseReferralActive ? (
                <ActiveIcon />
              ) : (
                <InactiveIcon />
              )
            }
          />
          <DetailsSection
            title={t('MyCommunity.Builder Generational')}
            details={
              personalData?.isBuilderGenerationActive ? 'Active' : 'Inactive'
            }
            icon={
              personalData?.isBuilderGenerationActive ? (
                <ActiveIcon />
              ) : (
                <InactiveIcon />
              )
            }
          />
        </SectionWrapper>

        <SectionWrapper title={t('MyCommunity.Personal Rewards')}>
          <DetailsSection
            title="Total Personal Staking"
            details={`$${
              personalReWard?.totalPersonalStaking
                ? Number(
                    truncateToSixDecimals(
                      personalReWard?.totalPersonalStaking,
                      2
                    )
                  )
                : 0
            }`}
          />
          <DetailsSection
            title={t('MyCommunity.Personal Rewards From Staking')}
            details={`$${
              personalReWard?.rewardsFromStaking
                ? Number(
                    truncateToSixDecimals(personalReWard?.rewardsFromStaking, 2)
                  )
                : 0
            }`}
          />
        </SectionWrapper>

        <SectionWrapper title={t('MyCommunity.Machines List')}>
          <SimpleSlider
            className="flex items-center justify-center w-full h-full"
            loop={false}
            navigation={true}
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={isMobile || machineList?.length === 1 ? 1 : 2}
          >
            {machineList && machineList?.length > 0 ? (
              machineList?.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <div className="flex flex-col justify-center items-center gap-4 bg-[#DFF1FA] rounded-xl h-[200px] px-5 py-3">
                    <span className="text-sm font-medium">{item?.name}</span>
                    <img
                      src={item?.imageUrl}
                      alt=""
                      className="h-[100px] w-[100px] mix-blend-multiply"
                    />
                    <span className="text-xs font-semibold">
                      $
                      {item?.stakeLimit &&
                        setDecimalPlaces(item?.stakeLimit, 2)}
                    </span>
                    <span className="text-[#7D7D7D] font-normal text-xs">
                      Staking: $
                      {item?.collatoral
                        ? setDecimalPlaces(item?.collatoral, 2)
                        : 0}
                    </span>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <div className="text-xs italic text-center text-gray-400">
                No Machines
              </div>
            )}
          </SimpleSlider>
        </SectionWrapper>

        <SectionWrapper title={t('general.Community')}>
          <DetailsSection
            title={t('MyCommunity.TNodes')}
            details={community?.totalNode}
          />
          <DetailsSection
            title={t('MyCommunity.Total First Line Nodes')}
            details={community?.firstLineNode}
          />
          <DetailsSection
            title={t('MyCommunity.Total Staking')}
            details={`$${community?.totalStaking ? Number(truncateToSixDecimals(community?.totalStaking, 2)) : 0}`}
          />
          <DetailsSection
            title={t('MyCommunity.Total First Line Staking')}
            details={`$${community?.totalFirstLineStaking ? Number(truncateToSixDecimals(community?.totalFirstLineStaking, 2)) : 0}`}
          />
          <DetailsSection
            title={t('MyCommunity.Base Referral Levels')}
            details={community?.baseReferralLevels ?? '-'}
          />
          <DetailsSection
            title={t('MyCommunity.Builder Generational Levels')}
            details={community?.builderGenerationalLevels ?? '-'}
          />
        </SectionWrapper>
      </div>

      {(getPersonalAndMachineDetailsLoading ||
        getMachineStakeDetailsLoading) && (
        <div className="opacity-70 w-[100%] bg-white h-[100%] absolute top-1 flex justify-center items-center z-[50] ">
          <Spinner className="h-6 text-primary animate-spin" />
        </div>
      )}
    </div>
  );
};

export default SelectedUserInfo;
