import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';

const useHotjar = (siteId: number, hotjarVersion: number = 6) => {
  useEffect(() => {
    try {
      Hotjar.init(siteId, hotjarVersion);
    } catch (error) {
      console.error('Error initializing Hotjar:', error);
    }
  }, [siteId, hotjarVersion]);
};

export default useHotjar;
