import React from 'react';
import { useTranslation } from 'react-i18next';

function PlatformComingSoon() {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center h-full absolute top-0 w-full">
      {t('profile.coming')}
    </div>
  );
}

export default PlatformComingSoon;
