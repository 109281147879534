import {
  facebook,
  linkedin,
  share,
  telegram,
  twitter,
} from '../images/Socials';

const environment = process.env;

export const MEMBERSHIP_DATE = '1722423559662';

export const UNAUTHORIZED = 'Unauthorized';
export const INVALID_TOKEN = 'Invalid token.';

export const ACCESS_TOKEN = 'accessToken';
export const LOGIN_TOKEN = 'loginToken';

export const PLATFORM_ENVIRONMENT = environment.REACT_APP_ENVIRONMENT;

const DEV_ENV_VARS = {
  SOCKET_BASE_URL: `${environment.REACT_APP_API_INSTANCE_HOMNIFI_DEV}homnifi`,
  CLOUDK_SOCKET_BASE_URL: `${environment.REACT_APP_API_INSTANCE_CLOUDK_DEV}sockets`,
  TWO_ACCESS_TOKEN: environment.REACT_APP_TWO_ACCESS_TOKEN_DEV,
  KMALL_API_URL: environment.REACT_APP_KMALL_API_URL_DEV,
  API_INSTANCE_HOMNIFI: process.env.REACT_APP_API_INSTANCE_HOMNIFI_DEV,
  API_INSTANCE_CLOUDK: process.env.REACT_APP_API_INSTANCE_CLOUDK_DEV,
  API_INSTANCE_BID: process.env.REACT_APP_API_INSTANCE_BID_DEV,
  WEBSITE_DOMAIN: process.env.REACT_APP_WEBSITE_DOMAIN_DEV,
  WALLEK_SERVICE_URI: process.env.REACT_APP_WALLEK_SERVICE_URI_DEV,
  WALLEK_API_KEY: process.env.REACT_APP_WALLEK_API_KEY_DEV,
  WALLEK_URL: process.env.REACT_APP_WALLEK_URL_DEV,
  DASHBOARD_REFERRAL: `${environment.REACT_APP_WEBSITE_DOMAIN_DEV}auth/signup?ref=`,
  X_API_KEY: environment.REACT_APP_X_API_KEY_DEV,
  SHOP_CONSTANT: {
    externalId: environment.REACT_APP_PLATFORM_ID_DEV,
  },
  HORYSMALL_CONSTANT: {
    externalId: environment.REACT_APP_HORYSMALL_ID_DEV,
  },
  QUANTWISE_CONSTANT: {
    externalId: environment.REACT_APP_QUANTWISE_ID_DEV,
  },
  WALLEK_CONSTANT: {
    externalId: environment.REACT_APP_WALLEK_ID_DEV,
  },
  RAMPSTARTER_CONSTANT: {
    externalId: environment.REACT_APP_RAMPSTARTER_ID_DEV,
  },
  // RAMPSTARTER_CONSTANT_2 : {
  //   externalId: environment.REACT_APP_RAMPSTARTER_TWO_ID_DEV,
  // };
  XERA_CONSTANT: {
    externalId: environment.REACT_APP_XERA_ID_DEV,
  },
  CLOUDK_CONSTANT: {
    externalId: environment.REACT_APP_CLOUDK_ID_DEV,
  },
};

const PROD_ENV_VARS = {
  SOCKET_BASE_URL: `${environment.REACT_APP_API_INSTANCE_HOMNIFI_PROD}homnifi`,
  CLOUDK_SOCKET_BASE_URL: `${environment.REACT_APP_API_INSTANCE_CLOUDK_PROD}sockets`,
  TWO_ACCESS_TOKEN: environment.REACT_APP_TWO_ACCESS_TOKEN_PROD,
  HASH_ITHDRAW_LINK: environment.REACT_APP_HASH_WITHDRAW_LINK_PROD,
  KMALL_API_URL: environment.REACT_APP_KMALL_API_URL_PROD,
  API_INSTANCE_HOMNIFI: process.env.REACT_APP_API_INSTANCE_HOMNIFI_PROD,
  API_INSTANCE_CLOUDK: process.env.REACT_APP_API_INSTANCE_CLOUDK_PROD,
  API_INSTANCE_BID: process.env.REACT_APP_API_INSTANCE_BID_PROD,
  WEBSITE_DOMAIN: process.env.REACT_APP_WEBSITE_DOMAIN_PROD,
  WALLEK_SERVICE_URI: process.env.REACT_APP_WALLEK_SERVICE_URI_PROD,
  WALLEK_API_KEY: process.env.REACT_APP_WALLEK_API_KEY_PROD,
  WALLEK_URL: process.env.REACT_APP_WALLEK_URL_PROD,
  DASHBOARD_REFERRAL: `${environment.REACT_APP_WEBSITE_DOMAIN_PROD}auth/signup?ref=`,
  X_API_KEY: environment.REACT_APP_X_API_KEY_PROD,
  SHOP_CONSTANT: {
    externalId: environment.REACT_APP_PLATFORM_ID_PROD,
  },
  HORYSMALL_CONSTANT: {
    externalId: environment.REACT_APP_HORYSMALL_ID_PROD,
  },
  QUANTWISE_CONSTANT: {
    externalId: environment.REACT_APP_QUANTWISE_ID_PROD,
  },
  WALLEK_CONSTANT: {
    externalId: environment.REACT_APP_WALLEK_ID_PROD,
  },
  RAMPSTARTER_CONSTANT: {
    externalId: environment.REACT_APP_RAMPSTARTER_ID_PROD,
  },
  // RAMPSTARTER_CONSTANT_2 : {
  //   externalId: environment.REACT_APP_RAMPSTARTER_TWO_ID_PROD,
  // };
  XERA_CONSTANT: {
    externalId: environment.REACT_APP_XERA_ID_PROD,
  },
  CLOUDK_CONSTANT: {
    externalId: environment.REACT_APP_CLOUDK_ID_PROD,
  },
};

export const SOCIALS = [
  {
    title: 'Telegram',
    icon: telegram,
    link: environment.REACT_APP_TELEGRAM,
    color: 'telegram-bg',
  },
  {
    title: 'Linkedin',
    icon: linkedin,
    link: environment.REACT_APP_LINKEDIN,
    color: 'linkedin-bg',
  },
  {
    title: 'TwitterX',
    icon: twitter,
    link: environment.REACT_APP_X,
    color: 'twitter-bg',
  },
  {
    title: 'Facebook',
    icon: facebook,
    link: environment.REACT_APP_FACEBOOK,
    color: 'facebook-bg',
  },
  // {
  //   title: 'Youtube',
  //   icon: youtube,
  //   link: environment.REACT_APP_YOUTUBE,
  //   color: 'youtube-bg',
  // },
  // {
  //   title: 'Instagram',
  //   icon: instagram,
  //   link: environment.REACT_APP_INSTAGRAM,
  //   color: 'instagram-bg',
  // },
  {
    title: 'share',
    icon: share,
    link: 'share',
    color: 'share-bg',
  },
];

export const FOOTER_SOCIALS = {
  telegram: environment.REACT_APP_TELEGRAM,
  telegramChatRoom: environment.REACT_APP_TELEGRAM_CHAT_ROOM,
  linkedIn: environment.REACT_APP_LINKEDIN,
  twitterX: environment.REACT_APP_X,
  facebook: environment.REACT_APP_FACEBOOK,
  youtube: environment.REACT_APP_YOUTUBE,
  instagram: environment.REACT_APP_INSTAGRAM,
};

export const LINKS = {
  LEGAL: environment.REACT_APP_LEGAL_LINK,
  WIKI: environment.REACT_APP_WIKI_LINK,
  HOW_IT_WORKS: environment.REACT_APP_HOW_IT_WORKS,
  HOW_IT_WORKS_SMLYK: environment.REACT_APP_HOW_IT_WORKS_SMLYK,
  SUPPORT_URL: environment.REACT_APP_SUPPORT_URL,
};

export const FEATURES = {
  showSignup: environment.REACT_APP_SHOW_SIGNUP,
  showCloudK: environment.REACT_APP_SHOW_CLOUDK,
  enableClaimButton: environment.REACT_APP_ENABLE_CLAIM_BUTTON,
  showPromotionalBanner: environment.REACT_APP_SHOW_PROMOTIONAL_BANNER,
  myTreeEmailLevel: environment.REACT_APP_MYTREE_EMAIL_LEVEL,
  showEditButton: environment.REACT_APP_SHOW_EDIT_PROFILE,
  show3dMachine: environment.REACT_APP_SHOW_3D_MACHINE,
  showTranslation: environment.REACT_APP_SHOW_TRANSLATION,
  showPurchaseSimulator: environment.REACT_APP_SHOW_PURCHASE_SIMULATOR,
  newMyTreeAPIV1: environment.REACT_APP_NEW_MY_TREE_API_V1,
  showMyTree2: environment.REACT_APP_SHOW_MY_TREE2,
  showTBalance: environment.REACT_APP_SHOW_TBALANCE,
  showGen2: environment.REACT_APP_SHOW_GEN2,
  showMintingPower: environment.REACT_APP_SHOW_ADDITIONAL_MINTING,
  showPandaDocs: environment.REACT_APP_SHOW_PANDA_DOCS,
  cloudKPassword: environment.REACT_APP_CLOUDK_PASSWORD,
  showDistributorMembership: environment.REACT_APP_SHOW_DISTRIBUTOR_MEMBERSHIP,
  showCloudKInImpersonate: environment.REACT_APP_SHOW_CLOUDK_IN_IMPERSONATE,
};

export const HCATCHA_SITEKEY = environment.REACT_APP_HCAPTCHA_SITEKEY;

export const TOASTER_AUTOCLOSE_TIME =
  environment.REACT_APP_TOASTER_AUTOCLOSE_TIME;

export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export const PLATFORM_NAME = environment.REACT_APP_PLATFORM_NAME;

export const ENV_VARS =
  PLATFORM_ENVIRONMENT === 'DEV' ? DEV_ENV_VARS : PROD_ENV_VARS;
