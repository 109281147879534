import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setCloudkSlice, setSelectedMintingTokenLicense } from './cloudkSlice';
import ApiRequestCloudk from '../../utils/network/ApiRequestCloudk';
import {
  ADD_MINTING_TOKEN,
  ALL_DETAILS,
  ALL_HARDWARES,
  ALL_MINTERS_LIST,
  ALL_USER_LICENSES,
  ALL_USER_LINK_LICENSES,
  ALL_USER_UNLOCK_LICENSES,
  CLAIM_CLOUDK_REWARD,
  CLAIM_HARDWARE_GENERATED_REWARD,
  CLAIMABLE_REWARD_CLOUDK,
  CLAIMED_REWARD_AUTO_LINK,
  CLOUDK_HARDWARE_TRANSACTION_HISTORY_V2,
  CLOUDK_TRANSACTION_HISTORY,
  CLOUDK_TRANSACTION_HISTORY_V2,
  CONNECT_NEW_MINTER,
  DE_LINK_HARDWARE,
  LINK_LICENSE_WITH_MINTER,
  LINKED_MYLY,
  MACHINE_REWARD_DAILY_ACTIVITY,
  MACHINE_REWARD_HISTORY,
  MINTER_REWARD_HISTORY_LICENSES_DROPDOWN,
  MINTER_REWARDS,
  REBOOT_MINTER,
  REWARD_ANALYTICS_GRAPH_API,
  SPECIFIC_CLAIM_CLOUDK_REWARD,
  TRANSACTION_HISTORY_LICENSE_FILTER,
  UNLOCK_LICENSE,
  UNLOCKED_LICENSE_CLAIM,
  UNLOCKED_LICENSE_DETAILS,
  UNLOCKED_LICENSE_TRANSACTIONS,
  UNLOCKED_LICENSE_VENTING,
  USER_LICENSE_ACTIVE_COUNT,
  USER_MINTER_DETAIL,
  USER_MINTER_LINKED_LICENSE,
} from '../../utils/network/ApiEndpoints';
import { AxiosResponse } from 'axios';
import { licenseUnlockActions } from '../Cloudk2/cloudk2LicenseUnlockSlice';
import { INVALID_TOKEN, UNAUTHORIZED } from '../../utils/constants';
import { LicenseType } from './cloudkTypes';
import { useTranslation } from 'react-i18next';

export const useCloudkActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cloudKClaimableReward = async () => {
    dispatch(
      setCloudkSlice({
        claimableRewardLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLAIMABLE_REWARD_CLOUDK,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            claimableRewardData: response.data.data.tokens,
            isGenerationSwitch: response.data.data.isAutoCompoundOn,
            claimableRewardLoading: false,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            claimableRewardLoading: false,
          })
        );

        if (error.data) {
          // toast.error(error.data.message);
        }

        return error;
      });
  };

  const rewardAnalyticsGraphDataFunc = async (
    type: string,
    timePeriod: string
  ) => {
    dispatch(
      setCloudkSlice({
        rewardAnalyticsGraphLoading: true,
      })
    );

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${REWARD_ANALYTICS_GRAPH_API}`,
        params: {
          type: type,
          timePeriod: timePeriod,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            rewardAnalyticsGraphData: data?.reverse(),
            rewardAnalyticsGraphLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            rewardAnalyticsGraphLoading: false,
          })
        );
        if (error.data) {
          // toast.error(error.data.message);
        }
        return error;
      });
  };

  const getAllUserLinkLicenses = async ({
    page,
    limit,
    query,
    toDate,
    fromDate,
    status,
  }: any) => {
    dispatch(
      setCloudkSlice({
        allUserLinkLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_USER_LINK_LICENSES,
        params: {
          page,
          limit,
          query,
          toDate,
          fromDate,
          status,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allUserLinkLicencesData: response.data.data,
            allUserLinkLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allUserLinkLicienceLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const getUserUnLockAllLicenses = async ({
    page,
    limit,
    query,
    toDate,
    fromDate,
    status,
  }: any) => {
    dispatch(
      setCloudkSlice({
        allUserUnLockLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_USER_UNLOCK_LICENSES,
        params: {
          page,
          limit,
          query,
          toDate,
          fromDate,
          status,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allUserUnLockLicencesData: response.data.data,
            allUserUnLockLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allUserUnLockLicienceLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const getAllLicenses = async ({ limit = 100 } = {}) => {
    dispatch(
      setCloudkSlice({
        allLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_USER_LICENSES,
        params: {
          limit,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allLicencesData: response?.data?.data,
            allLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allLicienceLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };
  const unlockLicense = async (licenseId: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: `${UNLOCK_LICENSE}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };
  const fetchUnlockedLicenseDetails = async (licenseId: string) => {
    dispatch(licenseUnlockActions.setSummaryLoading(true));
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${UNLOCKED_LICENSE_DETAILS}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        if (response.data?.data) {
          dispatch(licenseUnlockActions.setSummaryData(response.data?.data));
        }
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        throw error;
      })
      .finally(() => {
        dispatch(licenseUnlockActions.setSummaryLoading(false));
      });
  };
  const claimUnlockedLicenseDailyRewards = async (licenseId: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: `${UNLOCKED_LICENSE_CLAIM}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        if (response.data.status) {
          toast.success(`Daily ${t('defi.myCloudk.licenses.toast.rewards')}`);
          fetchUnlockedLicenseDetails(licenseId);
          fetchUnlockedLicenseVenting(licenseId);
          fetchUnlockedLicenseTransactions(licenseId, { page: 1, limit: 5 });
        }
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(licenseUnlockActions.setSummaryLoading(false));
      });
  };
  const fetchUnlockedLicenseVenting = async (licenseId: string) => {
    dispatch(licenseUnlockActions.setVestingTimelineLoading(true));

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${UNLOCKED_LICENSE_VENTING}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        if (response.data?.data) {
          dispatch(
            licenseUnlockActions.setVestingTimelineData(response.data?.data)
          );
        }
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(licenseUnlockActions.setVestingTimelineLoading(false));
      });
  };
  const fetchUnlockedLicenseTransactions = async (
    licenseId: string,
    filters: object = {},
    isStateSet: boolean = true
  ) => {
    isStateSet && dispatch(licenseUnlockActions.setTransactionsLoading(true));

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${UNLOCKED_LICENSE_TRANSACTIONS}/${licenseId}`,
        params: {
          ...filters,
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          isStateSet &&
            dispatch(
              licenseUnlockActions.setTransactionsData({
                totalPages: response.data?.data.totalPages,
                totalCount: response.data?.data.totalCount,
                list: response.data?.data?.list,
              })
            );
        }
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      })
      .finally(() =>
        dispatch(licenseUnlockActions.setTransactionsLoading(false))
      );
  };

  const rebootMinter = async (userMinter: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: REBOOT_MINTER,
        data: {
          userMinter,
        },
      })
      .then((response: any) => {
        toast.success('Minter rebooted successfully!');
        return response;
      })
      .catch((error: any) => {
        toast.error(error.data.message);
        return error;
      });
  };

  const getAllDetails = async () => {
    dispatch(
      setCloudkSlice({
        upgradeAbleMachinesLoading: true,
        detailDataLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_DETAILS,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            upgradeAblesMachinesData: response.data.data,
            upgradeAbleMachinesLoading: false,
            detailDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            upgradeAbleMachinesLoading: false,
            detailDataLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };
  const getAllHardwares = async ({ limit = 100 } = {}) => {
    dispatch(
      setCloudkSlice({
        allHardwareLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_HARDWARES,
        params: {
          limit,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allHArdwaresData: response?.data?.data?.list,
            allHardwareLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allHardwareLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const getCloudKTransactionHistory = async (
    { page = 1, limit = 10, id, type, fromDate, toDate }: any,
    isStateSet: boolean = true
  ) => {
    isStateSet &&
      dispatch(
        setCloudkSlice({
          transactionHistoryLoading: true,
        })
      );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLOUDK_TRANSACTION_HISTORY,
        params: {
          page,
          limit,
          userLicenseId: id,
          type,
          fromDate,
          toDate,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        isStateSet &&
          dispatch(
            setCloudkSlice({
              transactionHistoryData: data,
            })
          );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            transactionHistoryLoading: false,
          })
        );
      });
  };
  const getCloudKTransactionHistoryV2 = async (type: string, params: any) => {
    dispatch(
      setCloudkSlice({
        transactionHistoryType: type,
        transactionHistoryLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${CLOUDK_TRANSACTION_HISTORY_V2}/${type?.toLocaleLowerCase()}`,
        params,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            transactionHistory: response.data?.data?.list ?? [],
            transactionHistoryLoading: false,
            transactionHistoryTotalPages: response.data?.data?.totalPages ?? 1,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            transactionHistoryLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getAllMintersList = async (params: any) => {
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${ALL_MINTERS_LIST}`,
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getCloudKTransactionHistoryFilters = async (type: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `/user/license/transaction-history/${type}/filter`,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            transactionHistoryLicenseIdsFilter:
              response?.data?.data?.licenseList ?? [],
            transactionHistoryStatusesFilter:
              response?.data?.data?.status ?? [],
          })
        );
        return response;
      })
      .catch((error) => {
        if (error?.data) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const getCloudKHardwareTransactionHistoryV2 = async (
    type: string,
    params: any,
    id?: number | string
  ) => {
    dispatch(
      setCloudkSlice({
        hardwareHistoryType: type,
        hardwareHistoryLoading: true,
      })
    );
    const requestParams = id ? { ...params, minterName: id } : { ...params };

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${CLOUDK_HARDWARE_TRANSACTION_HISTORY_V2}/${type?.toLocaleLowerCase()}`,
        params: requestParams, // Correctly passing `params`
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            hardwareHistory: response.data?.data?.list ?? [],
            hardwareHistoryLoading: false,
            HardwareTransactionHistoryTotalPages:
              response.data?.data?.totalPages ?? 1,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            hardwareHistoryLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const getCloudKHardwareTransactionHistoryFilters = async (type: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `/user/minter/transaction-history/${type}/filter`,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            hardwareTransactionHistoryFilters: response?.data?.data ?? {},
            // hardwareTransactionHistoryStatusesFilter:
            // response?.data?.data?.status ?? [],
          })
        );
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getMachineRewardHistory = async (
    { page = 1, limit = 10, fromDate, toDate, licenseId, minterId }: any,
    isStateSet: boolean = true
  ) => {
    isStateSet &&
      dispatch(
        setCloudkSlice({
          machineRewardHistoryLoading: true,
        })
      );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MACHINE_REWARD_HISTORY,
        params: {
          page,
          limit,
          fromDate,
          toDate,
          licenseId,
          minterId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        isStateSet &&
          dispatch(
            setCloudkSlice({
              machineRewardHistoryData: data,
            })
          );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            machineRewardHistoryLoading: false,
          })
        );
      });
  };

  const getAllMachinesRewardHistory = async ({
    page = 1,
    limit = 10,
    fromDate,
    toDate,
    licenseId,
  }: any) => {
    dispatch(
      setCloudkSlice({
        allMachinesRewardHistoryLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${MACHINE_REWARD_HISTORY}/all`,
        params: {
          page,
          limit,
          fromDate,
          toDate,
          licenseId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            allMachinesRewardHistoryData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            allMachinesRewardHistoryLoading: false,
          })
        );
      });
  };

  const getMinterLicenses = async ({ minterId }: { minterId?: string }) => {
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MINTER_REWARD_HISTORY_LICENSES_DROPDOWN,
        params: {
          minterId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            machineRewardsHistoryLicensesDropdown: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const addMintingTokenFunc = async (data: any, page: any) => {
    dispatch(setCloudkSlice({ createMintingTokenLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: ADD_MINTING_TOKEN,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ createMintingTokenLoading: false }));
        getAllLicenses();
        getAllUserLinkLicenses({
          limit: 10,
          page: page,
          status: '',
          date: '',
          query: '',
          fromDate: null,
          toDate: null,
        });
        if (response?.data.status) {
          toast.success(response?.data?.message);
          getTotalLinkedFunc();
          getCloudKTransactionHistory({
            page: 1,
            limit: 10,
          });
        }
        return response;
      })
      .catch((error: any) => {
        toast.error(error.data.message || 'Staking error');
        dispatch(setCloudkSlice({ createMintingTokenLoading: false }));
        return error;
      });
  };

  const connectNewMinterFunc = async (data: any) => {
    dispatch(setCloudkSlice({ connectNewMinterLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CONNECT_NEW_MINTER,
        data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ connectNewMinterLoading: false }));
          toast.success(response.data.data);
          getAllHardwares();
          return response;
        }
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ connectNewMinterLoading: false }));
        toast.error(error.data.message);
        return error;
      });
  };

  const getDailyMinterActivityFunc = async (data: any) => {
    const { id, month = 1, year = 2024 } = data;
    dispatch(
      setCloudkSlice({
        dailyActivityLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${MACHINE_REWARD_DAILY_ACTIVITY}/${id}`,
        params: {
          month,
          year,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setCloudkSlice({
            dailyActivityData: data[0],
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            dailyActivityLoading: false,
          })
        );
      });
  };

  const deLinkLicenseFunc = async (data: any) => {
    dispatch(setCloudkSlice({ deLinkLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: DE_LINK_HARDWARE,
        data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ deLinkLoading: false }));

          data.type === 'phone'
            ? toast.success(
                t('defi.myCloudk.licenses.toast.licenseDelinked', {
                  dynamic: 'phone',
                })
              )
            : toast.success(
                t('defi.myCloudk.licenses.toast.licenseDelinked', {
                  dynamic: 'minter',
                })
              );
          getAllLicenses();
          getAllHardwares();
          getUserMinterLinkedLicenses({
            page: 1,
            limit: 10,
            id: data?.minterId,
          });
          getCloudKTransactionHistory({
            page: 1,
            limit: 10,
          });
          return response;
        }
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ deLinkLoading: false }));
        toast.error(error.data.message);
        return error;
      });
  };

  const getTotalLinkedFunc = async () => {
    dispatch(
      setCloudkSlice({
        upgradeAbleMachinesLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: LINKED_MYLY,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            totalStacked: response.data.data,
            upgradeAbleMachinesLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            upgradeAbleMachinesLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  // const getAllLicensesStatusFunc = async () => {
  //   // dispatch(
  //   //   setCloudkSlice({
  //   //     allLicienceLoading: true,
  //   //   })
  //   // );
  //   return await ApiRequestCloudk()
  //     .request({
  //       method: 'GET',
  //       url: TRANSACTION_HISTORY_LICENSE_STATUS_FILTER,
  //     })
  //     .then((response: AxiosResponse) => {
  //       dispatch(
  //         setCloudkSlice({
  //           allLiciencesStatus: response.data.data,
  //           // allLicienceLoading: false,
  //         })
  //       );
  //       return response;
  //     })
  //     .catch((error: any) => {
  //       // dispatch(
  //       //   setCloudkSlice({
  //       //     allLicienceLoading: false,
  //       //   })
  //       // );
  //       toast.error(error?.data?.message || 'Something went wrong');
  //       return error;
  //     });
  // };
  const getUserMinterDetailFunc = async ({ id }: any) => {
    //  dispatch(
    //    setCloudkSlice({
    //      allLicienceLoading: true,
    //    })
    //  );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: USER_MINTER_DETAIL,
        params: {
          id,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            minterRewardGeneratedData: response.data.data[0],
            // activeLicenses: response.data.data[0].Activecount,
            // inActiveLicenses: response.data.data[0].InactiveCount,
            // totalLicenses:
            //   response.data.data[0].Activecount +
            //   response.data.data[0].InactiveCount,
          })
        );
        return response;
      })
      .catch((error: any) => {
        //  dispatch(
        //    setCloudkSlice({
        //      allLicienceLoading: false,
        //    })
        //  );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };
  const getUserMinterLinkedLicenses = async ({
    id,
    page = 1,
    limit = 10,
  }: any) => {
    dispatch(
      setCloudkSlice({
        minterLinkedLicensesLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: USER_MINTER_LINKED_LICENSE,
        params: {
          page: page,
          limit: limit,
          id,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            minterLinkedLicenses: response.data.data,
            minterLinkedLicensesLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            minterLinkedLicensesLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };
  const getActiveLicensesCountFunc = async () => {
    dispatch(
      setCloudkSlice({
        totalCountLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: USER_LICENSE_ACTIVE_COUNT,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            // allLiciencesIds: response.data.data.list,
            // allLiciencesStatus: response.data.data.status,
            totalCountLoading: false,
            activeLicenses: response.data.data.Activecount,
            inActiveLicenses: response.data.data.InactiveCount,
            expiredLicenses: response.data.data.ExpiredCount,
            totalLicenses: response.data.data.TotalCount,
            rewardGeneratedTokens: response.data.data.RewardGenerated,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            totalCountLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const getAllLicienceIDsFunc = async () => {
    //  dispatch(
    //    setCloudkSlice({
    //      allLicienceLoading: true,
    //    })
    //  );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: TRANSACTION_HISTORY_LICENSE_FILTER,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allLiciencesIds: response?.data?.data?.licensesList,
            allLiciencesStatus: response?.data?.data?.transactionType,
            //  allLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        //  dispatch(
        //    setCloudkSlice({
        //      allLicienceLoading: false,
        //    })
        //  );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const LinkLicenseWithMinterFunc = async (data: any) => {
    dispatch(setCloudkSlice({ linkLicenseWithMinterLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: LINK_LICENSE_WITH_MINTER,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ linkLicenseWithMinterLoading: false }));
        data.type === 'phone'
          ? toast.success(
              t('defi.myCloudk.licenses.toast.license', {
                dynamic: 'phone',
              })
            )
          : toast.success(
              t('defi.myCloudk.licenses.toast.license', {
                dynamic: 'minter',
              })
            );
        getAllLicenses();
        getAllHardwares();
        getCloudKTransactionHistory({
          page: 1,
          limit: 10,
        });
        return response;
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ linkLicenseWithMinterLoading: false }));
        toast.error(error.data.message);
        return error;
      });
  };
  const cloudKClaimableRewardAutoLinkFunc = async (data: any) => {
    dispatch(
      setCloudkSlice({
        claimableRewardAutoLinkLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: CLAIMED_REWARD_AUTO_LINK,
        data,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setCloudkSlice({
              isGenerationSwitch: data.isAutoLink,
              claimableRewardAutoLinkLoading: false,
            })
          );
          toast.success(
            `${data.isAutoLink ? t('defi.myCloudk.licenses.toast.autoLinkActivated') : t('defi.myCloudk.licenses.toast.autoLinkDeactivated')}`
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            claimableRewardAutoLinkLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const singleLicenseAutoLinkFunc = async (data: any) => {
    dispatch(
      setCloudkSlice({
        singleLicenseAutoLinkLoadingId: data.license._id,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: CLAIMED_REWARD_AUTO_LINK,
        data: {
          ...data.body,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setCloudkSlice({
              singleLicenseAutoLinkLoadingId: null,
            })
          );
          toast.success(
            ` ${data.license.isAutoCompoundOn ? t('defi.myCloudk.licenses.toast.autoLinkDeactivated') : t('defi.myCloudk.licenses.toast.autoLinkActivated')}`
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            singleLicenseAutoLinkLoadingId: null,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const hardwareGeneratedRewardClaimfunc = async (data: any) => {
    dispatch(
      setCloudkSlice({
        hardwareClaimLoading: true,
      })
    );
    // toast.success('Reward claimed successfully!');

    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CLAIM_HARDWARE_GENERATED_REWARD,
        data,
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ hardwareClaimLoading: false }));

          if (response.data.status) {
            toast.success(t('defi.myCloudk.licenses.toast.rewards'));
            getMinterRewardFunc(data?.userMinter);
            cloudKClaimableReward();
            getCloudKTransactionHistory({
              page: 1,
              limit: 10,
            });
          }
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            hardwareClaimLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getMinterRewardFunc = async (id: any) => {
    dispatch(
      setCloudkSlice({
        MinterRewardLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${MINTER_REWARDS}/${id}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            MinterRewards: response.data.data,
            MinterRewardLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            MinterRewardLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.data.message) {
          toast.error(error?.data?.message);
        }
        return error;
      });
  };

  const claimCloudkRewardFunc = async () => {
    dispatch(setCloudkSlice({ claimRewardLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CLAIM_CLOUDK_REWARD,
        // data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ claimRewardLoading: false }));
          cloudKClaimableReward();
          if (response.data.status) {
            toast.success(t('defi.myCloudk.licenses.toast.rewards'));
            getCloudKTransactionHistory({
              page: 1,
              limit: 10,
            });
          }
          return response;
        }
      })
      .catch((error: any) => {
        toast.error(error.data.message);
        dispatch(setCloudkSlice({ claimRewardLoading: false }));
        return error;
      });
  };

  const cloudkSpecificLicenseReward = async (data: any) => {
    dispatch(setCloudkSlice({ specificLicenseLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: SPECIFIC_CLAIM_CLOUDK_REWARD,
        data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ specificLicenseLoading: false }));
          cloudKClaimableReward();
          if (response.data.status) {
            toast.success(t('defi.myCloudk.licenses.toast.rewards'));
            getAllUserLinkLicenses({
              limit: 10,
              page: 1,
              status: '',
              date: '',
              query: '',
              fromDate: null,
              toDate: null,
            });
          }
          return response;
        }
      })
      .catch((error: any) => {
        toast.error(error.data.message);
        dispatch(setCloudkSlice({ specificLicenseLoading: false }));
        return error;
      });
  };
  const getSetCloudkSelectedLicense = (selectedLicense: LicenseType | null) => {
    dispatch(setSelectedMintingTokenLicense(selectedLicense));
  };

  return {
    cloudkSpecificLicenseReward,
    claimCloudkRewardFunc,
    cloudKClaimableReward,
    getUserUnLockAllLicenses,
    getAllUserLinkLicenses,
    rewardAnalyticsGraphDataFunc,
    getAllLicenses,
    getAllHardwares,
    getCloudKTransactionHistory,
    getCloudKTransactionHistoryV2,
    getCloudKTransactionHistoryFilters,
    getCloudKHardwareTransactionHistoryV2,
    getCloudKHardwareTransactionHistoryFilters,
    getMachineRewardHistory,
    getAllMintersList,
    getAllDetails,
    addMintingTokenFunc,
    connectNewMinterFunc,
    getDailyMinterActivityFunc,
    deLinkLicenseFunc,
    hardwareGeneratedRewardClaimfunc,
    cloudKClaimableRewardAutoLinkFunc,
    getAllLicienceIDsFunc,
    // getAllLicensesStatusFunc,
    unlockLicense,
    fetchUnlockedLicenseDetails,
    fetchUnlockedLicenseVenting,
    fetchUnlockedLicenseTransactions,
    claimUnlockedLicenseDailyRewards,
    getActiveLicensesCountFunc,
    getUserMinterDetailFunc,
    getUserMinterLinkedLicenses,
    getTotalLinkedFunc,
    LinkLicenseWithMinterFunc,
    getMinterRewardFunc,
    getMinterLicenses,
    getAllMachinesRewardHistory,
    rebootMinter,
    getSetCloudkSelectedLicense,
    singleLicenseAutoLinkFunc,
  };
};
