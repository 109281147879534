import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  T_BALANCE,
  T_BALANCE_PRODUCTS,
  T_BALANCE_WALLET_LIST,
  T_BALANCE_PRODUCTS_HISTORY,
  T_BALANCE_PRODUCTS_PURCHASE,
  T_BALANCE_SWAPPED_TOTAL,
} from '../../utils/network/ApiEndpoints.ts';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi.ts';
import { selectTBalance, setTBalanceSlice } from './TBalanceSlice.tsx';

export const useTBalanceActions = () => {
  const dispatch = useDispatch();
  const { TBalanceProducts } = useSelector(selectTBalance);

  const getTBalance = async () => {
    dispatch(
      setTBalanceSlice({
        isTBalanceLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${T_BALANCE}`,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setTBalanceSlice({
            TBalance: data,
          })
        );
        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');
        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceLoading: false,
          })
        );
      });
  };

  const getTBalanceSwappedTotal = async () => {
    dispatch(
      setTBalanceSlice({
        isTBalanceSwappedTotalLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${T_BALANCE_SWAPPED_TOTAL}`,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setTBalanceSlice({
            TBalanceSwappedTotal: data,
          })
        );
        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');
        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceSwappedTotalLoading: false,
          })
        );
      });
  };

  const getTBalanceWalletList = async () => {
    dispatch(
      setTBalanceSlice({
        isTBalanceWalletListLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${T_BALANCE_WALLET_LIST}`,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setTBalanceSlice({
            TBalanceWalletList: data,
          })
        );
        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');

        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceWalletListLoading: false,
          })
        );
      });
  };

  const getAllTBalanceProducts = async (filter: {
    page: number;
    limit: number;
  }) => {
    dispatch(
      setTBalanceSlice({
        isAllTBalanceProductsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${T_BALANCE_PRODUCTS}`,
        params: filter,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setTBalanceSlice({
            AllTBalanceProducts: data,
          })
        );

        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');

        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isAllTBalanceProductsLoading: false,
          })
        );
      });
  };

  const getTBalanceProducts = async (filter: {
    page: number;
    limit: number;
  }) => {
    dispatch(
      setTBalanceSlice({
        isTBalanceProductsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${T_BALANCE_PRODUCTS}`,
        params: filter,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        getAllTBalanceProducts({
          limit: data.totalCount,
          page: 1,
        });
        if (TBalanceProducts) {
          dispatch(
            setTBalanceSlice({
              TBalanceProducts: {
                totalPages: data.totalPages,
                totalCount: data.totalCount,
                currentPage: data.currentPage,
                list: [...TBalanceProducts?.list, ...data?.list],
              },
            })
          );
        } else {
          dispatch(
            setTBalanceSlice({
              TBalanceProducts: data,
            })
          );
        }

        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');

        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceProductsLoading: false,
          })
        );
      });
  };

  const getTBalanceProductsHistory = async (filter: {
    page: number;
    limit: number;
    query?: string | null;
    product?: string | null;
    status?: string | null;
    fromDate?: string | null;
    toDate?: string | null;
  }) => {
    dispatch(
      setTBalanceSlice({
        isTBalanceProductsHistoryLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${T_BALANCE_PRODUCTS_HISTORY}`,
        params: filter,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setTBalanceSlice({
            TBalanceProductsHistory: data,
          })
        );
        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');

        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceProductsHistoryLoading: false,
          })
        );
      });
  };

  const postTBalanceProductsPurchase = async (data: {
    product: string;
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    dispatch(
      setTBalanceSlice({
        isTBalanceProductsPurchaseLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: `${T_BALANCE_PRODUCTS_PURCHASE}`,
        data,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setTBalanceSlice({
            TBalanceProductsPurchase: data,
          })
        );
        toast.success(data);
        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');

        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceProductsPurchaseLoading: false,
          })
        );
      });
  };

  const updateTBalanceProductsPurchase = async (
    data: {
      firstName: string;
      lastName: string;
      email: string;
    },
    id: string
  ) => {
    dispatch(
      setTBalanceSlice({
        isTBalanceProductsPurchaseUpdateLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'PUT',
        url: `${T_BALANCE_PRODUCTS_PURCHASE}/${id}`,
        data,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setTBalanceSlice({
            TBalanceProductsPurchaseUpdate: data,
          })
        );

        toast.success(data);
        return response;
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'something Went Wrong');

        return error;
      })
      .finally(() => {
        dispatch(
          setTBalanceSlice({
            isTBalanceProductsPurchaseUpdateLoading: false,
          })
        );
      });
  };

  return {
    getTBalance,
    getTBalanceProducts,
    getTBalanceWalletList,
    getTBalanceSwappedTotal,
    getTBalanceProductsHistory,
    postTBalanceProductsPurchase,
    updateTBalanceProductsPurchase,
  };
};
