import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../images/svgs';
import { AllPages } from '../../utils/data/data';
import { useTranslation } from 'react-i18next';

type Props = {};

const GlobalAutoComplete = (props: Props) => {
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([...AllPages]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const filterOptions = (input: string) => {
    const filtered = AllPages.filter((option) =>
      option.name.toLowerCase().includes(input.toLocaleLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    filterOptions(e.target.value);
  };

  const handleSelectValue = (value: string, external: boolean = false) => {
    if (value) {
      setIsOpen(false);
      if (external) {
        window.open(value, '_blank');
      } else {
        navigate(value);
      }
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const isPageExist = filteredOptions.find(
        (item: any) => item.name.toLowerCase() === searchTerm.toLowerCase()
      );

      if (isPageExist) {
        handleSelectValue(isPageExist?.value);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative select-none">
      <div
        className={`bg-white border flex gap-2 items-center
            } py-2 px-4 text-xs rounded-md w-full cursor-pointer min-h-9`}
        onClick={toggleDropdown}
      >
        <SearchIcon className="text-default-400" strokeWidth={2.5} width={20} />
        <input
          type="text"
          placeholder={t('general.searchWithDots')}
          className="outline-none border-none"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyPress={handleEnter}
        />
      </div>
      <div
        ref={dropdownRef}
        className={`text-xs flex flex-col w-full rounded-md shadow-lg absolute top-10 left-0 border border-blueGrey bg-white overflow-auto max-h-52 small-scroll ${
          isOpen
            ? 'translate-y-0 visible opacity-100'
            : '-translate-y-10 invisible opacity-0'
        } transition-all ease-in-out duration-300`}
        style={{ zIndex: 99 }}
      >
        {filteredOptions.map((option, index) => (
          <button
            type="button"
            key={index}
            className="p-2 border-b border-blueGrey hover:bg-gray-100 transition-all ease-in-out duration-300"
            onClick={() => handleSelectValue(option.value, option.external)}
          >
            {t(`searchList.${option.name}`)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default GlobalAutoComplete;
