import { memo, useEffect, useState } from 'react';
import CloudKTable from '../../../../Common/CloudKTable/CloudKTable';
import { MinterDetails } from '../../../../Common/types';
import { useCloudkActions } from '../../../../../../../store/cloudk/cloudkAction';
import { id } from 'date-fns/locale';
import {
  capitalizeFirstLetter,
  formatStatusToDispaly,
  toFixedFormat,
} from '../../../../../../../utils/utils';
import moment from 'moment';
import { mapStatusColor } from '../../../../../../../utils/statusColors';
import { useNavigate } from 'react-router-dom';
import { EmptyHardwareList } from '../../../../../../../images/wallets';
import { useTranslation } from 'react-i18next';
import Tooltips from '../../../../../../../components/Tooltips';
import { InfoIcon } from '../../../../../../../images/svgs';

const columns = [
  { label: 'License Name', key: 'licenseName' },
  { label: 'NFT Weightage', key: 'licenseNftWeightage' },
  { label: 'Status', key: 'status' },
  { label: 'Date', key: 'date' },
];

const MinterConnections = ({
  minter,
  refreshConnections,
}: {
  minter: MinterDetails;
  refreshConnections: any;
}) => {
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState([]);
  const { getCloudKHardwareTransactionHistoryV2 } = useCloudkActions();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    (async function () {
      setLoading(true);
      const response = await getCloudKHardwareTransactionHistoryV2(
        'hardware_connections',
        { limit: 4 },
        minter.identifier
      );
      if (response.data?.data?.list) {
        setConnections(response.data?.data?.list);
      }
      setLoading(false);
    })();
  }, [refreshConnections]);

  const renderStatusChip = (transaction: any, row: any) => {
    const isLinked = row === 'linked';
    const isManualDelink = transaction.isManualDelink && row === 'de-linked';
    const autoDelinked = !transaction.isManualDelink && row === 'de-linked';

    const renderTooltipContent = (message: string) => (
      <div className="p-3 text-xs bg-white rounded-lg shadow-md">
        <div className="font-normal">{message}</div>
      </div>
    );

    const renderDeLinkedStatus = (message: string, showTooltip: boolean) => (
      <div className="flex items-start justify-start">
        <span
          className="py-1 px-2 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium flex items-center justify-center w-24" // Fixed width
        >
          {t("dynamic.defi360.cloudk.transactionHistory.table.hardwareConnections.label.De-Linked")}
          {showTooltip && (
            <Tooltips
              containerClass="ml-2 flex items-center justify-center"
              content={renderTooltipContent(message)}
              placement="top-end"
            >
              <InfoIcon className="w-4 h-4 cursor-pointer" />
            </Tooltips>
          )}
        </span>
      </div>
    );

    return (
      <span>
        {isLinked && (
          <span
            className="font-medium text-xs py-1 px-2 rounded-lg !w-24 flex justify-center"
            style={mapStatusColor(row)}
          >
            {formatStatusToDispaly(row)}
          </span>
        )}

        {autoDelinked &&
          renderDeLinkedStatus(
            t('dynamic.defi360.cloudk.transactionHistory.table.hardwareConnections.tooltip.autoDelinked'),
            true
          )}

        {isManualDelink &&
          renderDeLinkedStatus(t('dynamic.defi360.cloudk.transactionHistory.table.hardwareConnections.tooltip.manuallyDelinked'), false)}
      </span>
    );
  };

  const rows = connections.map((connection: any) => ({
    id: connection._id,
    licenseName: connection.licenseName,
    licenseNftWeightage: connection.licenseNftWeightage,
    status: renderStatusChip(connection, connection.status),
    date: moment(connection.actionDate).format('lll'),
  }));

  const NoDataCompoent = (
    <div className="flex flex-col items-center w-full mt-3">
      <img
        src={EmptyHardwareList}
        alt="empty transactions"
        className="h-auto w-28"
      />
      <span className="mt-4 text-cardSecondaryText">
        {t('defi.myCloudk.licenses.noConnection')}
      </span>
    </div>
  );
  return (
    <div className="">
      <CloudKTable
        loading={loading}
        columns={columns}
        rows={rows}
        onlyTable
        wrapperClassNames="!border-0 !p-0 !shadow-none"
        skeletonRows={3}
        renderNoData={NoDataCompoent}
      />
      <div className="mt-5 text-end">
        <button
          onClick={() =>
            navigate(
              `/defi-360/cloudk/reward-history?type=Hardware_Connections&minterId=${minter.identifier}`
            )
          }
          className="text-sm underline text-primary"
        >
          {t('defi.myCloudk.licenses.viewAll')}
        </button>
      </div>
    </div>
  );
};

export default memo(MinterConnections);
