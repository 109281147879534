/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';

import CardLayout from '../../../components/Cards/CardLayout/index.tsx';
import GetCSVData from '../../../components/GetCSVData';
import GlobalSelect from '../../../components/Global/GlobalSelect';
import GlobalTable from '../../../components/Global/GlobalTable';
import useDebounce from '../../../hooks/useDebounceHook';
import GlobalHeader from '../../../components/Global/GlobalHeader.tsx';
import EditIcon from '../../../SVGIcons/TBalance/EditIcon.tsx';
import EditButton from '../../../components/common/EditButton.tsx';
import { selectTBalance } from '../../../store/TBalance/TBalanceSlice.tsx';
import { useTBalanceActions } from '../../../store/TBalance/TBalanceActions.tsx';
import { formatUTCtoLocaleDateTime } from '../../../utils/helper.ts';
import GlobalButton from '../../../components/Global/GlobalButton.tsx';
import { DownloadIcon } from '../../../images/svgs/index.ts';
import PDFTemplate from '../../../components/pdfDownload';

type TTransactionStatus = {
  [key: string]: {
    label: string;
    color: string;
    title: string;
  };
};

const TransactionStatus: TTransactionStatus = {
  inProcess: {
    label: 'In Process',
    color: '#1B9FDA',
    title: 'In Progress',
  },
  processed: {
    label: 'Processed',
    color: '#5BAC0A',
    title: 'Processed',
  },
};

const headCells = [
  {
    id: 'id',
    label: `Purchase Id`,
    dataClass: 'w-[15%]',
  },
  {
    id: 'product',
    label: 'Product',
    dataClass: 'w-[10%]',
  },
  {
    id: 'user',
    label: 'User',
    dataClass: 'w-[20%]',
  },
  {
    id: 'amount',
    label: 'Amount',
    dataClass: 'w-[10%]',
  },

  {
    id: 'status',
    label: 'Status',
    dataClass: 'w-[10%]',
  },
  {
    id: 'purchase',
    label: 'Date of Purchase',
    dataClass: 'w-[15%]',
  },
  {
    id: 'update',
    label: 'Date of Update',
    dataClass: 'w-[15%]',
  },

  {
    id: 'action',
    label: 'Action',
    dataClass: 'w-[25%]',
  },
];

export default function TransactionHistory({
  openOrderDetailModal,
  setType,
}: {
  openOrderDetailModal: (product: any) => void;
  setType: (e: string) => void;
}) {
  const { t } = useTranslation();

  const FALLBACK_OPTIONS = [
    {
      value: '0',
      name: '-',
    },
  ];

  const typeDropdownOptions = TransactionStatus
    ? Object.keys(TransactionStatus).map((item) => ({
        value: item,
        name: TransactionStatus[item].label,
      }))
    : FALLBACK_OPTIONS;

  const {
    isTBalanceProductsHistoryLoading,
    TBalanceProductsHistory,
    isAllTBalanceProductsLoading,
    AllTBalanceProducts,
  } = useSelector(selectTBalance);

  const { getTBalanceProductsHistory } = useTBalanceActions();

  const [searchValue, setSearchValue] = useState<string>('');
  const [resetFilter, setResetFilter] = useState<boolean>(false);
  const [downloadTransaction, setDownloadTransaction] = useState<any>([]);
  const [dataChanged, setDateChanged] = useState<boolean>(false);
  const [resetDisable, setResetDisable] = useState(
    !TBalanceProductsHistory?.list?.length
  );
  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    fromDate: null,
    toDate: null,
    product: null,
    status: null,
  });

  const { debouncedValue: debouncedSearchValue } = useDebounce(
    searchValue,
    1000
  );
  const filterEmptyCheck = () =>
    !dataChanged && !filters.status && !filters.product && !searchValue;

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');
    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
      page: 1,
    }));
  };

  const handleEditClick = (product: any) => {
    setType('EDIT');
    openOrderDetailModal(product);
  };

  const rows = TBalanceProductsHistory?.list?.map((item) => ({
    id: item?.productUniqueId,
    product: <span>{item?.tProduct?.name}</span>,
    user: (
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 text-sm">
          <span>{item?.lastEditId?.firstName}</span>
          <span>{item?.lastEditId?.lastName}</span>
        </div>
        <span className="text-[#7D7D7D] text-xs">
          {item?.lastEditId?.email}
        </span>
      </div>
    ),
    amount: `TG ${item?.productPrice}`,
    status: (
      <span
        style={{
          color: TransactionStatus[item?.status]?.color,
        }}
        title={TransactionStatus[item?.status]?.title || ''}
      >
        {TransactionStatus[item?.status]?.label || ''}{' '}
      </span>
    ),
    purchase: item?.purchasedDate
      ? formatUTCtoLocaleDateTime(item?.purchasedDate)
      : '-',
    update: item?.lastEditDate
      ? formatUTCtoLocaleDateTime(item?.lastEditDate)
      : '-',
    action: (
      <div className="flex items-center gap-2">
        <EditButton
          isDisabled={TransactionStatus[item?.status]?.label === 'Processed'}
          onClick={() => {
            handleEditClick(item);
          }}
          text="Edit"
          icon={
            <EditIcon
              color={
                TransactionStatus[item?.status]?.label === 'Processed'
                  ? '#AFAFAF'
                  : '#1B9FDA'
              }
            />
          }
        />
        <PDFDownloadLink
          document={<PDFTemplate data={item} />}
          fileName={`OrderConfirmation_${item?.tProduct?.name}_${item?.productUniqueId}.pdf`}
        >
          <GlobalButton
            text={'Download PDF'}
            type="button"
            StartIcon={
              <DownloadIcon className="relative z-10 w-4 h-4 col-span-1 text-white" />
            }
            classNames="!w-[10%] !bg-red-500 hover:!bg-red-600 rounded-lg font-normal !text-sm !py-[10px] px-5 min-w-max place-item-end"
          />
        </PDFDownloadLink>
      </div>
    ),
  }));

  if (
    TBalanceProductsHistory &&
    TBalanceProductsHistory?.list.length &&
    resetDisable
  ) {
    setResetDisable(false);
  }

  useEffect(() => {
    if (debouncedSearchValue.length > 0 || filters) {
      setResetFilter(false);
      getTBalanceProductsHistory({
        ...filters,
        query: debouncedSearchValue,
      });
    }
  }, [filters, debouncedSearchValue]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <GlobalHeader title={'Transaction History'} />

        <CardLayout className="col-span-12" isBorder={false}>
          <section className="flex flex-col items-start justify-between gap-4">
            <GlobalTable
              tableWidth={'min-w-[1400px]'}
              showSearch
              pagination
              resetDateFilter={resetFilter}
              isWallet={false}
              headerClass={
                'bg-gradient-to-r from-gray-800 via-[#6659a2] to-gray-800'
              }
              headStyles={`rounded-b-none !text-white !text-nowrap`}
              emptyDataMessage={t('dynamic.tBalance.NoHistory')}
              rows={rows || []}
              headCells={headCells}
              isLoading={isTBalanceProductsHistoryLoading}
              searchIsLoading={false}
              page={filters.page}
              handleDateDate={handleDateDate}
              totalPages={TBalanceProductsHistory?.totalPages || 0}
              tableContainerClassname={'!items-start lg:!block !rounded-lg'}
              handlePageChange={(page) => {
                setFilters({ ...filters, page });
                getTBalanceProductsHistory({ ...filters, page });
              }}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              showTotalRecords={false}
              searchFieldPlaceholderText={t('Product')}
              paginationPosition={'right'}
              colorItems={['status']}
              tableDropdowns={
                <div className="flex space-x-3 sm:items-center">
                  <>
                    <GlobalSelect
                      name="status"
                      placeholder={t('Status')}
                      isError={false}
                      value={filters.status}
                      getSelectedValue={(item: string): void => {
                        setFilters((prev: any) => ({
                          ...prev,
                          status: item,
                          page: 1,
                        }));
                      }}
                      options={typeDropdownOptions}
                      className="w-32"
                      classNameSelect="rounded-lg py-3 px-0"
                    />

                    <GlobalSelect
                      name="product"
                      placeholder={t('Product')}
                      isError={false}
                      value={filters.product}
                      disabled={isAllTBalanceProductsLoading}
                      getSelectedValue={(item: string): void => {
                        setFilters((prev: any) => ({
                          ...prev,
                          product: item,
                          page: 1,
                        }));
                      }}
                      options={
                        AllTBalanceProducts?.list
                          ? AllTBalanceProducts.list.map((item) => ({
                              ...item,
                              name: `${item?.name} - ${item.price}`,
                              value: item?._id,
                            }))
                          : []
                      }
                      className="w-56"
                      classNameSelect="rounded-lg py-3 px-0"
                    />
                  </>

                  <button
                    className={`${filterEmptyCheck() ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
                    type="button"
                    disabled={resetDisable || filterEmptyCheck()}
                    onClick={(): void => {
                      setFilters({
                        limit: 10,
                        page: 1,
                        fromDate: null,
                        toDate: null,
                        status: null,
                        product: null,
                      });
                      setResetFilter(true);
                      setDateChanged(false);
                      setSearchValue('');
                    }}
                  >
                    {t('general.reset')}
                  </button>
                </div>
              }
              searchdisable={resetDisable}
              filterSearchClassName={`${resetDisable ? 'cursor-not-allowed' : ''} text-xs text-cardBorder !bg-transparent`}
              fromDateDisable={resetDisable}
              fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} !bg-transparent`}
              toDateDisable={resetDisable}
              toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} !bg-transparent`}
              setDateChanged={setDateChanged}
              DownloadCSV={
                <GetCSVData
                  disabled={
                    TBalanceProductsHistory &&
                    TBalanceProductsHistory?.totalPages <= 0
                      ? true
                      : false
                  }
                  transactionData={downloadTransaction}
                  onclick={() => {
                    if (!isTBalanceProductsHistoryLoading) {
                      getTBalanceProductsHistory({
                        limit: TBalanceProductsHistory?.totalCount || 0,
                        page: 1,
                        fromDate: filters.fromDate,
                        toDate: filters.toDate,
                        status: filters.status,
                        product: filters.product,
                      }).then((res) => {
                        const data = res.data.data.list.map((item: any) => {
                          return {
                            PurchaseID: item?.productUniqueId,
                            Product: item?.tProduct?.name,
                            user:
                              item?.lastEditId?.firstName +
                              ' ' +
                              item?.lastEditId?.lastName,
                            email: item?.lastEditId?.email,
                            amount: item?.productPrice,
                            status:
                              TransactionStatus[item?.status]?.label || '',
                            DateOfPurchase: item?.purchasedDate
                              ? formatUTCtoLocaleDateTime(item?.purchasedDate)
                              : '-',
                            DateOfUpdate: item?.lastEditDate
                              ? formatUTCtoLocaleDateTime(item?.lastEditDate)
                              : '-',
                          };
                        });
                        setDownloadTransaction(data);
                      });
                    }
                  }}
                  onDownloadReady={() => {
                    // Callback function to handle any additional logic if needed
                  }}
                />
              }
            />
          </section>
        </CardLayout>
      </div>
    </>
  );
}
