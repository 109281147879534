import { ReactElement, useEffect } from 'react';
import ActivePool from './_components/ActivePool';
import { useCountryPoolActions } from '../../../store/countryPool/poolAction';
import { useSelector } from 'react-redux';
import { selectGlobalPoolSlice } from '../../../store/countryPool/poolSlice';
import { Stack } from '@mui/material';
import MyScore from './_components/MyScore';
import UpcomingPools from './_components/UpcomingPools';
import PoolGenerationHistory from './_components/PoolGenerationHistory';
import PoolRewardHistory from './_components/PoolRewardHistory';
import GlobalHeading from '../../../components/Global/GlobalHeading';
import BannerComp from './_components/BannerComp';
import ScoreHistory from './_components/ScoreHistory';
import { FEATURES } from '../../../utils/constants';
import GlobalHeader from '../../../components/Global/GlobalHeader';
import { selectSocketIO } from '../../../store/socketIO/socketIOSlice';
import useSocketEvent from '../../../hooks/socketIO/useSocketEvent';
import { DepositTransactionResponse } from '../../../types/wallet.types';
import useUserCheck from '../../../hooks/useUserCheck';
import { useTranslation } from 'react-i18next';

const CountryPools = (): ReactElement => {
  const {
    countryBannerToken,
    activePool,
    activePoolLoading,
    myScore,
    upcomingPools,
    scoreHistory,
    rewardHistory,
    tokenType,
    countryBannerData,
    countryBannerLoading,
    claimLoading,
    myScorehistory,
    myScorehistoryLoading,
  } = useSelector(selectGlobalPoolSlice);
  const { t } = useTranslation();

  const {
    getCountryBannerData,
    getUpComingPool,
    getRewardHistory,
    getTokenType,
    getMyScoreHistory,
    getScoreHistory,
    getMyScore,
    getCountryPool,
  } = useCountryPoolActions();

  useEffect((): void => {
    fetchGlobalPool();
  }, []);

  const fetchGlobalPool = async (): Promise<void> => {
    !countryBannerLoading &&
      !countryBannerData &&
      (await getCountryBannerData());
    !activePoolLoading &&
      !activePool &&
      (await getCountryPool({ page: 1, limit: 10 }));
    !myScore && (await getMyScore({ year: '2024' }));
    !upcomingPools && (await getUpComingPool());
    !scoreHistory && (await getScoreHistory({ page: 1, limit: 10 }));
    !rewardHistory && (await getRewardHistory({ page: 1, limit: 10 }));
    !tokenType && (await getTokenType());
    !myScorehistoryLoading &&
      !myScorehistory &&
      (await getMyScoreHistory({ page: 1, limit: 10 }));
  };

  // const { user } = useUserCheck();

  return (
    <div className="flex flex-col bg-pageBackground">
      {countryBannerData?.length ? (
        <BannerComp
          rewardToken={countryBannerToken}
          data={countryBannerData}
          loading={countryBannerLoading}
          claimLoading={claimLoading}
        />
      ) : (
        <></>
      )}
      <div className="flex flex-col gap-4 p-4">
        <GlobalHeader title={t('GlobalPool.countryPool')} />
        <ActivePool data={activePool} />
        <ScoreHistory
          title={t('GlobalPool.yourScoreHistory')}
          data={myScorehistory}
          loading={myScorehistoryLoading}
        />
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
          {/*<MyScore data={myScore} />*/}
          <UpcomingPools
            data={upcomingPools}
            rewardToken={countryBannerToken}
          />
        </Stack>
        {/*<PoolGenerationHistory data={scoreHistory} />*/}
        <PoolRewardHistory data={rewardHistory} typeList={tokenType} />
      </div>
    </div>
  );
};

export default CountryPools;
