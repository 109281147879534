import { ReactElement } from 'react';
import { motion } from 'framer-motion';
import Loader from '../../../components/loader/Loader';

export interface LeaderBoardFilterTabsProps {
    layoutID: string;
    onFilterChange?: (event: string) => void;
    filterColor?: string;
    hoverTextColor?: boolean;
    selectedFilter?: any;
    filterType: { label: string; value: string }[];
    disabled?: boolean;
    setSelectedFilter?: any;
    loader?: boolean
}

function LeaderBoardFilterTab({
    layoutID,
    onFilterChange = (): void => { },
    filterColor = 'bg-gradient-primary-blue',
    hoverTextColor,
    selectedFilter,
    filterType,
    disabled,
    setSelectedFilter,
    loader = false
}: LeaderBoardFilterTabsProps): ReactElement {
    return (
        <div className={`relative flex justify-between items-center bg-[#F5FCFF] border-[1px] border-[#87DAFF] w-auto p-[0.15rem] md:p-1 rounded-md text-primaryGrey ml-auto lg:ml-0 h-fit `}>
            {
                loader && (
                    <div className="absolute inset-0 flex justify-center items-center z-40 bg-white bg-opacity-50 w-full h-full rounded-md">
                        <Loader className="w-6 h-6 !top-1/2 !left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                    </div>
                )}
            {
                filterType.map(
                    (t): ReactElement => (
                        <motion.button
                            key={t.value}
                            disabled={disabled}
                            onClick={(): void => {
                                onFilterChange(t.value);
                                if (setSelectedFilter) {
                                    setSelectedFilter(t.value);
                                }
                            }}
                            className={`relative flex w-full md:w-auto items-center justify-center px-3 py-1   rounded-md cursor-pointer text-xs ${t.value === selectedFilter && !loader
                                ? `text-white `
                                : `hover:${hoverTextColor === true ? 'text-primary' : 'text-[#FF2727]'}`
                                }`}
                        >
                            <span className="relative z-10">{t.label}</span>

                            {t.value === selectedFilter && (
                                <motion.div
                                    layoutId={layoutID}
                                    className={`absolute inset-0 ${loader ? '' : filterColor} rounded-md`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{
                                        type: 'spring',
                                        bounce: 0.2,
                                        duration: 0.6,
                                    }}
                                />
                            )}
                        </motion.button>
                    )
                )
            }
        </div>
    );
}

export default LeaderBoardFilterTab;
