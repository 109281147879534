import React from 'react';
import GlobalHeader from '../../../components/Global/GlobalHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalTextInput from '../../../components/Global/GlobalTextInput';
import GlobalButton from '../../../components/Global/GlobalButton';
import { ReactComponent as EyeOpenIcon } from '../../../SVGIcons/eyeIcon.svg';
import { ReactComponent as EyeCloseIcon } from '../../../SVGIcons/eyeCloseIcon.svg';
import { ReactComponent as WarningIcon } from '../../../SVGIcons/warning.svg';
import { Spinner } from '../../../images/svgs';
import { FEATURES } from '../../../utils/constants';

const Auth = () => {
  const [input, setInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const correctPassword = FEATURES.cloudKPassword;
  const cloudKpassword = localStorage.getItem('cloudkPass');

  React.useEffect(() => {
    if (cloudKpassword) {
      navigate('/defi-360/cloudk');
    }
  }, []);

  const handleSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (input === correctPassword) {
      localStorage.setItem('cloudkPass', 'true');
      navigate(location.state?.from?.pathname || '/defi-360/cloudk');
      setLoading(false);
    } else {
      setError('Incorrect Password');
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword((prevState) => !prevState);

  return cloudKpassword ? (
    <div className="flex items-center justify-center w-full h-32">
      <Spinner className="h-5 animate-spin text-primary" />
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <GlobalHeader title={'Login to CloudK'} />
      <form
        onSubmit={handleSubmit}
        className="p-4 border rounded bg-white w-full max-w-[40rem] m-auto flex flex-col gap-4"
      >
        <div className="text-sm text-secondaryGrey">
          Enter Password for CloudK
        </div>
        <GlobalTextInput
          name="password"
          className={'!rounded-md bg-gray-50'}
          type={showPassword ? 'text' : 'password'}
          placeholder="Enter Cloudk Password"
          value={input}
          style={{ cursor: 'default', appearance: 'none' }}
          errorMessage={error}
          onChange={(e) => {
            setError('');
            setInput(e.target.value);
          }}
        >
          <div className="absolute flex items-center gap-2 right-4">
            <button type="button" onClick={toggleShowPassword}>
              {showPassword ? (
                <EyeCloseIcon className="w-4" />
              ) : (
                <EyeOpenIcon className="w-4" />
              )}
            </button>
          </div>
        </GlobalTextInput>

        <GlobalButton
          containerClassName="rounded text-sm w-full"
          text={'Submit'}
          type="submit"
          disabled={!input}
          loading={loading}
        />

        {/* <input
        type="password"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter password"
        className="w-full p-2 mb-2 border rounded"
      /> */}
        {/* <button
        type="submit"
        className="w-full p-2 text-white bg-blue-500 rounded"
      >
        Submit
      </button> */}
      </form>
    </div>
  );
};

export default Auth;
