import React from 'react';
import CloudkOverview from './CloudOverview';
import AuthComponent from '../Auth/AuthComponent';

const Cloudk = () => {
  return (
    <AuthComponent>
      <CloudkOverview />
    </AuthComponent>
  );
};

export default Cloudk;
