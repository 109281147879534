import useUserCheck from '../../hooks/useUserCheck';
import { bannerTickerYellowImg, bannerTickerImg } from '../../images';
import { usePlatformAction } from '../../store/platform/platformAction';
import { ENV_VARS } from '../../utils/constants';

const TickerBanner = () => {
  const { platformLogin } = usePlatformAction();
  const { user } = useUserCheck();

  const subscriptionType =
    user?.subscriptionType === 'CLIENT' ? 'CLIENT' : 'DEFAULT';

  const config = {
    DEFAULT: {
      bgColor: 'bg-bannerTickerRed',
      textColor: 'text-darkRed',
      buttonColor: 'bg-darkRed',
      bannerImage: bannerTickerImg,
      message:
        'Your membership has expired. Renew and upgrade now to access all features!',
      buttonText: 'Buy Membership',
    },
    CLIENT: {
      bgColor: 'bg-bannerTickerYellow',
      textColor: 'text-darkYellow',
      buttonColor: 'bg-darkYellow',
      bannerImage: bannerTickerYellowImg,
      message:
        'Upgrade to Distributor Membership today! Unlock premium rewards and advanced features.',
      buttonText: 'Click Here To Upgrade',
    },
  };

  const { bgColor, textColor, buttonColor, bannerImage, message, buttonText } =
    config[subscriptionType];

  const buyMembership = async () => {
    const externalId = ENV_VARS.HORYSMALL_CONSTANT.externalId;
    const res = await platformLogin(externalId);
    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }
  };

  return (
    <div
      className={`fixed top-20 w-full z-[49] flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center gap-2 sm:gap-4 px-4 py-2 sm:py-1 ${bgColor}`}
    >
      <div className="w-8 h-8 flex-shrink-0">
        <img
          className="w-full h-full object-contain"
          src={bannerImage}
          alt="banner_ticker_img"
        />
      </div>
      <div
        className={`${textColor} text-xs sm:text-sm text-center sm:text-left flex-1 sm:max-w-[45%] xl:max-w-[40%]`}
      >
        {message}
      </div>
      <button
        className={`${buttonColor} text-white text-xs sm:text-sm px-4 py-1 rounded-md whitespace-nowrap`}
        onClick={buyMembership}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default TickerBanner;
