import classNames from 'classnames';
import DataLoader from '../../../../../components/DataLoader/DataLoader';

interface Props {
  text: string;
  onClick: () => void;
  outlined?: boolean;
  disabled?: boolean;
  className?: string;
  suffixIcon?: React.ReactNode;
  prefixIcon?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
}
const CloudKButton = ({
  text,
  onClick,
  outlined,
  prefixIcon,
  disabled,
  size = 'md',
  className,
  loading,
}: Props) => {
  return (
    <button
      disabled={disabled || loading}
      className={classNames(
        'flex gap-1 items-center bg-primary hover:bg-primary/90 h-[29px] leading-[29px] rounded-3xl py-0.5 px-4 border border-transparent text-sm box-border text-white disabled:bg-primaryGrey/30 disabled:cursor-not-allowed',
        outlined &&
          'bg-white !border-primary !text-primary hover:!bg-white hover:!bg-primary/5 disabled:!bg-[#EFEFEF] disabled:!text-[#ADADAD] disabled:!border-transparent',
        size === 'sm' && 'h-[26px] leading-[26px] py-0.5 px-4 text-xs',
        className
      )}
      onClick={onClick}
    >
      {loading ? (
        <DataLoader
          width={size === 'sm' ? 15 : 20}
          className={size === 'sm' ? 'px-2' : 'px-3'}
          color="#1B9FDA"
        />
      ) : (
        <>
          {prefixIcon}
          {text}
        </>
      )}
    </button>
  );
};

export default CloudKButton;
