import { ENV_VARS, FEATURES } from './constants';
import {
  AppsIcon,
  ArrowRightIcon,
  CloudIcon,
  CommunityIcon,
  DasboardIcon,
  EmptyWalletIcon,
  GlobalPoolIcon,
  QuantwiseIcon,
  RampSidebarIcon,
  ShopIcon,
  VouchersIcon,
  WallekIcon,
  WalleKIcon,
  XeraSidebarIcon,
  TBalanceIcon,
  FileIcon,
} from '../images/svgs';
import { useTranslation } from 'react-i18next';
import useUserCheck from '../hooks/useUserCheck';

const useSidebarData = () => {
  const { t } = useTranslation();
  const { user } = useUserCheck();

  const sidebarData = [
    {
      StartIcon: DasboardIcon,
      EndIcon: '',
      text: t('sideBar.home'),
      key_value: '',
      url: '/',
      children: null,
    },
    {
      StartIcon: EmptyWalletIcon,
      EndIcon: ArrowRightIcon,
      text: t('sideBar.wallet.wallet'),
      url: '/wallet',
      key_value: 'wallet',
      children: [
        {
          text: t('sideBar.wallet.deposit'),
          url: '/wallet/deposit',
          key_value: 'deposit',
        },
        {
          text: t('sideBar.wallet.withdraw'),
          url: '/wallet/withdraw',
          key_value: 'withdraw',
        },
        {
          text: t('sideBar.wallet.swap'),
          url: '/wallet/swap',
          key_value: 'swap',
        },
        {
          text: t('sideBar.wallet.special'),
          url: '/wallet/special-swap',
          key_value: 'special-swap',
        },
        {
          text: t('sideBar.wallet.history'),
          url: '/wallet/history',
          key_value: 'history',
        },
      ],
    },
    {
      StartIcon: ShopIcon,
      EndIcon: '',
      text: 'Shop - HorysMall',
      url: '',
      external: true,
      children: null,
      externalId: ENV_VARS.HORYSMALL_CONSTANT.externalId,
      key_value: 'horysmall',
    },
    {
      StartIcon: CloudIcon,
      EndIcon: ArrowRightIcon,
      text: t('sideBar.defi.defi'),
      url: '/defi-360',
      key_value: 'defi-360',
      children: [
        { text: 'NodeK', url: '/defi-360/nodek', key_value: 'nodek' },
        ...((user?.isImpersonateMode && FEATURES.showCloudKInImpersonate) ||
        (FEATURES.showCloudK && !FEATURES.cloudKPassword)
          ? [
              {
                text: t('sideBar.defi.myCloudk'),
                url: '/defi-360/cloudk',
                key_value: 'nodek',
              },
            ]
          : []),
        {
          text: 'My CloudK',
          key_value: 'cloudK-1.0',
          external: true,
          externalId: ENV_VARS.CLOUDK_CONSTANT.externalId,
        },
      ],
    },
    {
      StartIcon: CommunityIcon,
      EndIcon: ArrowRightIcon,
      text: t('sideBar.myCommunity.myCommunity'),
      url: '/community/super-node',
      key_value: 'community',
      children: [
        {
          text: t('sideBar.myCommunity.superNode'),
          url: '/community/super-node',
          key_value: 'superNode',
        },
        {
          text: 'My First Line',
          url: '/community/my-firstline',
          key_value: 'myFirstLine',
        },
        {
          text: t('sideBar.myCommunity.mytree'),
          url: '/community/my-tree',
          key_value: 'myTree',
        },
        ...(FEATURES.showMyTree2
          ? [
              {
                text: 'My Tree 2',
                url: '/community/my-tree2',
                key_value: 'myTree2',
              },
            ]
          : []),

        {
          text: 'Leaders Board',
          url: '/community/leaders-board',
          key_value: 'leaders-board',
        },
      ],
    },
    {
      StartIcon: GlobalPoolIcon,
      EndIcon: ArrowRightIcon,
      text: t('sideBar.sngp.pool'),
      url: '/global-pool/sngp',
      key_value: 'global-pool',
      children: [
        { text: 'SNGP', url: '/global-pool/sngp', key_value: 'sngp' },
        {
          text: t('sideBar.sngp.countryPools'),
          url: '/global-pool/country-pools',
          key_value: 'country-pools',
        },
      ],
    },
    {
      StartIcon: AppsIcon,
      EndIcon: '',
      text: t('sideBar.external'),
      url: '/external-apps',
      key_value: 'external-apps',
      children: null,
    },
    {
      StartIcon: VouchersIcon,
      EndIcon: '',
      text: t('sideBar.vouchers'),
      url: '/vouchers',
      key_value: 'vouchers',
      children: null,
    },
    {
      StartIcon: WalleKIcon,
      EndIcon: '',
      text: 'WalleK',
      key_value: 'wallek',
      url: '/wallek',
      children: null,
    },
    {
      text: t('sideBar.quickLinks'),
      isDivider: true,
    },
    {
      StartIcon: TBalanceIcon,
      EndIcon: '',
      text: 'T-Balance',
      url: '/t-balance',
      children: null,
      key_value: 't-balance',
    },
    ...(FEATURES.showPandaDocs
      ? [
          {
            StartIcon: FileIcon,
            EndIcon: '',
            text: 'PandaDocs',
            url: '/panda-docs',
            children: null,
            key_value: 'pandaDocs',
          },
        ]
      : []),
    {
      StartIcon: QuantwiseIcon,
      EndIcon: '',
      text: 'Quantwise',
      key_value: 'quantwise',
      url: '',
      external: true,
      children: null,
      externalId: ENV_VARS.QUANTWISE_CONSTANT.externalId,
    },
    {
      StartIcon: RampSidebarIcon,
      EndIcon: '',
      text: 'Rampstarter',
      url: '',
      external: true,
      children: null,
      externalId: ENV_VARS.RAMPSTARTER_CONSTANT.externalId,
      key_value: 'rampstarter',
    },
    {
      StartIcon: XeraSidebarIcon,
      EndIcon: '',
      text: 'My XPro',
      url: ENV_VARS.XERA_CONSTANT.externalId,
      external: false,
      children: null,
      externalId: '',
      key_value: 'myxera',
    },
  ];

  return sidebarData;
};

export default useSidebarData;
