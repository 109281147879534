import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  CLAIM_WALLEK,
  GET_WALLEK_CLAIM_HISTORY,
  GET_WALLEK_DETAILS,
} from '../../utils/network/ApiEndpoints';
import {
  setStakedWallek,
  setWallekClaimHistory,
  setWallekSlice,
  setResetHistoryFilter,
} from './walleKSlice';

type paginateType = {
  page: number;
  limit: number;
  query?: string | null;
  toDate?: any;
  fromDate?: any;
};

export const useWallekActions = () => {
  const dispatch = useDispatch();

  const getWallekStakeDetails = async (page = 1) => {
    dispatch(
      setWallekSlice({
        wallekStakedLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_WALLEK_DETAILS,
        params: {
          page,
          limit: 10,
        },
      })
      .then((response: any) => {
        dispatch(setStakedWallek(response.data.data));
        return response.data;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setWallekSlice({
            wallekStakedLoading: false,
          })
        );
      });
  };

  const getWallekClaimHistory = async (
    data?: paginateType,
    storeOnRedux = true
  ) => {
    dispatch(
      setWallekSlice({
        wallekClaimHistoryLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_WALLEK_CLAIM_HISTORY,
        params: data,
      })
      .then((response: any) => {
        const data = response.data.data;
        storeOnRedux && dispatch(setWallekClaimHistory(data));
        return response.data;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setWallekSlice({
            wallekClaimHistoryLoading: false,
          })
        );
      });
  };

  const claimWallekStakeAmount = async (id: string) => {
    dispatch(
      setWallekSlice({
        claimWallekStakeLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: CLAIM_WALLEK + id,
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setWallekSlice({
            claimWallekStakeLoading: false,
          })
        );
      });
  };

  return {
    getWallekStakeDetails,
    getWallekClaimHistory,
    claimWallekStakeAmount,
  };
};
