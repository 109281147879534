import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { ReactComponent as ArrowRight } from '../../../../../SVGIcons/arrow-right.svg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import GlobalModal from '../../../../../components/Global/GlobalModal';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import useModalHook from '../../../../../hooks/useModalHook';
import { EmptyLicenseList } from '../../../../../images/wallets';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { LicenseType } from '../../../../../store/cloudk/cloudkTypes';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import AddMintingToken from './AddMintingToken/AddMintingToken';
import LicenseCard from './LicenseCard';
import UpgradeStakePeriods from './UpgradeStakePeriods';
import { useTranslation } from 'react-i18next';

const AllLicenses = () => {
  const navigate = useNavigate();
  const { open, handleClose, handleOpen } = useModalHook();
  const {
    open: stakePeriodOpen,
    handleClose: handleCloseStakePeriod,
    handleOpen: handleOpenStakePeriod,
  } = useModalHook();
  const { allLicienceLoading, allLicencesData } = useSelector(selectCloudSlice);
  const {
    getActiveLicensesCountFunc,
    getAllLicenses,
    getSetCloudkSelectedLicense,
  } = useCloudkActions();
  const sliderRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handlePrev = () => {
    if (sliderRef.current && currentIndex > 0) {
      sliderRef.current.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (sliderRef.current && !isAtEnd) {
      sliderRef.current.slideNext();
      setCurrentIndex(currentIndex + 1);
    }
  };
  useEffect(() => {
    if (!allLicencesData?.list) {
      getAllLicenses();
    } // if it's null, we need to call te inital api
  }, []);

  const handleMintingToken = (item: LicenseType) => {
    getSetCloudkSelectedLicense(item);
  };

  const licensesList = allLicencesData?.list ?? [];
  const { t } = useTranslation();

  return (
    <CardLayout className="col-span-12 p-5 flex flex-col justify-between gap-2 !shadow-boxShadow bg-[#FFFFFF]">
      <section className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-secondaryBlack sm:text-lg">
            {t('defi.myCloudk.license')}
          </h1>
          {licensesList.length > 0 && (
            <div
              className=" top-5 justify-end right-5 bottom-[-6px] md:bottom-5 md:right-[10px] gap-1 flex cursor-pointer"
              onClick={() => {
                navigate('/defi-360/cloudk/Licenses');
              }}
            >
              <span className="text-sm font-normal">{t('general.vAll')}</span>
              <div className="w-5 h-5">
                <ArrowRight />
              </div>
            </div>
          )}
        </div>
      </section>

      <div className="relative">
        <div className="flex gap-2 overflow-hidden">
          {allLicienceLoading && licensesList.length === 0 ? (
            <div className="flex items-center justify-center flex-1 w-full h-64">
              <div className="items-center self-center justify-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="h-6 animate-spin" />
                </div>
              </div>
            </div>
          ) : licensesList.length > 0 ? (
            <SimpleSlider
              onSwiper={(swiper) => {
                sliderRef.current = swiper;
              }}
              onSlideChange={(swiper) => {
                setCurrentIndex(swiper.activeIndex);
                setIsAtEnd(swiper.isEnd); // Update end status here
              }}
              className="w-full cursor-grab"
              spaceBetween={8}
              slidesPerView={'auto'}
              modules={[Navigation]}
              navigation={false} // Disable default Swiper navigation
              breakpoints={{
                768: {
                  slidesPerView: 'auto',
                },
              }}
            >
              {allLicencesData?.list?.slice(0, 10)?.map((item, index) => (
                <SwiperSlide key={index} className="!w-56 !h-92 bg-[#FFFFFF]">
                  <LicenseCard
                    key={item?._id}
                    item={item}
                    handleMintingToken={handleMintingToken}
                    handleOpen={handleOpen}
                    handleOpenStakePeriod={handleOpenStakePeriod}
                  />
                </SwiperSlide>
              ))}
            </SimpleSlider>
          ) : (
            <div className="flex items-center justify-center flex-1 w-full xxs:px-2 sm:px-0 ">
              <div className="flex flex-col items-center gap-2">
                <div className="h-auto w-44 lg:w-64">
                  <img src={EmptyLicenseList} alt="empty transactions" />
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
                    {t('defi.myCloudk.tNoProducts', {
                      property: t('defi.myCloudk.li'),
                    })}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {licensesList.length > 0 && (
          <>
            {/* Fade effect */}
            <div className="absolute z-10 top-0 right-0 h-full w-[10%] pointer-events-none bg-gradient-to-l from-white md:block hidden"></div>
            <div className="flex gap-4 mt-2 md:hidden">
              <button
                onClick={handlePrev}
                disabled={currentIndex === 0}
                className={` rounded-full ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowBack size={20} />
              </button>
              <button
                onClick={handleNext}
                disabled={isAtEnd}
                className={` rounded-full ${isAtEnd ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowForward size={20} />
              </button>
            </div>
          </>
        )}
      </div>

      <GlobalModal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll"
        title={t('defi.myCloudk.licenses.modalAddUtility.title')}
        noHeader={false}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
      >
        <AddMintingToken
          closeFunc={(message: string) => {
            handleClose();
            message === 'refreshLicenseCount' && getActiveLicensesCountFunc();
          }}
          allLicensesOrLinkLicense={true}
        />
      </GlobalModal>

      <GlobalModal
        isOpen={stakePeriodOpen}
        onClose={handleCloseStakePeriod}
        size="3xl"
        childrenClass="!px-0 !py-7 small-scroll"
        title={t('defi.myCloudk.licenses.modalAddUtility.modal.title')}
        noHeader={false}
        headerClassName="!p-6 font-medium text-lg text-[#0E173F] border-b border-b-[#D5DBE0]"
      >
        <UpgradeStakePeriods
          closeFunc={handleCloseStakePeriod}
          allLicensesOrLinkLicense={false}
        />
      </GlobalModal>
    </CardLayout>
  );
};

export default AllLicenses;
