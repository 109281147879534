import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';

import GlobalModal from './Global/GlobalModal';
import StepLine from './StepLine';
import StepNumber from './StepNumber';
import { danger } from '../images/others';
import StatusChip from './StatusChip';

import { formatNumberWithCommas } from '../utils/utils';
import { setDecimalPlaces, tolocalTime } from '../utils/helper';
import { useTranslation } from 'react-i18next';

type GlobalStepperProps = {
  step: number;
  enableBackground?: boolean;
  lineEnable?: boolean;
};

const GlobalStepper = ({
  step,
  enableBackground = false,
  lineEnable = true,
}: GlobalStepperProps) => (
  <div className="h-full transition-all">
    <StepNumber number={step} enableBackground={enableBackground} active />
    {lineEnable && <StepLine enableBackground={enableBackground} />}
  </div>
);

interface IHistoryHeadings {
  dataClass: string;
  id: string;
  label: string;
}

interface HistoryPopupProps {
  onClose: () => void;
  open?: boolean;
  headings: IHistoryHeadings[];
  data: Record<string, any>;
  fromComponent?: string;
  type?: string;
}

export default function HistoryPopup({
  onClose,
  open = false,
  headings,
  data,
  fromComponent,
  type,
}: HistoryPopupProps) {
  const { t } = useTranslation();
  const Type = type === 'deposit-and-stake-request' ? 'Deposit' : type;
  const getToken = (id: string) =>
    id === 'previousBalance' ? 'fromToken' : 'toToken';
  return (
    <GlobalModal size="xl" onClose={onClose} isOpen={open} title=" ">
      <div className="flex flex-col mt-2">
        <div className="flex flex-col w-full h-full md:flex-row">
          {fromComponent?.toLowerCase() !== 'deposit' &&
          fromComponent?.toLowerCase() !== 'swap' ? (
            <>
              <div className="flex-1 my-2">
                <div className="flex w-full py-2">
                  <div className="w-14">
                    <GlobalStepper
                      step={1}
                      enableBackground={true}
                      lineEnable={true}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full gap-2">
                    <div className="text-sm font-semibold text-defaultBlack">
                      {fromComponent?.toLowerCase() ===
                        'deposit-and-stake-request'
                        ? t('dynamic.wallet.history.requestCreated')
                        : `${type} ${t("dynamic.wallet.history.orderSubmitted")}`}
                    </div>
                    <div className="pt-2 pb-4 text-sm">
                      {data['time'] ? tolocalTime(data['time']) : '-'}
                    </div>
                  </div>
                </div>

                {fromComponent?.toLowerCase() === 'deposit-and-stake-request' ||
                fromComponent?.toLowerCase() === 'deposit-and-stake' ? (
                  <div className="flex w-full py-4">
                    <div className="w-14">
                      <GlobalStepper
                        step={2}
                        enableBackground={
                          data.status === 'success' ||
                          data.status === 'completed'
                            ? true
                            : false
                        }
                        lineEnable={false}
                      />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="text-sm font-semibold text-defaultBlack">
                        <StatusChip status={data.status} />
                      </div>
                      <span className="pt-2 pb-4 text-sm">
                        {data.status === 'expired' ||
                        data.status === 'cancelled'
                          ? tolocalTime(data['expiredtime'])
                          : tolocalTime(data['updatetime'])}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full py-4">
                    <div className="w-14">
                      <GlobalStepper
                        step={2}
                        enableBackground={true}
                        lineEnable={true}
                      />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="text-sm font-semibold text-defaultBlack">
                        {t("dynamic.wallet.history.systemProcessing")}
                      </div>
                      <div className="pt-2 pb-4 text-sm">
                        {data['time'] ? tolocalTime(data['time']) : '-'}
                      </div>
                    </div>
                  </div>
                )}

                {(fromComponent?.toLowerCase() ===
                  'deposit-and-stake-request' ||
                  fromComponent?.toLowerCase() === 'deposit-and-stake') &&
                (data.status !== 'success' || data.status !== 'completed') ? (
                  <></>
                ) : (
                  <div className="flex w-full py-2">
                    <div className="w-14">
                      <GlobalStepper
                        step={3}
                        enableBackground={true}
                        lineEnable={false}
                      />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="text-sm font-semibold text-defaultBlack first-letter:capitalize">
                        <StatusChip status={data?.status} isBlackColor={true} />
                      </div>
                      <div className="text-sm">
                        {data['updatetime']
                          ? tolocalTime(data['updatetime'])
                          : data['time']
                            ? tolocalTime(data['time'])
                            : '-'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            ''
          )}

          <div className="w-full my-2 md:flex-1">
            <div className="w-full">
              {headings
                ?.filter((cell) => cell.id !== 'requestNumber')
                .map((item) => (
                  <div
                    key={item.id}
                    className={`flex flex-row w-full gap-2 py-2 md:flex-col ${data.withdrawToken === 'Debit' && item.id === 'total' ? 'hidden' : 'block'}`}
                  >
                    <div className="text-sm font-semibold text-defaultBlack">
                      {item.label}
                      {type === 'Swap' &&
                        ['previousBalance', 'newBalanceOfToToken'].includes(
                          item.id
                        ) &&
                        data[getToken(item.id)] && (
                          <span className="text-xs">{` (${data[getToken(item.id)]})`}</span>
                        )}
                    </div>
                    <div className="text-sm whitespace-normal overflow-wrap-anywhere">
                      {type === 'Swap' && item.id === 'amount' ? (
                        `${data['toTokenValueType'] === 'usd' && data['toToken'] !== 'TomoCat' && data['toToken'] !== 'T-Balance' ? '$' : ''}${formatNumberWithCommas(setDecimalPlaces(Number(data[item.id]), 6))} 
                        ${
                          data['toTokenValueType'] === 'lyk'
                            ? data['toToken']
                            : data['toToken'] === 'TomoCat'
                              ? 'Tomo'
                              : ''
                        }`
                      ) : [
                          'previousBalance',
                          'newBalance',
                          'total',
                          'amount',
                        ].includes(item.id) ? (
                        `${(data['valueType'] === 'usd' || data['fromTokenValueType'] === 'usd') && data['withdrawToken'] !== 'TomoCat' && data['coin'] !== 'TomoCat' && data['fromToken'] !== 'TomoCat' && data['withdrawToken'] !== 'T-Balance' ? '$' : data['withdrawToken'] === 'T-Balance' ? 'TG ' : ''}${formatNumberWithCommas(setDecimalPlaces(Number(data[item.id]), 6))} 
                        ${
                          data['valueType'] === 'lyk' ||
                          data['fromTokenValueType'] === 'lyk'
                            ? data['withdrawToken'] ||
                              data['wallet'] ||
                              data['fromToken']
                            : data['withdrawToken'] === 'TomoCat' ||
                                data['coin'] === 'TomoCat' ||
                                data['fromToken'] === 'TomoCat'
                              ? 'Tomo'
                              : ''
                        }`
                      ) : item.id === 'newBalanceOfToToken' ? (
                        `${data['toTokenValueType'] === 'usd' && data['toToken'] !== 'TomoCat' && data['toToken'] !== 'T-Balance' ? '$' : data['toToken'] === 'T-Balance' ? 'TG ' : ''}${formatNumberWithCommas(setDecimalPlaces(Number(data[item.id]), 6))} 
                        ${
                          data['toTokenValueType'] === 'lyk'
                            ? data['toToken']
                            : data['toToken'] === 'TomoCat'
                              ? 'Tomo'
                              : ''
                        }`
                      ) : item.label.toLowerCase() === 'status' ? (
                        <StatusChip status={data[item.id]} />
                      ) : item.id === 'time' ? (
                        tolocalTime(data[item.id])
                      ) : item.id === 'hash' && Array.isArray(data[item.id]) ? (
                        <ul>
                          {data[item.id].map((hash: any, index: number) => (
                            <li key={index}>• {hash}</li>
                          ))}
                        </ul>
                      ) : item.id === 'hash' && data[item.id] === 'internal' ? (
                        `${data[item.id]}-${data?.fromUser?.blockchainId ?? ''}`
                      ) : item.id === 'userRemarks' || item.id === 'remarks' ? (
                        <span>
                          {data['userRemarks'] ?? data['remarks']}
                          <span className="block text-xs italic text-gray-400">
                            {data['optionalRemarks']}
                          </span>
                        </span>
                      ) : (
                        data[item.id] ?? '-'
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <Alert
            severity="info"
            icon={<img src={danger} alt="danger" />}
            className="!w-full"
            sx={{
              '& .MuiAlert-message': {
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <div className="py-2 text-primary">
              <div className="text-xs">
                {t("dynamic.wallet.history.pleaseNoteYouRecvEmailOnComplete")}
              </div>
              <div className="text-xs font-medium">
                {t("dynamic.wallet.history.whyIsNotMyTypeArrived", { type: Type })}
                <Link to="/support"> {t("dynamic.wallet.history.contactSupport")}.</Link>
              </div>
            </div>
          </Alert>
        </div>
      </div>
    </GlobalModal>
  );
}
