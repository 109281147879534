import { useTranslation } from 'react-i18next';

export enum RequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  COMPLETED = 'completed',
  ON_CHAIN_FAILURE_AND_REIMBERSED = 'on-chain-failure-and-reimbursed',
  REJECTED_AND_REIMBERSED = 'rejected-and-reimbursed',
  PENDING_FOR_ADMIN = 'pending-for-admin',
  SUCCESS = 'success',
  FAILED = 'failed',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  PARTIAL_SUCCESS = 'partial-success',
}

export default function StatusChip({
  status,
  isBlackColor = false,
}: {
  status: string;
  isBlackColor?: boolean;
}) {
  const { t } = useTranslation();
  // t = general.status

  let color = '';
  let text = '';

  if (status === RequestStatus.PENDING) {
    color = '#DC9700';
    text = t('general.status.pending');
  } else if (status === RequestStatus.APPROVED) {
    color = '#1B9FDA';
    text = t('general.status.approved');
  } else if (status === RequestStatus.COMPLETED) {
    color = '#5BAC0A';
    text = t('general.status.completed');
  } else if (status === RequestStatus.ON_CHAIN_FAILURE_AND_REIMBERSED) {
    color = '#FF2727';
    text = t('general.status.rejected');
  } else if (status === RequestStatus.REJECTED_AND_REIMBERSED) {
    color = '#FF2727';
    text = t('general.status.rejected');
  } else if (status === RequestStatus.PENDING_FOR_ADMIN) {
    color = '#0f17ff';
    text = 'pending-for-approval';
  } else if (status === RequestStatus.PARTIAL_SUCCESS) {
    color = '#DC9700';
    text = t('general.status.partial-success');
  } else if (status === RequestStatus.SUCCESS) {
    color = '#5BAC0A';
    text = t('general.status.success');
  } else if (status === RequestStatus.CANCELLED) {
    color = '#FF2727';
    text = t('general.status.cancelled');
  } else if (status === RequestStatus.EXPIRED) {
    color = '#FF2727';
    text = t('general.status.expired');
  } else if (status === RequestStatus.FAILED) {
    color = '#FF2727';
    text = t('general.status.failed');
  } else {
    color = '#1B9FDA';
    text = '-';
  }

  return (
    <span
      style={{
        color: isBlackColor ? '#000000' : color,
      }}
      className="font-medium capitalize"
    >
      {text}
    </span>
  );
}
