import { useDispatch } from 'react-redux';

import { setDashboardSlice } from './dashboardSlice.tsx';
import { USER_COMPLETION_STEPS } from '../../utils/network/ApiEndpoints.ts';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi.ts';

export const useDashbaordActions = () => {
  const dispatch = useDispatch();

  const getUserCompletionSteps = async () => {
    dispatch(
      setDashboardSlice({
        isUserCompletionStepsLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${USER_COMPLETION_STEPS}`,
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setDashboardSlice({
            userCompletionSteps: data,
          })
        );
        return response;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        dispatch(
          setDashboardSlice({
            isUserCompletionStepsLoading: false,
          })
        );
      });
  };

  return {
    getUserCompletionSteps,
  };
};
