import React from 'react';

const EditIcon = ({ color = "#1B9FDA" }: any) => {
  return (


<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.375 4.875H3.75C3.41848 4.875 3.10054 5.0067 2.86612 5.24112C2.6317 5.47554 2.5 5.79348 2.5 6.125V11.75C2.5 12.0815 2.6317 12.3995 2.86612 12.6339C3.10054 12.8683 3.41848 13 3.75 13H9.375C9.70652 13 10.0245 12.8683 10.2589 12.6339C10.4933 12.3995 10.625 12.0815 10.625 11.75V11.125" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10 3.62507L11.875 5.50007M12.7406 4.61569C12.9868 4.36954 13.1251 4.03568 13.1251 3.68757C13.1251 3.33945 12.9868 3.0056 12.7406 2.75944C12.4945 2.51329 12.1606 2.375 11.8125 2.375C11.4644 2.375 11.1305 2.51329 10.8844 2.75944L5.625 8.00007V9.87507H7.5L12.7406 4.61569Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default EditIcon;
