import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import React, { memo } from 'react';
import { ArrowDownSvg } from '../../images/svgs';
import { useTranslation } from 'react-i18next';

type Props = {
  containerClassName?: string;
  buttonClassName?: string;
  buttonText?: string;
  dropdownClassName?: string;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
  onItemClick?: (item: string) => void;
  id?: string;
  data?: { key: string; label: string }[];
  dropDownWithValues?: boolean;
  callBack?: any;
  value?: string;
};

const GlobalDropdown = ({
  containerClassName,
  buttonClassName,
  dropdownClassName,
  StartIcon,
  buttonText = 'Actions',
  EndIcon = <ArrowDownSvg className="w-4 h-4" />,
  onItemClick,
  id,
  data,
  dropDownWithValues = false,
  callBack,
  value = '',
}: Props) => {
  const { t } = useTranslation();
  const items = data || [
    {
      key: 'new',
      label: 'New file',
    },
    {
      key: 'copy',
      label: 'Copy link',
    },
    {
      key: 'edit',
      label: 'Edit file',
    },
    {
      key: 'delete',
      label: 'Delete file',
    },
  ];

  const name =
    (data || []).find((item) => item.key === value)?.label ||
    t('general.Select');

  return (
    <Dropdown>
      <DropdownTrigger onClick={() => document.body.click()}>
        <Button
          variant="bordered"
          className={`py-3 px-4 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none flex items-center justify-between gap-2 aria-expanded:scale-1 ${buttonClassName}`}
        >
          {StartIcon && StartIcon} {buttonText && buttonText}{' '}
          <span className="text-cardBorder flex items-center gap-x-2">
            {dropDownWithValues ? name : ''}
            {EndIcon && EndIcon}
          </span>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Dynamic Actions"
        items={items}
        classNames={{
          base: `bg-white text-xs border border-cardBorder rounded-lg backdrop-blur-md ${dropdownClassName} small-scroll`,
        }}
        style={{
          width: '120px',
          minWidth: '120px',
          maxHeight: '200px',
          overflowY: 'auto',
          top: '100%', // Ensures dropdown positions below the trigger
          transform: 'translateY(0)', // Prevents flipping
        }} // Set the desired width here
        onAction={(key: any) => {
          callBack(key?.toString());
        }}
      >
        {(item: any) => (
          <DropdownItem
            key={item.key}
            color={item.key === 'delete' ? 'danger' : 'default'}
            className={item.key === 'delete' ? 'text-danger' : 'w-full'}
            classNames={{
              base: `w-full ${dropdownClassName}`,
            }}
          >
            {item.label}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default memo(GlobalDropdown);
