import CardLayout from '../../../../../components/Cards/CardLayout';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { useSelector } from 'react-redux';
import ImageWithFallback from '../../Common/ImageWithFallback/ImageWithFallback';
import { EmptyTopMachineList } from '../../../../../images/wallets';
import { useTranslation } from 'react-i18next';
import { usePlatformAction } from '../../../../../store/platform/platformAction';
import { ENV_VARS } from '../../../../../utils/constants';

const TopMachines = () => {
  // const { upgradeAbleMachinesLoading, upgradeAblesMachinesData } = useSelector(selectCloudSlice)
  const { allHardwareLoading, allHArdwaresData } =
    useSelector(selectCloudSlice);
  const { platformLogin } = usePlatformAction();
  const { t } = useTranslation();

  const data = allHArdwaresData || [];

  const loginAndRedirectToHorysMall = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const res = await platformLogin(ENV_VARS.HORYSMALL_CONSTANT.externalId);
    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }
  };
  return (
    <div
      // style={{
      //   background:
      //     'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)',
      // }}
      className="rounded-xl h-[185px] p-[2px] md:col-span-6 xl:col-span-3 xl:h-full col-span-12"
    >
      <CardLayout
        className={`h-full  flex flex-col justify-between gap-2 bg-[#FFFFFF] ${allHArdwaresData?.length === 0 && '!shadow-boxShadow'}`}
      >
        {allHardwareLoading && allHArdwaresData?.length === 0 ? (
          <div className="flex items-center justify-center flex-1 w-full h-full">
            <div className="items-center self-center justify-center">
              <div className="flex flex-col gap-5">
                <Spinner className="h-6 animate-spin" />
              </div>
            </div>
          </div>
        ) : allHArdwaresData?.length > 0 ? (
          <section className="relative flex flex-col h-full gap-4 cloudkSlider">
            <SimpleSlider
              //   className={`w-full mt-2  min-h-[320px] !mx-auto ${loading ? '' : ' xxs:!py-7'} `}
              className="w-full h-full topMachineSlider"
              loop={true}
              navigation={true}
              //   pagination={{
              //       el: '.wallet-swiper-pagination-custom',
              //       clickable: true,
              //       type: 'bullets',
              //   }}
              modules={[Navigation]}
              //   onSwiper={handleSlideChange}
              //   onSlideChange={handleSlideChange}
              //   initialSlide={activeSlideIndex}
              spaceBetween={20}
              slidesPerView={1}
            >
              {data?.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <>
                    {item.softwareUpgradeStatus === 'pending' && (
                      <div
                        style={{
                          background:
                            'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)',
                        }}
                        className="absolute z-[-200] top-0 left-0 right-0 bottom-0 rounded-lg"
                      ></div>
                    )}
                    <div className="h-[calc(100%-4px)] w-[calc(100%-4px)] px-5 py-3 absolute z-[-199] top-[2px] left-[2px] right-[2px] bottom-[2px] bg-white rounded-lg">
                      <div
                        style={{
                          background: `linear-gradient(93.11deg, rgba(250, 225, 64, 0.3) 1.51%, rgba(242, 196, 98, 0.3) 27.11%, rgba(235, 154, 14, 0.3) 52.32%, rgba(242, 196, 98, 0.3) 73.67%, rgba(255, 230, 104, 0.3) 101.91%)`,
                        }}
                        className="w-44 h-44 -top-2 right-5 rounded-full blur-[35px] z-[-10]  absolute "
                      ></div>

                      <div className="relative flex items-start justify-end h-full px-4 pr-4 width screen370:items-center xl:items-start screen1780:items-center ">
                        <div className="relative w-36 h-28">
                          <ImageWithFallback
                            src={item.minterData?.documents[0]?.link}
                            fallbackSrc={''}
                            alt={item.name}
                            classNames="h-full w-full z-[999] relative md:scale-125"
                          />
                        </div>
                      </div>

                      <div
                        className={`absolute flex flex-col gap-2 screen370:gap-3 bottom-2 xl:gap-2 screen1780:gap-3 left-10 ${
                          item.softwareUpgradeStatus === 'pending' &&
                          '!-bottom-6'
                        }`}
                      >
                        <span className="text-sm font-semibold text-[#121212]">
                          {item?.minterData?.name}
                        </span>
                        {item.softwareUpgradeStatus === 'pending' && (
                          <div
                            style={{
                              boxShadow:
                                '0px 4.1px 18.56px 0px rgba(235, 154, 14, 0.5)',
                              background:
                                'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)',
                            }}
                            className="z-20 flex items-center justify-center w-32 min-h-6 h-auto shadow-lg cursor-pointer rounded-xl "
                          >
                            <span
                              className="text-xs font-medium text-white "
                              onClick={loginAndRedirectToHorysMall}
                            >
                              {t('defi.myCloudk.upgradeSoftwareBannerButton')}
                            </span>
                          </div>
                        )}
                        <div className="z-20 flex items-center justify-center w-32 h-6 p-2 rounded-xl"></div>
                      </div>
                    </div>
                  </>
                </SwiperSlide>
              ))}
            </SimpleSlider>
          </section>
        ) : (
          <div className="flex items-center justify-center flex-1 w-full xxs:px-2 sm:px-0 ">
            <div className="flex flex-col items-center gap-2">
              <div className="w-32 h-auto lg:w-24">
                <img src={EmptyTopMachineList} alt="empty transactions" />
              </div>
              <div className="flex flex-col items-center gap-2">
                <span className="text-primary xxs:text-sm text-[16px] lg:text-[14px] font-medium">
                  {t('defi.myCloudk.noMintersFound')}
                </span>
              </div>
            </div>
          </div>
        )}
      </CardLayout>
    </div>
  );
};

export default TopMachines;
