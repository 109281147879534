import { useSelector } from 'react-redux';
import GlobalButton from '../../../../components/Global/GlobalButton';
import DepositStepper from './DepositStepper';
import { DEPOSIT_SLICE_NAME } from '../../../../store/wallets/depositSlice';
import { RootState } from '../../../../store';
import { useEffect, useState } from 'react';
import { useCloudActions } from '../../../../store/Cloudk2/cloudk2Actions';
import { selectCloudSlice } from '../../../../store/Cloudk2/clouldk2Slice';
import moment from 'moment';
import { useWalletDataAction } from '../../../../store/walletData/walletDataAction';
import { ReactComponent as CLoseIcon } from '../../../../SVGIcons/close.svg';
import { TMACHINE_DATA } from '../../../../data/cloudk2';
import { useNavigate } from 'react-router-dom';
import GlobalSelectMui from '../../../../components/Global/GlobalSelectMui';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import { Spinner } from '../../../../images/svgs';
import { useTranslation } from 'react-i18next';
interface Props {
  onRequestSubmited: () => void;
  onReset: () => void;
}
const DepositStep3Stake = ({ onRequestSubmited, onReset }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedMachineId, setSelectedMachine] = useState('');
  const { selectedCoin, selectedNetwork, currentStep }: any = useSelector(
    (state: RootState) => state[DEPOSIT_SLICE_NAME]
  );
  const { machineList, userMachinesLoading } = useSelector(selectCloudSlice);
  const { postDepositAndStakeRequest } = useWalletDataAction();
  const { getUserMachinesList } = useCloudActions();

  const navigate = useNavigate();

  useEffect(() => {
    if (!machineList) {
      getUserMachinesList(true);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const payload = {
        token: selectedCoin._id,
        network: selectedNetwork._id,
        machine: selectedMachineId,
      };
      const response = await postDepositAndStakeRequest(payload);
      onRequestSubmited();
    } catch (error) {
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    setSelectedMachine('');
    onReset();
  };
  const options =
    machineList
      ?.filter(
        (machine) =>
          Number(machine?.stake?.value) < Number(machine.stakeLimit) ||
          machine.stakeUnlimited === true
      )
      ?.map((machine) => ({
        value: machine?._id,
        name: machine?.name,
        widget: <MachineCard machine={machine} />,
      })) ?? [];

  const machine = machineList?.find((m) => m._id === selectedMachineId);

  const NavigateToPurchaseButton = userMachinesLoading ? (
    <div className="flex items-center justify-center w-full h-16">
      <Spinner className="h-4 animate-spin text-primary" />
    </div>
  ) : (
    <div className="text-sm text-primaryGrey">
      You've not purchased any product yet.
      <span
        className="pl-2 cursor-pointer text-primary"
        onClick={() => navigate('/defi-360/nodek/purchase')}
      >
        Purchase Now
      </span>
    </div>
  );

  if (options.length === 0) {
    options.push({ value: '', name: '', widget: NavigateToPurchaseButton });
  }

  return (
    <>
      <div className="flex">
        <div className="w-10 lg:w-14">
          <DepositStepper
            step={3}
            enableBackground={currentStep === 3 && selectedMachineId}
            lineEnable={false}
          />
        </div>
        <div className="flex-1">
          <div className="mb-2 text-sm font-medium text-defaultBlack">
            {t("general.selectProduct")} <span className="text-redDanger">*</span>
          </div>
          {selectedCoin && selectedNetwork && (
            <>
              <GlobalSelectMui
                loading={loading}
                optionsWidth="w-full"
                options={options}
                required
                setSelectedOption={(option: any) =>
                  setSelectedMachine(option?.value)
                }
                selectedOption={machine}
                firstAutoSelected={false}
                placeHolder={t("general.selectAProduct")}
              />
              {!loading && machineList?.length === 0 && (
                <div className="mt-2">{NavigateToPurchaseButton}</div>
              )}

              {selectedMachineId && (
                <MachineCard
                  machine={machine!}
                  className="p-1 border rounded-lg border-lightGray"
                  onClose={() => setSelectedMachine('')}
                />
              )}

              <div className="flex justify-end gap-2 mt-8">
                <GlobalButton
                  text={t('general.cancel')}
                  disabled={submitLoading}
                  onClick={handleCancel}
                  outlined
                />
                <GlobalButton
                  text={t("general.submit")}
                  disabled={!selectedMachineId}
                  onClick={handleSubmit}
                  loading={submitLoading}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

interface MachineCardProps {
  machine: TMACHINE_DATA;
  className?: string;
  onClose?: () => void;
}
const MachineCard = ({ machine, className, onClose }: MachineCardProps) => (
  <div className={`flex gap-2 ${className} relative`}>
    <img src={machine.imageUrl} alt="" className="h-8" />
    <div>
      <div className="mb-1 text-xs font-medium">{machine.name}</div>
      <div className="flex gap-x-4 text-[10px] text-secondaryGrey flex-wrap">
        <div className="flex gap-2">
          <span className="font-medium">Machine Id</span>
          {machine?.uniqueName}
        </div>
        <div className="flex gap-2">
          <span className="font-medium">Expiry</span>{' '}
          {moment(machine.expiry).format('DD-MM-YYYY')}
        </div>
        <div className="flex gap-2">
          <span className="font-medium">Stake Balance</span> $
          {machine.stake?.value}
        </div>
        <div className="flex gap-2">
          <span className="font-medium">Stake Limit</span>
          {machine?.stakeLimit ? `$${machine?.stakeLimit}` : 'unlimited'}
        </div>
      </div>
    </div>
    {onClose && (
      <CLoseIcon
        className="absolute h-4 cursor-pointer hover:text-primary top-2 right-2"
        onClick={onClose}
      />
    )}
  </div>
);

export default DepositStep3Stake;
