export const LeaderBoardDefs = [
    {
        id: 'gradient3',
        type: 'linearGradient',
        x1: 0,
        y1: 1,
        x2: 1,
        y2: 0,
        colors: [
            {
                offset: 0,
                color: 'rgba(25, 122, 253, 0.25)',
            },
            {
                offset: 100,
                color: 'rgba(25, 122, 253, 0)',
            },
        ],
    },
];
export const LeaderBoardFill = [
    { match: { id: 'LeaderBoardPoints' }, id: 'gradient3' },
];