import React, { useEffect } from 'react';
import Slider from 'react-slick';
import useDebounce from '../../hooks/useDebounceHook';
import { EmptyBox } from '../../images/svgs';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
import GlobalSearch from '../Global/GlobalSearch';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import ProductCard from './ProductCard';
import HoverBorderAnimation from '../HoverBorderAnimation';
import { useTranslation } from 'react-i18next';

interface Platform {
  id: string;
  comingSoon: boolean;
  category: string;
  description: string;
  logo: string;
  favorite: boolean;
  name: string;
  numberOfUsers: number;
  isDisabledForUser: boolean;
  // Add other properties if needed
}

interface PlatformProps {
  isPlatorms: boolean;
  platforms: Platform[];
  onClick: (id: string, isFav: boolean) => void;
  loginClick: (id: string) => void;
  loginLoading: boolean;
  loadingCardId: string | null;
  loading: boolean;
}

const FeatureProducts = ({
  isPlatorms,
  platforms,
  onClick,
  loginClick,
  loginLoading,
  loadingCardId,
  loading,
}: PlatformProps) => {
  const [serachPlatoforms, setSerachPlatoforms] = React.useState<string>('');
  const { debouncedValue: debouncedSearch } = useDebounce(
    serachPlatoforms,
    1000
  );

  const [filteredPlatforms, setFilteredPlatforms] =
    React.useState<Platform[]>(platforms);

  const [searchIsloading, setSearchIsLoading] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (debouncedSearch) {
      setSearchIsLoading(true);
      const timeoutId = setTimeout(() => {
        setFilteredPlatforms(
          platforms?.filter(
            (platform) =>
              platform.name
                .toLowerCase()
                .includes(debouncedSearch.toLowerCase()) &&
              !platform.isDisabledForUser
          )
        );
        setSearchIsLoading(false);
      }, 300);

      return () => clearTimeout(timeoutId);
    } else {
      setFilteredPlatforms(
        platforms?.filter((platform) => !platform.isDisabledForUser)
      );
      setSearchIsLoading(false);
    }
  }, [debouncedSearch, platforms]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: filteredPlatforms?.length < 5 ? 1 : 5,
    arrows: true,
    dots: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerMode: false,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: (i: any) => (
      <button>
        <span></span>
      </button>
    ),
  };

  return (
    <div
      className={`w-full bg-white flex flex-col gap-4 p-4 border shadow-sm rounded-lg relative ${isPlatorms && 'radius-5px '}`}
    >
      <div className="flex flex-col justify-between w-full gap-2 md:flex-row">
        <div className="text-lg font-semibold">{`${isPlatorms ? t('externnalApps.banner.sec1.favourite') : t('externnalApps.banner.sec1.featured')}  `}</div>
        {!isPlatorms && (
          <GlobalSearch
            placeholderText={t('externnalApps.searchPlatformsWithDots')}
            value={serachPlatoforms}
            handleOnSearch={(e) => {
              if (setSerachPlatoforms) {
                setSerachPlatoforms(e.target.value);
              }
            }}
            isLoading={searchIsloading}
            baseClassName="!w-60 text-xs mr-24"
          />
        )}
      </div>
      {loading && !filteredPlatforms ? (
        <div className="items-center self-center justify-center">
          <div className="flex flex-col gap-5">
            <Spinner className="h-6 animate-spin" />
          </div>
        </div>
      ) : filteredPlatforms?.length > 0 ? (
        <div className="w-full overflow-hidden">
          <Slider
            {...settings}
            ref={(slider: any) => {}}
            className="externalApps"
          >
            {filteredPlatforms?.map((platform, index) => (
              <div className="px-4" key={index}>
                <ProductCard
                  key={platform.id}
                  id={platform.id}
                  logo={platform.logo}
                  favorite={platform.favorite}
                  comingSoon={platform?.comingSoon}
                  loginLoading={loadingCardId === platform.id}
                  isPlatorms={isPlatorms}
                  onClick={(id, isFvt) => onClick(id, isFvt)}
                  name={platform.name}
                  loginClick={(id: string) => loginClick(id)}
                  category={t('general.new')}
                  version="Web 2.0"
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="items-center self-center justify-center">
          <div className="flex flex-col gap-5">
            <EmptyBox className="self-center" />
            {isPlatorms && t('externnalApps.banner.sec1.noAddedFavPlatforms')}
            {!isPlatorms && t('externnalApps.banner.sec1.noFeaturedPlatforms')}
            {/* {`You’ve not added any platform in the ${isPlatorms ? 'favourites' : 'featured '} yet.`} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureProducts;
