import { useCallback, useEffect, useState } from 'react';
import GlobalSearch from '../../../components/Global/GlobalSearch';
import { profileImg } from '../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerLoader } from './LoadingShimmer';
import ImageWithFallback from '../../Defi/Cloudk/Common/ImageWithFallback/ImageWithFallback';
import NoData from './NoData';
import PaginationButtons from '../../../components/Global/GlobalPaginatedButtons';
import { selectMyFirstLineUsers } from '../../../store/myFirstLine/myFirstLineSlice';
import { useMyFirstLineUsersAction } from '../../../store/myFirstLine/myFirstLineAction';
import MyFirstLineFilterTab from './MyFirstLineFilterTab';
import useUserCheck from '../../../hooks/useUserCheck';
type FilterType = 'week' | 'month' | 'year';
const MyFirstLineUsers = () => {
  const [selectedFilter, setSelectedFilter] = useState<FilterType>('week');
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { myFirstLineUsers, myFirstLineUsersLoading } =
    useSelector(selectMyFirstLineUsers) || {};
  const { getMyFirstLineUsers } = useMyFirstLineUsersAction();
  const { user } = useUserCheck();
  useEffect(() => {
    if (!myFirstLineUsersLoading) setSearchLoading(false);
  }, [myFirstLineUsersLoading]);
  useEffect(() => {
    setSearchValue('');
    setSelectedFilter('week');
    setCurrentPage(1);
    const data = {
      page: 1,
      limit: 10,
      bid: user?.id,
      filter: selectedFilter,
    };
    fetchMyFirstLineUsers(data);
  }, []);
  const fetchMyFirstLineUsers = (data: any) => {
    getMyFirstLineUsers(data);
  };
  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    delaySaveToDb(e.target.value, setSelectedFilter);
  };
  const delaySaveToDb = useCallback(
    debounce((val, rankFilter) => {
      setSearchLoading(true);
      const data = {
        page: 1,
        limit: 10,
        bid: user?.id,
        query: val ? val : '',
        filter: selectedFilter,
      };
      fetchMyFirstLineUsers(data);
    }, 1000),
    []
  );
  function debounce<T extends (...args: any[]) => void>(fn: T, delay: number) {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return function (this: unknown, ...args: Parameters<T>) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const data = {
      page: page,
      limit: 10,
      bid: user?.id,
      query: searchValue ? searchValue : '',
      filter: selectedFilter,
    };
    fetchMyFirstLineUsers(data);
  };
  const resetFilter = () => {
    setSearchValue('');
    setCurrentPage(1);
    fetchMyFirstLineUsers({
      bid: user?.id,
      page: 1,
      limit: 10,
      filter: selectedFilter,
    });
  };
  return (
    <div className="flex flex-col gap-4 bg-white w-full border border-borderBlue rounded-lg p-4">
      <div className=" flex flex-col-reverse md:flex-row justify-between gap-3">
        <div className="flex items-center justify-center w-full gap-3 md:w-[60%] lg:w-[40%] xl:w-[30%]">
          <GlobalSearch
            placeholderText={'Search'}
            value={searchValue ?? ''}
            handleOnSearch={handleSearch}
            isLoading={searchLoading}
            baseClassName="!w-full"
            disabled={searchLoading}
          />
          <button
            className={`${!searchValue || myFirstLineUsersLoading ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium`}
            type="button"
            onClick={() => {
              resetFilter();
            }}
            disabled={!searchValue || myFirstLineUsersLoading}
          >
            Reset
          </button>
        </div>
        {/* <MyFirstLineFilterTab
          layoutID={'my-first-line'}
          disabled={myFirstLineUsersLoading}
          onFilterChange={(event: string): void => {
            setSelectedFilter(event as FilterType);
            setSearchValue('');
            setCurrentPage(1);
            const data = {
              page: 1,
              limit: 10,
              bid: user?.id,
              filter: event,
            };
            if (!myFirstLineUsersLoading) {
              getMyFirstLineUsers(data);
            }
          }}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          hoverTextColor={true}
          filterType={[
            { label: 'Week', value: 'week' },
            { label: 'Month', value: 'month' },
            { label: 'Year', value: 'year' },
          ]}
          loader={myFirstLineUsersLoading}
        /> */}
      </div>
      <div
        className={`w-full flex flex-col gap-4 custom-scroll small-scroll overflow-x-auto`}
      >
        {myFirstLineUsersLoading ? (
          Array.from({ length: 5 }).map((_, i) => <ShimmerLoader />)
        ) : myFirstLineUsers?.users && myFirstLineUsers?.users?.length > 0 ? (
          myFirstLineUsers?.users?.map((user: any, index: number) => (
            <div className="w-full md:min-w-[600px] md:overflow-x-auto">
              <MyFirstLineUser
                image={user?.profilePicture || profileImg}
                name={user?.username}
                bid={user?.id}
                email={user?.email}
                country={user?.document_country ?? '-'}
                reward={user?.tokenAmount}
                tokenName={user?.tokenName}
              />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {!!myFirstLineUsers?.totalPages && myFirstLineUsers.totalPages > 1 && (
        <>
          <hr />
          <div className="self-end mt-2">
            <PaginationButtons
              totalPage={myFirstLineUsers.totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

const MyFirstLineUser = ({
  image,
  name,
  bid,
  email,
  country,
  reward,
  tokenName,
}: {
  image: string;
  name: string;
  bid: string;
  email: string;
  country: string;
  reward: number;
  tokenName: string;
}) => {
  return (
    <>
      <div className="hidden md:flex w-full justify-between items-center border border-primary rounded-xl px-4 py-2 shadow-myFirstLineCardShadow text-xs">
        <div className="flex gap-2 items-center w-[20%]">
          <ImageWithFallback
            src={image}
            alt="Image"
            classNames="object-cover w-[40px] h-[40px] border border-primary rounded-full"
            fallbackSrc={profileImg}
          />
          <div className="text-defaultBlack font-semibold">{name}</div>
        </div>
        <div className="flex flex-col w-[20%] items-center">
          <div className="text-primaryGrey font-normal">ID</div>
          <div className="text-defaultExtraBlack font-normal">{bid}</div>
        </div>
        <div className="flex flex-col w-[20%] items-center">
          <div className="text-primaryGrey font-normal">Email</div>
          <div className="text-defaultExtraBlack font-normal text-center">
            {email}
          </div>
        </div>
        <div className="flex flex-col w-[20%] items-center">
          <div className="text-primaryGrey font-normal">Country</div>
          <div className="text-defaultExtraBlack font-normal">{country}</div>
        </div>
        {/* <div className='flex flex-col w-[20%] items-end'>
                    <div className='text-primaryGrey font-normal'>Rewards</div>
                    <div className='text-black font-medium text-2xl'>{reward?.toFixed(3)}<span className="text-xs ml-1">{tokenName}</span></div>
                </div> */}
      </div>
      <div className="screen480:flex screen480:flex-row flex-shrink-0 items-center gap-5 md:hidden w-full rounded-xl border-2 border-primary px-6 py-3">
        <ImageWithFallback
          src={image}
          alt="Image"
          classNames="object-cover w-[70px] h-[70px] border-2 border-primary rounded-full"
          fallbackSrc={profileImg}
        />
        <div className="flex flex-col gap-1 mt-2 screen480:mt-0">
          <span className="text-defaultBlack font-medium text-base">
            {name}
          </span>
          <span className="text-primaryGrey font-normal text-sm">
            ID: <span className="text-black">{bid}</span>
          </span>
          <span className="text-primaryGrey font-normal text-sm break-words">
            Email: <span className="text-black break-words">{email}</span>
          </span>
          <span className="text-primaryGrey font-normal text-sm">
            Country: <span className="text-black">{country}</span>
          </span>
          {/* <span className='text-primaryGrey font-normal text-sm'>Rewards: <span className='text-primary font-medium text-base'>{reward?.toFixed(3)}<span className="text-xs ml-1">{tokenName}</span></span></span> */}
        </div>
      </div>
    </>
  );
};

export default MyFirstLineUsers;
