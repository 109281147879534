export const ShimmerLoader = () => {
  return (
    // <div className="skew-menu flex gap-2 items-center p-2">
    <div className="flex gap-2 items-center p-2">
      <ul className="w-[99%] overflow-auto">
        <li className="flex bg-gray-200 overflow-hidden h-16 items-center w-full justify-between rounded-lg border border-gray-300 animate-pulse">
          <div className="flex h-full gap-4">
            <div className="h-full px-4 flex items-center text-white font-medium bg-gray-300 animate-pulse">
              <div className="w-8 h-8 bg-gray-400 rounded"></div>
            </div>
            {/* <div className="skew-inner h-full flex gap-2 items-center"> */}
            <div className=" h-full flex gap-2 items-center">
              <div className="w-12 h-12 rounded-full overflow-hidden border border-gray-300 bg-gray-400 animate-pulse"></div>
              <div className="flex flex-col">
                <span className="w-20 h-4 bg-gray-300 animate-pulse rounded"></span>
                <span className="w-16 h-3 bg-gray-300 animate-pulse rounded mt-1"></span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export const TopThreeLoader = ({ rank }: { rank: number | null }) => {
  return (
    <div
      className={`flex flex-col rounded-2xl overflow-hidden w-52 relative bg-white shadow-top3Shadow ${rank === 1 ? 'scale-[1.1] z-10' : ''}`}
    >
      <div className="w-full h-16 bg-gray-200 animate-pulse"></div>

      <div className="absolute top-4 right-6 font-bold flex items-start text-xl italic text-[#696969] gap-0.5">
        <div className="w-8 h-4 bg-gray-200 animate-pulse"></div>
        <div className="w-4 h-4 bg-gray-200 animate-pulse ml-1"></div>
      </div>

      <div className="w-20 h-20 absolute left-4 top-10 border-4 border-white rounded-full overflow-hidden shadow-md bg-gray-200 animate-pulse">
        <div className="object-cover w-full h-full bg-gray-200 animate-pulse"></div>
      </div>

      <div className="flex flex-col gap-2 p-4 pt-16">
        <div className="flex flex-col">
          <div className="w-24 h-4 bg-gray-200 animate-pulse"></div>
          <div className="w-20 h-3 bg-gray-200 animate-pulse mt-1"></div>
        </div>
        <hr className="my-2 border-gray-300" />

        <div className="w-full flex flex-col items-center justify-center gap-1">
          <div className="w-20 h-4 bg-gray-200 animate-pulse"></div>
          <div className="w-32 h-6 bg-gray-200 animate-pulse mt-2"></div>
        </div>
      </div>
    </div>
  );
};

export const NivoLineChartLoader = () => {
  return (
    <div className="w-full h-[300px] bg-gray-200 animate-pulse">
      <svg className="w-full h-full">
        <path
          d="M 0 100 Q 50 120, 100 100 Q 150 80, 200 90 Q 250 110, 300 100"
          fill="none"
          stroke="#e0e0e0"
          strokeWidth="2"
          className="animate-pulse"
        />
        <circle
          cx="50"
          cy="90"
          r="4"
          fill="#e0e0e0"
          className="animate-pulse"
        />
        <circle
          cx="100"
          cy="100"
          r="4"
          fill="#e0e0e0"
          className="animate-pulse"
        />
        <circle
          cx="150"
          cy="80"
          r="4"
          fill="#e0e0e0"
          className="animate-pulse"
        />
        <circle
          cx="200"
          cy="90"
          r="4"
          fill="#e0e0e0"
          className="animate-pulse"
        />
        <circle
          cx="250"
          cy="110"
          r="4"
          fill="#e0e0e0"
          className="animate-pulse"
        />
        <circle
          cx="300"
          cy="100"
          r="4"
          fill="#e0e0e0"
          className="animate-pulse"
        />
      </svg>
    </div>
  );
};

export const LeaderCardLoader = () => {
  return (
    <div className="p-4 bg-white rounded-lg border border-borderBlue w-72 shadow-lg mx-2 animate-pulse">
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <div className="w-12 h-12 rounded-full overflow-hidden border border-primary bg-gray-200 animate-pulse"></div>
          <div className="flex flex-col">
            <span className="w-24 h-4 bg-gray-200 animate-pulse rounded"></span>
            <span className="w-16 h-3 bg-gray-200 animate-pulse rounded mt-1"></span>
            <span className="w-20 h-4 bg-gray-200 animate-pulse rounded mt-1"></span>
          </div>
        </div>
        <div className="w-12 h-8 bg-gray-200 animate-pulse rounded text-center"></div>
      </div>
    </div>
  );
};
