import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import GlobalTextInput from '../../../../components/Global/GlobalTextInput';
import GlobalButton from '../../../../components/Global/GlobalButton';
import GlobalSelectMui from '../../../../components/Global/GlobalSelectMui';
import ImageWithFallback from '../Common/ImageWithFallback/ImageWithFallback';
import { hardwareImg } from '../../../../images/cloudk';
import { useMapColumns } from '../shared/ActivityHistoryTable/utils';
import { useTranslation } from 'react-i18next';

const LinkedLicense = ({ minter, handleClose }: any) => {
  const mapColumns = useMapColumns();
  const [minterName, setMinterName] = useState(minter.minterData.name);
  const {
    allLicencesData,
    linkLicenseWithMinterLoading,
    transactionHistoryType,
    transactionHistoryFilters,
  } = useSelector(selectCloudSlice);
  const {
    getAllLicenses,
    LinkLicenseWithMinterFunc,
    getUserMinterLinkedLicenses,
    getCloudKTransactionHistoryV2,
  } = useCloudkActions();
  const tabs = Object.keys(mapColumns).map((key) => ({
    label: key.replace('_', ' '),
    key,
  }));
  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    getAllLicenses();
  }, []);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2 px-6 pb-6">
      <div className="grid items-center grid-cols-12 gap-4">
        <div className="flex flex-col order-2 w-full col-span-12 gap-2 sm:col-span-6 sm:order-1">
          <div className="text-sm font-medium text-defaultBlack">
            {t('defi.myCloudk.Hardwares.minterName')}
          </div>
          <div className="relative w-full">
            <GlobalTextInput
              name="name"
              placeholder={t('defi.myCloudk.Hardwares.placeOrder1')}
              type="text"
              className="!rounded-[50px] bg-gray-50"
              animationClasses="!rounded-[50px]"
              value={minterName || ''}
              style={{ cursor: 'default', appearance: 'none' }}
              errorStyle="!text-left"
              onChange={(e) => !minterName && setMinterName(e.target.value)}
              disabled={minterName ? true : false}
            />
          </div>
        </div>

        <div className="flex justify-center order-1 col-span-12 sm:col-span-6 sm:order-2 sm:justify-end">
          <div className="w-64 h-36">
            <ImageWithFallback
              src={minter.minterData?.documents?.[0]?.link ?? minter?.image}
              alt="hardwareImage"
              classNames="h-full w-full"
              fallbackSrc={''}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col items-start justify-between flex-1 gap-5">
          <div className="flex flex-col w-full gap-2">
            <div className="text-sm font-medium text-defaultBlack">
              {t('defi.myCloudk.Hardwares.select')}
              {/* <span className="text-redDanger">*</span> */}
            </div>
            {
              <>
                <GlobalSelectMui
                  required={true}
                  className={`!rounded-[50px]`}
                  showPropName={'label'}
                  options={
                    allLicencesData?.list
                      ? allLicencesData?.list
                          ?.filter((license: any) => {
                            return (
                              license.status === 'active' &&
                              license.totalLockedNft < license.nft
                            );
                            // !license.isExpired && Number(license.staked) > 0 && license.status !== 'inactive' && license.totalLockedNft < license.nft && license?.linkStatus === 'LINK'
                          })
                          ?.map((item: any) => ({
                            ...item,
                            label: item.name,
                            value: item._id,
                          }))
                      : []
                  }
                  selectedOption={selectedOption}
                  setSelectedOption={(option: any) => {
                    setSelectedOption(option);
                  }}
                  placeHolder={t('defi.myCloudk.Hardwares.select')}
                  firstAutoSelected={false}
                />
              </>
            }
          </div>
        </div>
      </div>

      <div className="w-full mt-3">
        <GlobalButton
          text={t('defi.myCloudk.connectLicense')}
          type="button"
          loading={linkLicenseWithMinterLoading}
          disabled={
            !linkLicenseWithMinterLoading && minterName && selectedOption
              ? false
              : true
          }
          containerClassName={'w-full'}
          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
               ${!linkLicenseWithMinterLoading && minterName && selectedOption ? '!bg-updateBtnGredient' : 'bg-[#C0D5DF]'} font-medium !text-[#FFFFFF]`}
          onClick={async () => {
            const data = {
              minterId: minter?._id,
              licenseId: selectedOption?._id,
              type: minter?.type,
            };
            const response = await LinkLicenseWithMinterFunc(data);
            if (response?.data?.status) {
              handleClose();
              getUserMinterLinkedLicenses({
                page: 1,
                limit: 10,
                id: minter?._id,
              });

              getCloudKTransactionHistoryV2(
                transactionHistoryType ?? tabs[0].key,
                transactionHistoryFilters ?? {
                  page: 1,
                  limit: 10,
                  licenseName: '',
                  sort: {},
                }
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default LinkedLicense;
