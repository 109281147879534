import { homnifiLogo } from '../../images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '../../store/user/userActions';
import { selectUserSlice } from '../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { FEATURES, PLATFORM_NAME } from '../../utils/constants';
import { useTranslation } from 'react-i18next';


const AuthHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userLogout } = useUserActions();
  const { user } = useSelector(selectUserSlice);
  const { t } = useTranslation();

  return location.pathname === '/auth/signin' ? (
    // For Signup
    <div className="flex flex-col gap-4 text-sm sm:flex-row items-center justify-between w-full max-w-[1300px]">
      <div className="relative">
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME} Logo`}
          className="cursor-pointer w-36"
          onClick={() => (window.location.href = 'https://homnifi.com')}
        />
      </div>
      {FEATURES.showSignup && (
        <div className="w-full xs:w-auto">
          {t("auth.haveAnyAccount")}{' '}
          <button
            onClick={() => navigate('/auth/signup')}
            className="font-medium text-primary"
          >
            {t("auth.forms.label.signUp")}
          </button>
        </div>
      )}
    </div>
  ) : (
    // For Login
    <div className="flex flex-col gap-4 text-sm sm:flex-row items-center justify-between w-full max-w-[1300px]">
      <div className="relative">
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME} Logo`}
          className="cursor-pointer w-36"
          onClick={() => (window.location.href = 'https://homnifi.com')}
        />
      </div>
      {!user ? (
        <div className="w-full xs:w-auto">
          {t("auth.alreadyHaveAnAccount")}{' '}
          <button
            onClick={() => {
              // userLogout();
              navigate('/auth/signin');
            }}
            className="font-medium text-primary"
          >
            {t("auth.forms.label.signIn")}
          </button>
        </div>
      ) : (
        <div className="w-full xs:w-auto">
          {t("auth.forms.errors.Not")} {user?.email}?{' '}
          <button onClick={userLogout} className="font-medium text-primary">
            {t("auth.forms.label.logOut")}
          </button>
        </div>
      )}
    </div>
  );
};

export default AuthHeader;
