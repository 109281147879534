/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';

import { errorAlert } from '../../../utils/alerts';
import GlobalTextInput from '../../../components/Global/GlobalTextInput';
import GlobalButton from '../../../components/Global/GlobalButton';
import GlobalModal from '../../../components/Global/GlobalModal';
import { selectTBalance } from '../../../store/TBalance/TBalanceSlice';
import { useTBalanceActions } from '../../../store/TBalance/TBalanceActions';
import e from 'express';

declare global {
  interface Window {
    RichTextEditor: any;
  }
}

interface Props {
  type: string;
  setType: (e: string) => void;
  open: boolean;
  onClose: () => void;
  autoHeight?: string;
  editData?: any;
  title?: string;
}

function OrderDetailModal({
  type,
  setType,
  open,
  onClose,
  autoHeight,
  editData,
  title,
}: Props): ReactElement {
  const [step1, setStep1] = React.useState(true);

  const {
    isTBalanceProductsPurchaseLoading,
    isTBalanceProductsPurchaseUpdateLoading,
  } = useSelector(selectTBalance);

  const {
    getTBalance,
    postTBalanceProductsPurchase,
    updateTBalanceProductsPurchase,
    getTBalanceProductsHistory,
    getTBalanceWalletList,
  } = useTBalanceActions();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name must not exceed 50 characters')
      .required('First name is required'),

    lastName: Yup.string()
      .trim()
      .min(2, 'Last name must be at least 2 characters')
      .max(50, 'Last name must not exceed 50 characters')
      .required('Last name is required'),

    email: Yup.string()
      .trim()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      try {
        if (editData) {
          if (type === 'EDIT') {
            await updateTBalanceProductsPurchase(
              {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
              },
              editData?._id
            );
            await getTBalanceProductsHistory({
              limit: 10,
              page: 1,
            });
          } else {
            await postTBalanceProductsPurchase({
              product: editData?._id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
            });
            await getTBalanceProductsHistory({
              limit: 10,
              page: 1,
            });
            await getTBalance();
            await getTBalanceWalletList();
          }

          onResetStates();
          onClose();
        }
      } catch (error: any) {
        errorAlert(
          error?.data?.message || 'An error occurred while submitting.'
        );
      }
    },
  });

  const { handleSubmit, resetForm, setValues } = formik;

  useEffect(() => {
    if (type === 'EDIT') {
      setValues({
        firstName: editData?.lastEditId?.firstName ?? '',
        lastName: editData.lastEditId?.lastName ?? '',
        email: editData?.lastEditId?.email ?? '',
      });
    }
  }, [type]);

  const onResetStates = (): void => {
    onClose();
    resetForm();
    setStep1(true);
    setType('CREATE');
  };

  return (
    <div>
      <GlobalModal
        isOpen={open}
        size="xl"
        onClose={onResetStates}
        title={title}
      >
        <>
          <form
            className={`pb-4 ${autoHeight ? autoHeight : 'h-[750px]'}`}
            onSubmit={(e): void => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="text-2xl font-medium">
                  {step1 ? 'Order Details' : 'Review'}
                </div>
              </div>

              {step1 ? (
                <>
                  <div className="w-full px-4 py-2 text-sm border rounded bg-primary/20 text-primary border-primary">
                    Please enter the TAG account details
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col w-full gap-4 ">
                      <div className="w-full">
                        <GlobalTextInput
                          name="firstName"
                          label="First Name"
                          placeholder="Enter First Name"
                          type="text"
                          onBlur={formik.handleBlur}
                          required={true}
                          errorMessage={
                            formik.touched.firstName && formik.errors.firstName
                              ? formik.errors.firstName
                              : undefined
                          }
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <GlobalTextInput
                          name="lastName"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          type="text"
                          onBlur={formik.handleBlur}
                          required={true}
                          errorMessage={
                            formik.touched.lastName && formik.errors.lastName
                              ? formik.errors.lastName
                              : undefined
                          }
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <GlobalTextInput
                          name="email"
                          label="Email"
                          placeholder="Enter Email"
                          type="email"
                          onBlur={formik.handleBlur}
                          required={true}
                          errorMessage={
                            formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : undefined
                          }
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col items-center xs:flex-row text-sm ml-1 md:text-base md:mx-2 mt-[20px] gap-2 justify-end ">
                      <GlobalButton
                        text="Review"
                        loading={isTBalanceProductsPurchaseLoading}
                        onClick={(e: any) => {
                          e.preventDefault();
                          setStep1(false);
                        }}
                        disabled={
                          !formik.values.email ||
                          !formik.values.firstName ||
                          !formik.values.lastName ||
                          formik.errors.firstName ||
                          formik.errors.lastName ||
                          formik.errors.email
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between w-full gap-4">
                    <div className="flex flex-col w-full gap-2">
                      <span className="text-xs text-[#000000]">First name</span>
                      <span className="text-xs font-medium text-[#000000]">
                        {formik.values.firstName}
                      </span>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <span className="text-xs text-[#000000]">Last name</span>
                      <span className="text-xs font-medium text-[#000000]">
                        {formik.values.lastName}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="text-xs text-[#000000]">Email</span>
                    <span className="text-xs font-medium text-[#000000]">
                      {formik.values.email}
                    </span>
                  </div>
                  <div className="flex items-center w-full gap-4">
                    <div className="flex flex-col w-full gap-2">
                      <span className="text-xs text-[#000000]">Product</span>
                      <span className="text-xs font-medium text-[#000000]">
                        {editData?.name || editData?.tProduct?.name}
                      </span>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <span className="text-xs text-[#000000]">Amount</span>
                      <span className="text-xs font-medium text-[#000000]">
                        TG {editData?.price || editData?.tProduct?.price}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center xs:flex-row text-sm ml-1 md:text-base md:mx-2 mt-[20px] gap-2 justify-end ">
                    <GlobalButton
                      text="Submit"
                      type="submit"
                      loading={
                        isTBalanceProductsPurchaseLoading ||
                        isTBalanceProductsPurchaseUpdateLoading
                      }
                      onClick={(e: any) => {
                        handleSubmit(e);
                        onResetStates();
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </>
      </GlobalModal>
    </div>
  );
}

export default OrderDetailModal;
