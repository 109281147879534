import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const WALLEK_SLICE_NAME = 'wallek';

export type TPaginated = {
  totalPages: number;
  currentPage: number;
  totalCount: number;
};

type ClaimData = {
  _id: string;
  user: string;
  bid: string;
  stake: string;
  stakeExpiryDate: string;
  claimDate: string;
  status: 'success' | 'pending' | 'failed';
  amount: number;
  wallet: string;
  timeToExpiry: number;
  claimedBeforeExpiry: boolean;
  token: Record<string, any>;
};

export type WALLEK_CLAIM_HISTORY = {
  list: ClaimData[];
} & TPaginated;

interface Token {
  _id: string;
  name: string;
  symbol: string;
  type: string;
  withdrawType: string;
  color: string;
  borderColor: string;
  networks: string[];
  iconUrl: string;
  platforms: string[];
  valueType: string;
  showZeroBalance: boolean;
  customRate:number | undefined;
  conversionType:string;
}

interface WallekStakedList {
  _id: string;
  user: string;
  userBid: string;
  amount: number;
  tokenPrice: number;
  tokenAmount: number;
  token: Token;
  isClaimed: boolean;
  wallet: {};
  expiryDate: string;
  stakedPeriod: number;
  startStakedDate: string;
  endStakedDate: string;
  lockupPeriod: number;
  status: string;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

interface WallekStakeData {
  result: WallekStakedList[] | null;
  page: number;
  limit: number;
  totalCount: number;
}

export interface StateWallek {
  wallekClaimHistoryList: WALLEK_CLAIM_HISTORY | null;
  wallekStakedList: WallekStakeData | null;
  wallekClaimHistoryLoading: boolean;
  wallekStakedLoading: boolean;
  claimWallekStakeLoading: boolean;
  resetHistoryFilter: boolean;
}

const initialState: StateWallek = {
  wallekClaimHistoryList: null,
  wallekStakedList: null,
  wallekClaimHistoryLoading: false,
  wallekStakedLoading: false,
  claimWallekStakeLoading: false,
  resetHistoryFilter: false,
};

const slice = createSlice({
  name: WALLEK_SLICE_NAME,
  initialState,
  reducers: {
    setWallekSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setWallekClaimHistory: (state, action: PayloadAction<any>) => {
      return { ...state, wallekClaimHistoryList: action.payload };
    },
    setStakedWallek: (state, action: PayloadAction<any>) => {
      const walletList =
        state.wallekStakedList && state.wallekStakedList?.result?.length
          ? state.wallekStakedList?.result
          : [];

      const newData = action.payload.result || [];

      const uniqueDataMap = new Map();

      walletList.forEach((item) => {
        uniqueDataMap.set(item._id, item);
      });

      newData.forEach((item: any) => {
        uniqueDataMap.set(item._id, item);
      });

      const mergedList = Array.from(uniqueDataMap.values());

      return {
        ...state,
        wallekStakedList: {
          ...state.wallekStakedList,
          ...action.payload,
          result: mergedList,
        },
      };
    },
    setResetHistoryFilter: (state, action: PayloadAction<any>) => {
      return { ...state, resetHistoryFilter: action.payload };
    },
    updateWalletClaim: (state, action: PayloadAction<any>) => {
      const index =
        state.wallekStakedList && state.wallekStakedList?.result?.length
          ? state.wallekStakedList?.result.findIndex(
              (item) => item._id === action.payload
            )
          : -1;
      if (
        index !== -1 &&
        state.wallekStakedList &&
        state.wallekStakedList?.result?.length
      ) {
        state.wallekStakedList.result[index] = {
          ...state.wallekStakedList.result[index],
          isClaimed: true,
        };
      }
    },
  },
});

export const {
  setWallekClaimHistory,
  setStakedWallek,
  setWallekSlice,
  setResetHistoryFilter,
  updateWalletClaim,
} = slice.actions;
export const selectWallekPoolSlice = (state: {
  [WALLEK_SLICE_NAME]: StateWallek;
}) => state[WALLEK_SLICE_NAME];
export default slice.reducer;
