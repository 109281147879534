import { memo } from 'react';
import GlobalModal from '../../../components/Global/GlobalModal';
import GlobalButton from '../../../components/Global/GlobalButton';
import {
  voucherClickIcon,
  voucherIcon,
  voucherModalBg,
  voucherSoftwareIcon,
} from '../../../images';
import { useTranslation } from 'react-i18next';
interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const HowToRedeemModal = memo(({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <GlobalModal isOpen={isOpen} onClose={onClose} size="xl">
      <img src={voucherModalBg} className="absolute right-0 top-0 -z-10" />
      <div className="flex flex-col gap-5 mx-2 relative">
        <div className="font-medium text-xl text-defaultBlack">
          {/* How to Redeem */}
          {t('My Vouchers.howToRedeem')}
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-normal text-base text-primaryGrey">
            {/* 1. Access Quantwise.{' '} */}
            1. {t('My Vouchers.modal.step1')}{' '}
          </div>
          <div className="font-normal text-base text-primaryGrey">
            <span>
              {/* 2. Redeem your voucher code */}
              2. {t('My Vouchers.modal.step2')}
            </span>
            <img src={voucherIcon} className="inline-block ml-1" />
          </div>
          <div className="font-normal text-base text-primaryGrey">
            {/* 3. Navigate to the Software Section on Quantwise, copy the voucher
            code, and click "Connect Now" */}
            3. {t('My Vouchers.modal.step3')}
            <img src={voucherClickIcon} className="ml-1 inline-block" />
          </div>
          <div className="font-normal text-base text-primaryGrey">
            <span>
              {/* 4. Create your Unicapital Account. */}
              4. {t('My Vouchers.modal.step4')}
            </span>
          </div>
          <div className="font-normal text-base text-primaryGrey">
            {/* 5. Paste the voucher code to unlock your selected trading service or
            software */}
            5. {t('My Vouchers.modal.step5')}
            <img src={voucherSoftwareIcon} className="ml-1 inline-block" />{' '}
          </div>
          <div className="font-normal text-base text-primaryGrey">
            {/* 6. Follow the on-screen instructions to begin investing.{' '} */}
            6. {t('My Vouchers.modal.step6')}
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-3">
        <GlobalButton
          classNames="bg-primary !rounded-full text-white md:!text-lg font-medium w-32"
          text={t('general.Done')}
          bigFont={true}
          onClick={onClose}
        />
      </div>
    </GlobalModal>
  );
});

export default HowToRedeemModal;
