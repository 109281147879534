import CardLayout from '../../../../../components/Cards/CardLayout';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCloudSlice,
  setCloudkSlice,
} from '../../../../../store/cloudk/cloudkSlice';
import LineChart from '../../../../../components/Charts/LineChart';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { useEffect, useState } from 'react';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import CloudKSelect from '../../Common/CloudKSelect/CloudKSelect';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const RewardAnalytics = () => {
  const {
    activeRewardTab,
    activeRewardTime,
    rewardAnalyticsGraphLoading,
    rewardAnalyticsGraphData,
  } = useSelector(selectCloudSlice);
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const { t } = useTranslation();
  const tabs = [
    { key: 'license', label: t('defi.myCloudk.license') },
    { key: 'minter', label: t('defi.myCloudk.devices') },
    // { key: 'all', label: 'All' },
  ];
  const timeTabs = [
    { key: 'quarterly', label: '3M' },
    { key: 'half-yearly', label: '6M' },
    { key: 'yearly', label: '1Y' },
    { key: 'all', label: t('general.all') },
  ];
  const { rewardAnalyticsGraphDataFunc } = useCloudkActions();
  const [elementOptions, setElementOptions] = useState<any>([]);
  const [timeOptions, setTimeOptions] = useState<any>([]);

  useEffect(() => {
    if (tabs?.length > 0) {
      setElementOptions(
        tabs?.map((tab: any) => ({
          value: tab.key,
          label: tab.label,
          key: tab.key,
        }))
      );
    }
    if (timeTabs.length > 0) {
      setTimeOptions(
        timeTabs?.map((tab: any) => ({
          value: tab.key,
          label: tab.label,
          key: tab.key,
        }))
      );
    }
  }, []);

  const handleButtonClick = (buttonText: any) => {
    dispatch(
      setCloudkSlice({
        activeRewardTab: buttonText,
      })
    );
  };
  const handleTimeTabClick = (buttonText: any) => {
    dispatch(
      setCloudkSlice({
        activeRewardTime: buttonText,
      })
    );
  };
  useEffect(() => {
    rewardAnalyticsGraphDataFunc(activeRewardTab.key, activeRewardTime.key);
  }, [activeRewardTab, activeRewardTime]);

  const handleElementChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    dispatch(
      setCloudkSlice({
        activeRewardTab: selectedOption,
      })
    );
  };

  const handleLicensesChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    dispatch(
      setCloudkSlice({
        activeRewardTime: selectedOption,
      })
    );
  };
  const colors = rewardAnalyticsGraphData?.map((item: any) => item?.color);

  return (
    <CardLayout className="md:col-span-7 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow bg-[#FFFFFF]">
      <section className="flex flex-col gap-4">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <h1 className="text-sm font-medium text-secondaryBlack sm:text-lg">
            {t('defi.myCloudk.rewards')}
          </h1>
          <div className="flex md:hidden">
            <div className="flex flex-wrap justify-end gap-2">
              <CloudKSelect
                options={elementOptions}
                placeholder=""
                onChange={handleElementChange} // Handle the option change
                selectedOption={activeRewardTab}
                btnStyle={'!gap-x-0 !bg-[#EAF4FF]'}
              />
              <CloudKSelect
                selectedOption={activeRewardTime}
                options={timeOptions}
                placeholder=""
                onChange={handleLicensesChange}
                btnStyle={'!gap-x-0 !bg-[#EAF4FF]'}
              />
            </div>
          </div>
          <div className="hidden md:flex">
            <div className="flex justify-end gap-2 ">
              {tabs.map((tab) => (
                <button
                  key={tab.key}
                  onClick={() => handleButtonClick(tab)}
                  className={`!px-3 !py-1  max-w-44 font-normal rounded-lg transition-all
                              hover:text-primary bg-transparent border h-10 ${activeRewardTab.key === tab.key && ' !bg-[#EAF4FF]'}`}
                  style={{
                    transformStyle: 'preserve-3d',
                  }}
                >
                  {activeRewardTab.key === tab.key && (
                    <motion.span
                      layoutId="bubble"
                      className="absolute inset-0 bg-inherit rounded-lg !border-primary"
                      transition={{
                        type: 'spring',
                        bounce: 0.2,
                        duration: 0.6,
                      }}
                    />
                  )}
                  <span
                    className={`relative block ${activeRewardTab.key === tab.key ? 'text-[#344054]' : 'text-[#344054]'}`}
                  >
                    {tab.label}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="flex gap-2">
            {timeTabs.map((time) => {
              return (
                <div
                  onClick={() => handleTimeTabClick(time)}
                  className={`flex cursor-pointer justify-center items-center h-10 w-14 border ${activeRewardTime.key === time.key ? 'bg-[#EAF4FF]' : 'bg-[#FFFFFF]'}  rounded-lg text-sm font-medium`}
                  key={time.key}
                >
                  {time.label}
                </div>
              );
            })}
          </div>
        </div>
        <article className="w-full  h-[150px]">
          {rewardAnalyticsGraphLoading ? (
            <div className="flex items-center justify-center flex-1 w-full h-full">
              <div className="items-center self-center justify-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="h-6 animate-spin" />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-full">
              {rewardAnalyticsGraphData && (
                <LineChart
                  containerClassName={'!px-0'}
                  data={rewardAnalyticsGraphData}
                  isCloudKChart={true}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    // stacked: true,
                    // reverse: false
                  }}
                  pointSize={7}
                  pointColor={{ from: 'color', modifiers: [] }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'color', modifiers: [] }}
                  pointLabel="data.yFormatted"
                  pointLabelYOffset={-16}
                  areaOpacity={0.1}
                  // defs={TotalRewardsChartDefs}
                  // fill={TotalRewardsChartFill}
                  margin={{ top: 20, right: 17, bottom: 35, left: 17 }}
                  isInteractive={true}
                  enablePoints={false}
                  enableGridX={false}
                  enableGridY={true}
                  enableArea={true}
                  colors={colors}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 3, // Shorten tick lines for a cleaner look
                    tickPadding: 4, // Bring labels closer to the chart
                    tickRotation:
                      activeRewardTime?.key === 'yearly' ||
                      activeRewardTime?.key === 'all' ||
                      isMobileView
                        ? 69
                        : 0,
                    legend: '',
                    legendOffset: 32, // Adjust positioning of legend if needed
                    legendPosition: 'end',
                    truncateTickAt: 100,
                  }}
                  axisLeft={null}
                  theme={{
                    text: {
                      fontSize: '12px',
                      fill: '#AAAAAA',
                    },
                    grid: {
                      line: {
                        stroke: '#E0E0E0', // Ensure this is a visible color
                        strokeWidth: 1,
                        // strokeDasharray: [6, 4],
                      },
                      top: {
                        line: {
                          stroke: 'transparent', // Make the top grid line invisible
                          strokeWidth: 0,
                        },
                      },
                    },
                    axis: {
                      ticks: {
                        line: {
                          stroke: '#FFFFFF40',
                        },
                        text: {
                          fontSize:
                            activeRewardTime?.key === 'yearly' ||
                            activeRewardTime?.key === 'all' ||
                            isMobileView
                              ? '8px'
                              : '10px',
                          fill: '#616161',
                        },
                      },
                    },
                  }}
                  legends={[
                    {
                      anchor: 'top-right',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -25,
                      itemWidth: 55,
                      itemHeight: 25,
                      itemsSpacing: 20,
                      symbolSize: 9,
                      symbolShape: 'circle',
                      itemDirection: 'left-to-right',
                      itemTextColor: '#616161',
                      textStyle: { fontSize: 16 },
                    },
                  ]}
                  gridXValues={2}
                  gridYValues={4}
                  tootlipColor={'#292929'}
                  showSub={true}
                  curve={'basis'}
                />
              )}
            </div>
          )}
        </article>
      </section>
    </CardLayout>
  );
};

export default RewardAnalytics;
