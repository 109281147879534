import GlobalHeader from '../../components/Global/GlobalHeader';
import MyFirstLineUsers from './_components/MyFirstLineUsers';

const MyFirstLine = () => {
  return (
    <div className="flex flex-col gap-4">
      <GlobalHeader title={'My First Line'} />
      <MyFirstLineUsers />
    </div>
  );
};

export default MyFirstLine;
