import React from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { tokenIcon2, totalLinkedWaterMark } from '../../../../../images/cloudk';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { toFixedFormat } from '../../../../../utils/utils';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { setDecimalPlaces } from '../../../../../utils/helper';
import { useTranslation } from 'react-i18next';

const TotalLinkedMlyk = () => {
  const { totalStacked, upgradeAbleMachinesLoading } =
    useSelector(selectCloudSlice);
  const { t } = useTranslation();

  return (
    <CardLayout className="md:col-span-6 xl:col-span-3 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow relative bg-[#FFFFFF]">
      {upgradeAbleMachinesLoading ? (
        <div className="flex items-center justify-center flex-1 w-full h-full">
          <div className="items-center self-center justify-center">
            <div className="flex flex-col gap-5">
              <Spinner className="h-6 animate-spin" />
            </div>
          </div>
        </div>
      ) : (
        <section className="flex flex-col gap-2 ">
          <span className="font-medium text-[#616161]">
            {t('defi.nodek.Tlinked')} mLYK
          </span>
          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8">
                <img src={tokenIcon2} alt={'icon'} />
              </div>
              <span className="text-2xl font-medium xl:text-xl 2xl:text-3xl sm:text-3xl ">
                {toFixedFormat(totalStacked?.totalToken || 0, 3)}
                <span className="text-base">{totalStacked.tokenName}</span>
              </span>
            </div>
            <div>
              <div
                className={
                  'rounded-[100px] border border-[#1B9FDA] py-2 px-4 text-[#1B9FDA] font-medium text-sm inline-flex '
                }
              >
                ${toFixedFormat(totalStacked?.tokenAmountInUsd || 0, 2)}
                {/* ({t('general.amount')} in USD) */}
              </div>
            </div>
          </div>
        </section>
      )}

      <img
        src={totalLinkedWaterMark}
        alt={'water-mark'}
        className="absolute h-full top-2 right-6"
      />
    </CardLayout>
  );
};

export default TotalLinkedMlyk;
