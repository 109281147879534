import { useDispatch, useSelector } from 'react-redux';
import {
  SUPERNODE_TREE_CHILDREN,
  SUPERNODE_TREE_CHILDREN_SUMMARY_DETAILS,
  SUPERNODE_TREE_SEARCH,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import { toast } from 'react-toastify';
import { selectMyTreeSlice, setMyTreeSlice } from './myTreeSlice.ts';

interface Props {
  id?: string | null;
  limit?: number;
  page?: number;
}

export const useMyTreeActions = () => {
  const dispatch = useDispatch();
  const { supernodeTreeChildren, supernodeTreeSearch } =
    useSelector(selectMyTreeSlice);

  const getSuperNodeTreeChildren = async ({ id, limit, page }: Props) => {
    dispatch(
      setMyTreeSlice({
        getSuperNodeTreeChildrenLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPERNODE_TREE_CHILDREN,
        params: { id: id, limit: limit, page: page },
      })
      .then((response) => {
        const { data } = response;

        interface Child {
          _id: string;
          name?: string;
          children?: Child[];
          page?: number;
          totalPages?: number;
        }

        const updateReducerData = (
          firstData: any | null,
          secondData: { user: Child; page: number; totalPages: number }
        ) => {
          if (!firstData) {
            return secondData;
          }

          if (
            firstData.page < firstData.totalPages &&
            firstData.user._id === secondData.user._id &&
            firstData.user.children[0]?._id !==
              secondData?.user?.children?.[0]?._id
          ) {
            return {
              ...firstData,
              user: {
                ...firstData.user,
                children: [
                  ...firstData.user.children,
                  ...(secondData.user.children || []).filter(
                    (newChild: Child) =>
                      !firstData.user.children.some(
                        (existingChild: Child) =>
                          existingChild._id === newChild._id
                      )
                  ),
                ],
              },
            };
          }

          if (firstData.user._id === secondData.user._id) {
            firstData.page = secondData.page;
            firstData.totalPages = secondData.totalPages;
          }

          // Recursive function to update children with page and totalPages
          const updateChildRecursively = (
            children: Child[],
            secondDataUser: Child
          ): Child[] => {
            return children
              .filter(
                (child: Child) =>
                  !secondDataUser.children?.some(
                    (secondChild: Child) => secondChild._id === child._id
                  )
              )
              .map((child: Child) => {
                // If the current child's _id matches, merge data
                if (child._id === secondDataUser._id) {
                  return {
                    ...child,
                    page: secondData.page,
                    totalPages: secondData.totalPages,
                    children: [
                      ...(child.children || []),
                      ...(secondDataUser.children || []).filter(
                        (newChild: Child) =>
                          !child.children?.some(
                            (existingChild: Child) =>
                              existingChild._id === newChild._id
                          )
                      ),
                    ],
                  };
                }

                // If the child has nested children, recurse into them
                if (child.children) {
                  return {
                    ...child,
                    children: updateChildRecursively(
                      child.children,
                      secondDataUser
                    ),
                  };
                }
                // Return the child as is if no match or nested children are found
                return child;
              });
          };

          // Update the entire structure
          const updatedFirstData = {
            ...firstData,
            user: {
              ...firstData.user,
              children: updateChildRecursively(
                firstData.user.children,
                secondData.user
              ),
            },
          };

          return updatedFirstData;
        };

        // Update the supernodeTreeSearch if it exists
        if (supernodeTreeSearch) {
          const updatedSearchData = updateReducerData(
            supernodeTreeSearch,
            data
          );

          dispatch(
            setMyTreeSlice({
              supernodeTreeSearch: updatedSearchData,
            })
          );
        }

        // Update the supernodeTreeChildren with the new data
        const updatedData = updateReducerData(supernodeTreeChildren, data);

        dispatch(
          setMyTreeSlice({
            supernodeTreeParentInfo: updatedData.user,
            supernodeTreeChildren: updatedData,
            getSuperNodeTreeChildrenLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setMyTreeSlice({
            getSuperNodeTreeChildrenLoading: false,
          })
        );
        console.error(error);
        return error;
      });
  };

  const getSuperNodeTreeSearch = async ({
    searchQuery,
    limit,
    page,
    showParent = false,
  }: any) => {
    dispatch(
      setMyTreeSlice({
        getSuperNodeTreeSearchLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPERNODE_TREE_SEARCH,
        params: { searchQuery: searchQuery, limit: limit, page: page },
      })
      .then((response) => {
        const { data } = response;

        interface Child {
          _id: string;
          name?: string;
          children?: Child[];
        }

        const updateReducerData = (
          firstData: any | null,
          secondData: { user: Child }
        ) => {
          if (!firstData) {
            return secondData;
          }

          if (showParent) {
            const updateSuperNodeTreeSearch = (
              originalData: any,
              newData: any
            ): any => {
              const findDeepMatch = (
                children: any[],
                blockchainId: string
              ): any => {
                for (const child of children) {
                  if (child.blockchainId === blockchainId) {
                    return child;
                  }
                  if (child.children?.length > 0) {
                    const match = findDeepMatch(child.children, blockchainId);
                    if (match) return match;
                  }
                }
                return null;
              };

              const updateChildrenRecursively = (
                children: any[],
                targetBlockchainId: string,
                childrenToAdd: any[]
              ): any[] => {
                return children.map((child) => {
                  if (child.blockchainId === targetBlockchainId) {
                    return {
                      ...child,
                      children: [...(child.children || []), ...childrenToAdd],
                    };
                  }

                  if (child.children?.length > 0) {
                    return {
                      ...child,
                      children: updateChildrenRecursively(
                        child.children,
                        targetBlockchainId,
                        childrenToAdd
                      ),
                    };
                  }

                  return child;
                });
              };

              if (newData?.user?.children?.length > 0) {
                const [newChild] = newData.user.children;

                const existingMatch = findDeepMatch(
                  originalData?.user?.children || [],
                  newChild.blockchainId
                );

                if (existingMatch) {
                  // Find children to add
                  const childrenToAdd = originalData.user.children.filter(
                    (child: any) =>
                      child.uplineId.blockchainId === existingMatch.blockchainId
                  );

                  if (childrenToAdd.length > 0) {
                    // Update the children immutably
                    const updatedChildren = updateChildrenRecursively(
                      originalData.user.children,
                      existingMatch.blockchainId,
                      childrenToAdd
                    );

                    // Filter out the added children from the original children
                    const filteredChildren = updatedChildren.filter(
                      (child: any) =>
                        !childrenToAdd.some(
                          (toAdd: any) =>
                            toAdd.blockchainId === child.blockchainId
                        )
                    );

                    return {
                      ...originalData,
                      user: {
                        ...originalData.user,
                        children: filteredChildren,
                      },
                    };
                  }
                } else {
                  // Fallback to current logic for handling new children
                  const matchingChildren = originalData.user.children.filter(
                    (child: any) =>
                      child.uplineId.blockchainId === newChild.blockchainId
                  );

                  if (matchingChildren.length > 0) {
                    newChild.children = [...matchingChildren];

                    const filteredChildren = originalData.user.children.filter(
                      (child: any) => {
                        const isMatching = matchingChildren.some(
                          (matchingChild: any) =>
                            matchingChild.blockchainId === child.blockchainId
                        );
                        return !isMatching;
                      }
                    );

                    return {
                      limit: originalData?.limit,
                      page: originalData?.page,
                      total: originalData?.total,
                      totalPages: originalData?.totalPages,
                      user: {
                        children: [newChild, ...filteredChildren],
                      },
                    };
                  }
                }
              }

              return originalData;
            };

            const updatedData = updateSuperNodeTreeSearch(
              supernodeTreeSearch,
              data
            );
            return updatedData;
          }

          if (
            firstData.page < firstData.totalPages &&
            firstData.user._id === secondData.user._id &&
            firstData.user.children[0]?._id !==
              secondData?.user?.children?.[0]?._id
          ) {
            return {
              ...firstData,
              user: {
                ...firstData.user,
                children: [
                  ...firstData.user.children,
                  ...(secondData.user.children || []).filter(
                    (newChild: Child) =>
                      !firstData.user.children.some(
                        (existingChild: Child) =>
                          existingChild._id === newChild._id
                      )
                  ),
                ],
              },
            };
          }

          // Recursive function to find and update the matching child
          const updateChildRecursively = (
            children: Child[],
            secondDataUser: Child
          ): Child[] => {
            return children.map((child: Child) => {
              // If the current child's _id matches, merge new children
              if (child._id === secondDataUser._id) {
                return {
                  ...child,
                  children: [
                    ...(child.children || []),
                    ...(secondDataUser.children || []).filter(
                      (newChild: Child) =>
                        !child.children?.some(
                          (existingChild: Child) =>
                            existingChild._id === newChild._id
                        )
                    ),
                  ],
                };
              }

              // If the child has nested children, recurse into them
              if (child.children) {
                return {
                  ...child,
                  children: updateChildRecursively(
                    child.children,
                    secondDataUser
                  ),
                };
              }
              // Return the child as is if no match or nested children are found
              return child;
            });
          };

          // Create a new updated structure
          const updatedFirstData = {
            ...firstData,
            user: {
              ...firstData.user,
              children: updateChildRecursively(
                firstData.user.children,
                secondData.user
              ),
            },
          };

          return updatedFirstData;
        };

        const newData = updateReducerData(supernodeTreeSearch, data);

        if (searchQuery?.length === 0) {
          dispatch(
            setMyTreeSlice({
              supernodeTreeSearch: null,
              getSuperNodeTreeSearchLoading: false,
            })
          );
        } else {
          dispatch(
            setMyTreeSlice({
              supernodeTreeSearch: newData,
              getSuperNodeTreeSearchLoading: false,
            })
          );
        }

        return response;
      })
      .catch((error) => {
        dispatch(
          setMyTreeSlice({
            getSuperNodeTreeSearchLoading: false,
          })
        );
        return error;
      });
  };

  const getSuperNodeTreeChildrenDetails = async (user: string) => {
    dispatch(
      setMyTreeSlice({
        getSupernodeTreeChildrenDetailsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: SUPERNODE_TREE_CHILDREN_SUMMARY_DETAILS,
        params: {
          user,
        },
      })
      .then((response) => {
        const { data } = response;

        dispatch(
          setMyTreeSlice({
            getSupernodeTreeChildrenDetails: data.data.result,
            getSupernodeTreeChildrenDetailsLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setMyTreeSlice({
            getSupernodeTreeChildrenDetailsLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  return {
    getSuperNodeTreeChildren,
    getSuperNodeTreeChildrenDetails,
    getSuperNodeTreeSearch,
  };
};
