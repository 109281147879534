import { useEffect, useLayoutEffect, useState } from 'react';
import GlobalHeading from '../../components/Global/GlobalHeading';
import GlobalTable from '../../components/Global/GlobalTable';
import { useWallekActions } from '../../store/wallek/walleKAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWallekPoolSlice,
  setResetHistoryFilter,
} from '../../store/wallek/walleKSlice';
import useDebounce from '../../hooks/useDebounceHook';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../utils/utils';
import { format } from 'date-fns';
import StatusChip from '../../components/StatusChip';
import GetCSVData from '../../components/GetCSVData';
import { getYearsAndMonthsLeft } from '../WalleK/utils';
import { FEATURES } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ClaimHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    wallekClaimHistoryList,
    wallekClaimHistoryLoading,
    wallekStakedList,
    wallekStakedLoading,
  } = useSelector(selectWallekPoolSlice);
  const { getWallekClaimHistory } = useWallekActions();
  const { t } = useTranslation();

  const [downloadTransaction, setDownloadTransaction] = useState([]);
  const [resetFilter, setResetFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    totalPages: 0,
    totalCount: 0,
    query: null,
  });
  const [resetDisable, setResetDisable] = useState(
    !wallekClaimHistoryList?.list?.length
  );
  const { debouncedValue: debouncedSearch } = useDebounce(filters.query, 1000);

  const formatDate = (isoString?: string) =>
    isoString ? format(new Date(isoString), 'dd-MM-yyyy - HH:mm') : '';

  const filterEmptyCheck = () => !filters.query;

  const headCells = [
    {
      id: 'depositID',
      label: 'Deposit ID',
      dataClass: ' lg:!w-[16rem]',
    },

    { id: 'fromToken', label: 'From Token', dataClass: ' lg:!w-[6rem]' },
    {
      id: 'toToken',
      label: 'To Token',
      dataClass: ' lg:!w-[6rem]',
    },
    { id: 'amount', label: 'Amount', dataClass: ' lg:!w-[8rem]' },
    {
      id: 'stakedDate',
      label: 'Stake Start Date',
      dataClass: ' lg:!w-[10rem]',
    },
    {
      id: 'stakeExpiryDate',
      label: 'Stake Expiry Date',
      dataClass: ' lg:!w-[10rem]',
    },
    { id: 'lockupPeriod', label: 'Lockup Period', dataClass: ' lg:!w-[6rem]' },
    {
      id: 'claimDate',
      label: 'Claim Date',
      dataClass: ' lg:!w-[10rem]',
    },
    { id: 'status', label: 'Status', dataClass: ' lg:!w-[10rem]' },
  ];

  const filterDownloadData = (data: any): void => {
    const result = data?.map((item: any) => {
      return {
        depositID: item?.deposit_id,
        fromToken: item?.fromToken?.name,
        toToken: item?.toToken?.name,
        amount: formatNumberWithCommas(
          Number(toFixedWithoutRoundingOff(item?.amount, 6))
        ),
        stakedDate: formatDate(item?.stakedDate),
        stakeExpiryDate: formatDate(item?.stakeExpiryDate),
        claimDate: formatDate(item?.claimDate),
        status: item?.status,
        lockupPeriod: `${getYearsAndMonthsLeft(item?.lockupPeriod)?.years ? `${getYearsAndMonthsLeft(item?.lockupPeriod).years} ${getYearsAndMonthsLeft(item?.lockupPeriod).years > 1 ? t('general.Years') : t('general.Year')}` : ''} ${getYearsAndMonthsLeft(item?.lockupPeriod)?.months ? `${getYearsAndMonthsLeft(item?.lockupPeriod).months} ${getYearsAndMonthsLeft(item?.lockupPeriod).months > 1 ? t('general.Months') : t('general.Month')}` : ''}`,
      };
    });
    return setDownloadTransaction(result);
  };

  const getFilteredApiData = async (
    filter?: any,
    storeOnRedux = true
  ): Promise<void> => {
    const res = await getWallekClaimHistory(
      {
        page: filter.page,
        limit: filter.limit,
        query: filter.query,
      },
      storeOnRedux
    );
    dispatch(setResetHistoryFilter(true));
    return res.data?.list;
  };

  const rows =
    (wallekClaimHistoryList &&
      wallekClaimHistoryList?.list?.length &&
      wallekClaimHistoryList?.list.map((item: any) => ({
        depositID: item?.deposit_id,
        fromToken: item?.fromToken?.name,
        toToken: item?.toToken?.name,
        amount: formatNumberWithCommas(
          Number(toFixedWithoutRoundingOff(item?.amount, 6))
        ),
        stakedDate: formatDate(item?.stakedDate),
        stakeExpiryDate: formatDate(item?.stakeExpiryDate),
        claimDate: formatDate(item?.claimDate),
        status: <StatusChip status={item?.status} />,
        lockupPeriod: `${getYearsAndMonthsLeft(item?.lockupPeriod)?.years ? `${getYearsAndMonthsLeft(item?.lockupPeriod).years} ${getYearsAndMonthsLeft(item?.lockupPeriod).years > 1 ? 'Years' : 'Year'}` : ''} ${getYearsAndMonthsLeft(item?.lockupPeriod)?.months ? `${getYearsAndMonthsLeft(item?.lockupPeriod).months} ${getYearsAndMonthsLeft(item?.lockupPeriod).months > 1 ? t('general.Months') : t('general.Month')}` : ''}`,
      }))) ||
    [];

  const handlePageChange = (page: number) => {
    const updatedFilters = { ...filters, page };
    setFilters(updatedFilters);
    getFilteredApiData(updatedFilters);
  };

  const handleSearch = (query: string) => {
    const updatedFilters = { ...filters, query: query || null, page: 1 };
    setFilters(updatedFilters);
    if (!query) getFilteredApiData(updatedFilters);
  };

  const handleReset = () => {
    const resetFilters = {
      limit: 10,
      page: 1,
      totalPages: 0,
      totalCount: 0,
      query: null,
    };
    setFilters(resetFilters);
    setResetFilter(true);
    getFilteredApiData(resetFilters);
  };

  useEffect(() => {
    if (!wallekClaimHistoryLoading && !wallekClaimHistoryList) {
      getWallekClaimHistory();
    }
  }, []);

  useEffect(() => {
    if (resetDisable && wallekClaimHistoryList) {
      setResetDisable(false);
    }
  }, [resetDisable, wallekClaimHistoryList]);

  useEffect(() => {
    if (!debouncedSearch) return;
    getFilteredApiData({ ...filters, query: debouncedSearch });
  }, [debouncedSearch]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-start justify-between">
        <div className="flex flex-wrap items-center">
          <button
            onClick={() => navigate('/wallek')}
            className="p-0 text-xl bg-transparent cursor-pointer font-baumans"
          >
            Wallek {'>'} &nbsp;
          </button>
          <GlobalHeading
            heading="Claim History"
            className="flex-1 !text-defaultBlack w-max"
          />
        </div>
      </div>
      <GlobalTable
        rows={rows}
        headCells={headCells}
        isLoading={wallekClaimHistoryLoading}
        searchIsLoading={false}
        page={1}
        showSearch={true}
        showDateFilter={false}
        pagination={true}
        totalPages={wallekClaimHistoryList?.totalPages ?? 0}
        tableContainerClassname={'!items-start lg:!block !rounded-lg text-wrap'}
        tableBodyClassnames="!break-all"
        handlePageChange={handlePageChange}
        emptyDataMessage="No Purchase History!"
        setSearchValue={handleSearch}
        searchValue={filters.query}
        resetDateFilter={resetFilter}
        showTotalRecords={false}
        searchFieldPlaceholderText={t(
          'dynamic.wallet.withdraw.placeholder.Enter ID'
        )}
        paginationPosition={'right'}
        // copyItems={['destination', 'hash']}
        colorItems={['status']}
        tableDropdowns={
          <div className="flex space-x-3 sm:items-center">
            <button
              className={`${resetDisable || filterEmptyCheck() ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
              type="button"
              disabled={resetDisable || filterEmptyCheck()}
              onClick={handleReset}
            >
              {t('general.reset')}
            </button>
          </div>
        }
        DownloadCSV={
          <GetCSVData
            disabled={!wallekClaimHistoryList?.totalCount}
            transactionData={downloadTransaction}
            fileName={`claim-history.csv`}
            classes="flex justify-end"
            onclick={(): void => {
              if (wallekClaimHistoryList?.list?.length) {
                getFilteredApiData(
                  { ...filters, limit: wallekClaimHistoryList?.totalCount },
                  false
                ).then((data): void => {
                  filterDownloadData(data);
                });
              }
            }}
            onDownloadReady={(): void => {
              // Callback function to handle any additional logic if needed
            }}
          />
        }
        searchdisable={resetDisable}
        filterSearchClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
      />
    </div>
  );
};

export default ClaimHistory;
