import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { store } from '../../store';
import { showModal } from '../../store/modal/modalSlice';
import { getAccessToken, getLoginToken } from '../storage/protectedStorage';
import { ENV_VARS } from '../constants';
import { toast } from 'react-toastify';

export default function ApiRequestHomnifi() {
  const accessToken = getAccessToken();
  const loginToken = getLoginToken();

  const request = axios.create({
    // baseURL: `${API_INSTANCE_HOMNIFI}`,
    baseURL: `${ENV_VARS.API_INSTANCE_HOMNIFI}`,
    headers: {
      Authorization: `Bearer ${accessToken ? accessToken : null}`,
      Bidtoken: `Token ${loginToken ? loginToken : null}`,
    },
    responseType: 'json',
    socketPath: null,
  });

  request.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      const state = store.getState();
      if (
        state?.user?.user?.isImpersonateMode &&
        config.method?.toUpperCase() !== 'GET'
      ) {
        toast.error(
          'Impersonate mode is active. Non-GET requests are blocked.'
        );
        throw new axios.Cancel();
      }
      return config;
    }
  );

  request.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError): Promise<AxiosError> | AxiosError => {
      if (error.code === 'ERR_NETWORK') {
        return Promise.reject(error);
      }

      if (error.response?.status === 401) {
        store.dispatch(showModal());
        return Promise.reject(error);
      }

      if (error.response?.status === 403) {
        store.dispatch(showModal());
        return Promise.reject(error);
      }

      if (error.response?.status === 503) {
        window.location.href = '/maintenance';
        // navigate('/maintenance');
        return Promise.reject(error);
      }
      if (error.response?.status === 504) {
        return Promise.reject({
          data: { message: '' },
          response: { data: { message: '' } },
        });
      }

      return Promise.reject(error.response);
    }
  );

  return request;
}
