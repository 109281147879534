import { createSlice } from '@reduxjs/toolkit';
import { ITBalanceInitialState } from '../../types/tBalance.types';

const initialState: ITBalanceInitialState = {
  TBalance: null,
  TBalanceProducts: null,
  TBalanceWalletList: null,
  AllTBalanceProducts: null,
  TBalanceSwappedTotal: null,
  TBalanceProductsHistory: null,
  TBalanceProductsPurchase: null,
  TBalanceProductsPurchaseUpdate: null,
  isTBalanceLoading: false,
  isTBalanceProductsLoading: false,
  isTBalanceWalletListLoading: false,
  isAllTBalanceProductsLoading: false,
  isTBalanceSwappedTotalLoading: false,
  isTBalanceProductsHistoryLoading: false,
  isTBalanceProductsPurchaseLoading: false,
  isTBalanceProductsPurchaseUpdateLoading: false,
};
export const T_BALANCE_SLICE_NAME = 'tBalanace';

const tBalanceSlice = createSlice({
  name: T_BALANCE_SLICE_NAME,
  initialState,
  reducers: {
    setTBalanceSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setTBalanceSlice } = tBalanceSlice.actions;

export const selectTBalance = (state: {
  [T_BALANCE_SLICE_NAME]: ITBalanceInitialState;
}) => state[T_BALANCE_SLICE_NAME];
export default tBalanceSlice.reducer;
