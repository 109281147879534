import { Fragment } from 'react';
import { Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import { TBalanceProductsHistory } from '../../types/tBalance.types';
import { formatDate, formatUTCtoLocaleDateTime } from '../../utils/helper';

interface Props {
  data?: TBalanceProductsHistory;
}

const PDFTemplate = ({ data }: Props) => {
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: 'column',
    },

    spaceBetween: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#3E3E3E',
    },

    justifyCenter: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#3E3E3E',
    },

    marginBottom: {
      marginBottom: 15,
    },

    titleContainer: { flexDirection: 'row', marginTop: 24, marginBottom: 24 },

    title: { fontSize: 20, textAlign: 'center', fontWeight: 'bold' },

    addressTitle: { fontSize: 11, fontWeight: 'bold', color: '#000000' },

    theader: {
      color: '#ffffff',
      fontSize: 11,
      fontWeight: '400',
      paddingTop: 4,
      paddingLeft: 7,
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      height: 30,
      backgroundColor: '#6659a2',
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    tbody: {
      fontSize: 12,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 8,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      borderColor: 'whitesmoke',
      backgroundColor: '#f5f5f5',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
  });

  const Title = () => (
    <View style={styles.titleContainer}>
      <View style={styles.justifyCenter}>
        <Text style={styles.title}>{data?.tProduct?.name}</Text>
      </View>
    </View>
  );

  const Detail = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.marginBottom}>
            First Name:{' '}
            <Text style={styles.addressTitle}>
              {data?.lastEditId.firstName}
            </Text>
          </Text>
          <Text style={styles.marginBottom}>
            Last Name:{' '}
            <Text style={styles.addressTitle}>{data?.lastEditId.lastName}</Text>
          </Text>
          <Text style={styles.marginBottom}>
            Email:{' '}
            <Text style={styles.addressTitle}>{data?.lastEditId.email}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.marginBottom}>
            ID: <Text style={styles.addressTitle}>{data?.productUniqueId}</Text>
          </Text>
          <Text style={styles.marginBottom}>
            Record Date:{' '}
            <Text style={styles.addressTitle}>
              {data?.purchasedDate
                ? formatUTCtoLocaleDateTime(data?.purchasedDate)
                : ''}
            </Text>
          </Text>
          {data?.lastEditDate ? (
            <Text style={styles.marginBottom}>
              Update Date:{' '}
              <Text style={styles.addressTitle}>
                {formatUTCtoLocaleDateTime(data?.lastEditDate)}
              </Text>
            </Text>
          ) : null}
        </View>
      </View>
    </View>
  );

  const TableHead = () => (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        color: '#000000',
      }}
    >
      <View style={styles.theader}>
        <Text>Product Name</Text>
      </View>
      <View style={styles.theader}>
        <Text>Amount</Text>
      </View>
      <View style={styles.theader}>
        <Text>Leverage</Text>
      </View>
    </View>
  );

  const TableBody = () => (
    <Fragment>
      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={styles.tbody}>
          <Text>{data?.tProduct?.name}</Text>
        </View>
        <View style={styles.tbody}>
          <Text>TG {data?.tProduct?.price} </Text>
        </View>
        <View style={styles.tbody}>
          <Text>TG {data?.tProduct?.returnAmount}</Text>
        </View>
      </View>
    </Fragment>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Title />
        <Detail />
        <TableHead />
        <TableBody />
      </Page>
    </Document>
  );
};

export default PDFTemplate;
