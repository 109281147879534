import { useSelector } from 'react-redux';

import { selectTBalance } from '../../../store/TBalance/TBalanceSlice';
import GlobalSkeleton from '../../../components/Global/GlobalSkeleton';

const TokensBar = () => {
  const { TBalanceSwappedTotal, isTBalanceSwappedTotalLoading } =
    useSelector(selectTBalance);

  return (
    <div className="grid grid-cols-2 gap-2 xs:gap-4 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
      {isTBalanceSwappedTotalLoading && !TBalanceSwappedTotal && (
        <>
          {Array(7)
            .fill('')
            .map((_, i) => (
              <div key={i}>
                <GlobalSkeleton
                  animationValue="wave"
                  variant="text"
                  sx={{ fontSize: '40px' }}
                  width={'100%'}
                />
              </div>
            ))}
        </>
      )}
      {TBalanceSwappedTotal &&
        TBalanceSwappedTotal?.length > 0 &&
        TBalanceSwappedTotal?.map((token, index) => (
          <div
            key={index}
            className="w-full flex flex-col gap-1 items-center justify-center rounded-md text-white bg-gradient-to-r from-gray-800 via-[#6659a2] to-gray-800 text-[10px] xs:text-sm py-2"
          >
            <div>
              {token.fromTokenName}{' '}
              <span className="text-[10px] xs:text-xs">to</span>{' '}
              {token.toTokenName}
            </div>
            <div>
              <span className="text-[10px] xs:text-xs">TG</span>{' '}
              {token.totalAmount}
            </div>
          </div>
        ))}
    </div>
  );
};

export default TokensBar;
