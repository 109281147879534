import { useState, useRef, useEffect } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../../../../SVGIcons/arrowDown.svg';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  placeholder: string;
  onChange?: (option: Option) => void; // Adjusted to expect Option, not an event
  selectedOption?: any;
  btnStyle?: string;
  selectedValue?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const CloudKSelect = ({
  options,
  placeholder = 'Select an option',
  onChange,
  selectedOption,
  btnStyle = '',
  selectedValue,
  disabled = false,
  size,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown open/close
  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (option: Option) => {
    if (onChange) {
      onChange(option); // Pass the selected option to the parent handler
    }
    // setSelectedOption(option);
    setIsOpen(false);
  };
  const selectedValueOption = options.find((op) => op.value === selectedValue);
  const { t } = useTranslation();
  return (
    <div ref={dropdownRef} className="relative inline-block min-w-auto">
      <button
        onClick={toggleDropdown}
        disabled={disabled}
        className={`flex gap-x-5 justify-between items-center w-full px-3 py-1.5 text-gray-700 bg-white border border-blueGrey rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none ${btnStyle}
        ${disabled ? 'opacity-60' : ''}
        ${size === 'sm' && 'text-xs !py-1 !px-2'}`}
      >
        <span className="text-sm text-gray-500">{placeholder}</span>
        <span className="flex items-center gap-2 text-sm text-secondaryBlack">
          {selectedOption?.label ||
            (selectedValueOption?.label &&
              t(
                `dynamic.wallet.withdraw.status.${selectedValueOption?.label}`,
                {
                  defaultValue: selectedValueOption?.label,
                }
              )) ||
            t('dynamic.wallet.withdraw.status.All')}
          <ArrowDownIcon
            className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'} h-3 `}
          />
        </span>
      </button>

      {isOpen && (
        <div
          className={`absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-[6.5rem] overflow-y-auto overflow-x-hidden min-w-fit w-full`}
        >
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className="block w-full px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 min-w-max"
            >
              {t(`dynamic.wallet.withdraw.status.${option.label}`, {
                defaultValue: option.label,
              })}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CloudKSelect;
