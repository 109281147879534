import React, { useEffect, useState } from 'react';
import LeaderBoardFilterTab from './LeaderBoardFilterTab';
import { Top3Header1, Top3Header2, Top3Header3 } from '../../../images/svgs';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLeaderBoard,
  setTopThreeLeaders,
} from '../../../store/leadersboard/leaderBoardSlice';
import { useLeaderBoardActions } from '../../../store/leadersboard/leaderBoardAction';
import { profileImg } from '../../../images';
import { TopThreeLoader } from './LoadingShimmer';
import ImageWithFallback from '../../Defi/Cloudk/Common/ImageWithFallback/ImageWithFallback';
import { useTranslation } from 'react-i18next';
import NoData from './NoData';

const TopThreeLeaders = ({
  selectedMainTab,
  selectedNestedTab,
}: {
  selectedMainTab: string;
  selectedNestedTab: string;
}) => {
  const dispatch = useDispatch();
  const [selectedTop3Filter, setSelectedTop3Filter] = useState('day');
  const { topThreeLeaders, topThreeLeadersLoading } =
    useSelector(selectLeaderBoard);
  const { getTopThreeLeaders } = useLeaderBoardActions();
  useEffect(() => {
    dispatch(setTopThreeLeaders(null));
    setSelectedTop3Filter('day');
    fetchTopThreeLeaders('day');
  }, [selectedMainTab, selectedNestedTab]);
  const fetchTopThreeLeaders = (timeline: string) => {
    const filter =
      selectedMainTab === 'rewardSpecificLeaders'
        ? { filter: timeline, type: selectedNestedTab, limit: 3, page: 1 }
        : { filter: timeline, limit: 3, page: 1 };
    getTopThreeLeaders(filter);
  };
  const bgImages = [
    `from-[#FAC525] via-[#FFCF3F] to-[#FFEC95]`,
    `from-primary via-primaryLight  to-primaryExtraLight`,
    `from-[#FFAD53] via-[#FFAC52] to-[#FCD9B3]`,
  ];
  return (
    <div className="w-full xl:w-1/2 bg-white rounded-lg border border-borderBlue flex flex-col overflow-hidden">
      <div className="w-full flex justify-between items-center bg-gradient-to-r from-primary to-primaryLight text-white py-2 px-2 sm:px-3 gap-2">
        <span className="font-medium text-xl w-[50%]">Top 3</span>
        <LeaderBoardFilterTab
          disabled={topThreeLeadersLoading}
          layoutID={'top-3'}
          onFilterChange={(event: string): void => {
            setSelectedTop3Filter(event);
            fetchTopThreeLeaders(event);
          }}
          selectedFilter={selectedTop3Filter}
          setSelectedFilter={setSelectedTop3Filter}
          hoverTextColor={true}
          filterType={[
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
            { label: 'Month', value: 'month' },
          ]}
          loader={topThreeLeadersLoading}
        />
      </div>
      <div
        className={`py-2 px-4 w-full ${topThreeLeadersLoading ? 'h-[800px]' : 'h-[700px]'} screen510:h-[330px] flex flex-col screen510:flex-row justify-center items-center gap-1`}
      >
        {topThreeLeadersLoading
          ? Array.from({ length: 3 }).map((_, i) => (
            <TopThreeLoader rank={i === 1 ? 1 : null} />
          ))
          : topThreeLeaders?.list && topThreeLeaders.list.length > 0 ?
            ((() => {
              const leaders = [...topThreeLeaders!.list];
              if (leaders.length > 1 && leaders[0]?.rank === 1) {
                [leaders[0], leaders[1]] = [leaders[1], leaders[0]];
              }
              return leaders.map((leader, index) => (
                <LeaderCard
                  key={leader?.user?.blockchainId}
                  name={leader?.user?.username}
                  bid={leader?.user?.blockchainId}
                  rank={leader?.rank}
                  reward={leader?.tokenAmount}
                  image={leader?.profilePicture || profileImg}
                  bgImage={bgImages[leader?.rank - 1]}
                  tokenName={leader?.tokenName}
                />
              ));
            })())
            : (
              <NoData />
            )}
      </div>
    </div>
  );
};

const LeaderCard = ({
  name,
  bid,
  rank,
  reward,
  image,
  bgImage,
  tokenName,
}: {
  name: string;
  bid: string;
  rank: number;
  reward: number;
  image: string | null;
  bgImage: React.ReactNode;
  tokenName: string;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-col rounded-2xl overflow-hidden w-52 relative bg-white shadow-top3Shadow ${rank === 1 ? 'scale-[1.1] z-10' : ''}`}
    >
      <div
        className={`w-full h-12 screen510:h-16 overflow-hidden bg-gradient-to-tr ${bgImage}`}
      />
      <div className="absolute top-4 right-6 font-bold flex items-start text-xl italic text-white gap-0.5">
        {rank}{' '}
        <span className="text-sm sm:text-md">
          {rank === 1 ? 'st' : rank === 2 ? 'nd' : 'rd'}
        </span>
      </div>

      <div className="w-20 h-20 absolute left-4 top-3 screen510:top-10 border-4 border-white rounded-full overflow-hidden shadow-md">
        {/* <img src={image} alt="Image" className="object-cover w-full h-full" /> */}
        <ImageWithFallback
          src={image}
          alt="Image"
          className="object-cover w-full h-full"
          fallbackSrc={profileImg}
        />
      </div>
      <div className="flex flex-col gap-2 p-4 pt-12 screen510:pt-16">
        <div className="flex flex-col">
          <span className="text-md font-medium">{name}</span>
          <span className="text-xs text-secondaryGrey font-normal">
            ID: {bid}
          </span>
        </div>
        <hr />

        <div className="w-full flex flex-col items-center justify-center gap-1">
          <span className="text-xs text-primary font-medium">
            {t("MyCommunity.totalRewards")}
          </span>
          <span className="font-medium text-sm sm:text-md 2xl:text-lg max-w-full break-words">
            {Number(reward).toFixed(3)}
            <span className="text-xs ml-1">{tokenName}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopThreeLeaders;
