import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import useSocketIO from '../hooks/socketIO/useSocketIO';
import useUserCheck from '../hooks/useUserCheck';
import { selectDrawer } from '../store/drawer/drawerSlice';
import { useGeneralActions } from '../store/general/generalActions';
import {
  selectNewsletterModal,
  setNewsletterModal,
} from '../store/newsLetter/newsLetterSlice';
import { selectSocketIO } from '../store/socketIO/socketIOSlice';
import { useUserActions } from '../store/user/userActions';
import { checkPercentage } from '../store/user/userSlice';
import { PLATFORM_NAME, ENV_VARS, FEATURES } from '../utils/constants';
import { PAGES } from '../utils/routers';
import ScrollToTop from '../utils/scrollToTop';
import BottomBar from './common/BottomBar';
import Navbar from './common/Navbar';
import GlobalModal from './Global/GlobalModal';
import PrelineInit from './PrelineInit';
import { ReactComponent as Spinner } from '../SVGIcons/spinner.svg';
import { useCloudActions } from '../store/Cloudk2/cloudk2Actions';
import { selectConvertedPriceLoading } from '../store/general/generalSlice';
import TrustPilotSuccessModal from './Modal/TrustPilotSuccessModal';
import Sidebar from './common/Sidebar';
import { useWallekActions } from '../store/wallek/walleKAction';
import { selectWallekPoolSlice } from '../store/wallek/walleKSlice';
import TickerBanner from './distributorMembership/TickerBanner';
import useHotjar from '../hooks/useHotjar';
import { useTranslation } from 'react-i18next';

export default function Layout() {
  const { user } = useUserCheck();

  if (!user) {
    return <Navigate to={PAGES.LOGIN} replace />;
  }

  return <MainLayout />;
}

const MainLayout: React.FC = () => {
  // const peekAnimation = {
  //   hidden: { x: '-100%' },
  //   peek: { x: '0%' },
  //   partial: { x: '10%' },
  // };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const toggleDrawer = useSelector(selectDrawer);
  const { socket } = useSelector(selectSocketIO);
  const { user, checkLayoutValidation, fetchUser } = useUserCheck();
  const { GetConvertedPrice } = useGeneralActions();
  const [signoutLoading, setSignoutLoading] = useState(false);
  const { updateUserData, userLogout, getUserInfo } = useUserActions();
  const isNewsletterModal = useSelector(selectNewsletterModal);
  const { getBurnUserStatus } = useCloudActions();
  const { getWallekStakeDetails } = useWallekActions();
  const { wallekStakedList, wallekStakedLoading } = useSelector(
    selectWallekPoolSlice
  );

  const isConvertedPriceLoading = useSelector(selectConvertedPriceLoading);

  const {
    establishSocketConnection: establishSocketConnectionHomnifi,
    disconnectSocket: disconnectSocketConnectionHomnifi,
  } = useSocketIO(ENV_VARS.SOCKET_BASE_URL);

  // const {
  //   establishSocketConnection: establishSocketConnectionCloudk,
  //   disconnectSocket: disconnectSocketConnectionCloudk,
  // } = useSocketIO(CLOUDK_SOCKET_BASE_URL);

  const [isHomnifiConnected, setIsHomnifiConnected] = useState(false);
  const [isCloudkConnected, setIsCloudkConnected] = useState(false);
  const [openTPSuccessModal, setOpenTPSuccessModal] = useState(false);

  const handleLogout = async () => {
    setSignoutLoading(true);
    await userLogout();
    disconnectSocketConnectionHomnifi();
    // disconnectSocketConnectionCloudk();
    setSignoutLoading(false);
  };

  const closeTPModal = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('tpReviewed');
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    setOpenTPSuccessModal(false);
  };
  const fetchBurnStatusApi = async () => {
    try {
      await getBurnUserStatus();
    } catch (error) {
      console.error('Error fetching burn status:', error);
    }
  };

  useEffect(() => {
    if (!socket) {
      if (!isHomnifiConnected) {
        establishSocketConnectionHomnifi();
        setIsHomnifiConnected(true);
      }
      // if (!isCloudkConnected) {
      //   establishSocketConnectionCloudk();
      //   setIsCloudkConnected(true);
      // }
    }
  }, [socket, isHomnifiConnected, isCloudkConnected]);

  useEffect(() => {
    checkLayoutValidation();
  }, [user]);

  useEffect(() => {
    fetchBurnStatusApi();
    fetchUser();
    getUserInfo();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(checkPercentage());

      if (!isConvertedPriceLoading) {
        GetConvertedPrice();
      }

      if (
        (!localStorage.getItem('homnifi-username') ||
          !localStorage.getItem('homnifi-dateJoined')) &&
        !user?.isImpersonateMode
      ) {
        updateUserData(user);
      }
    }
  }, [user]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('tpReviewed') === 'true') {
      setOpenTPSuccessModal(true);
    } else {
      setOpenTPSuccessModal(false);
    }
  });

  useLayoutEffect(() => {
    if (!wallekStakedLoading && !wallekStakedList) {
      getWallekStakeDetails();
    }
  }, []);

  const noPaddingPaths = [
    '/',
    '/global-pool/country-pools',
    '/community/super-node',
    '/t-balance',
    '/defi-360/cloudk',
  ];

  return (
    <>
      <PrelineInit>
        <section
          className={`relative ${user?.isImpersonateMode ? 'border-4 border-primary' : ''} bg-pageBackground`}
        >
          {user?.isImpersonateMode && (
            <div className="text-center p-2 bg-primary text-white sticky top-0 z-[1000] flex items-center justify-center ">
              {t('layout.youAreImpersonateMode')}
              <button
                className="bg-white px-4 py-0.5 text-sm rounded-md ms-3 text-primary font-medium"
                onClick={handleLogout}
              >
                {signoutLoading ? (
                  <Spinner className="h-5 animate-spin" />
                ) : (
                  t('general.stop')
                )}
              </button>
            </div>
          )}

          <Navbar drawer={toggleDrawer} />
          <div className="flex justify-center w-full">
            <Sidebar drawer={toggleDrawer} />
            <div className="relative flex flex-col w-full min-h-screen overflow-hidden">
              {FEATURES.showDistributorMembership &&
                location.pathname !== '/wallet/withdraw' &&
                user?.subscriptionType !== 'DISTRIBUTOR' && <TickerBanner />}
              <div
                className={`${noPaddingPaths.includes(location.pathname) ? '' : 'p-4'} pt-16 screen430:pt-12`}
              >
                <ScrollToTop />
                <Outlet />
              </div>
            </div>
          </div>
          <BottomBar />
          <GlobalModal
            isOpen={isNewsletterModal}
            onClose={() => {
              // dispatch(setLoginPopup(false));
              dispatch(setNewsletterModal(false));
              // setIsLogInPopClosed(prev => !prev)
            }}
            title={`Announcement: ${PLATFORM_NAME} Platform is Now Live!`}
            childrenClass="p-0"
            size="2xl"
          >
            {/* {isNewsletterModalId === "1" ? <RenderInstructionModal /> : <RenderModalContent />} */}
          </GlobalModal>
          <TrustPilotSuccessModal
            isOpen={openTPSuccessModal}
            onClose={closeTPModal}
          />
        </section>
      </PrelineInit>
    </>
  );
};
