import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { tBalanceBgBlur, tBalanceBgPattern } from '../../../images/wallets';
import GlobalHeader from '../../../components/Global/GlobalHeader';
import CustomSelect from '../../../components/CustomSelect';
import { selectTBalance } from '../../../store/TBalance/TBalanceSlice';

const Banner = () => {
  const navigate = useNavigate();

  const { TBalance, TBalanceWalletList } = useSelector(selectTBalance);

  const handleClick = (tokenName: string) => {
    const filteredTokens = TBalanceWalletList?.filter(
      (token: any) => token.walletBalance.symbol === tokenName
    );
    if (filteredTokens && filteredTokens?.[0].walletBalance.balance > 0) {
      navigate(`/wallet/swap?tokenName=${tokenName}`);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-between w-full h-auto px-6 py-4 overflow-hidden bg-black md:flex-row md:h-28">
      <div className="z-10 flex items-center">
        <GlobalHeader title={'T-Balance'} titleStyle="text-white" />
      </div>
      <div className="z-10 flex flex-col items-center gap-4 text-2xl text-white md:gap-8 md:flex-row ">
        <div className="flex items-center justify-center w-full gap-4">
          <span className="text-sm italic font-normal text-nowrap">
            Current Available Balance:
          </span>
          <span className="w-full font-medium">
            <span className="text-base ">TG</span> {TBalance}
          </span>
        </div>

        <CustomSelect
          options={TBalanceWalletList || []}
          placeholder="Topup"
          onChange={(value) => handleClick(value)}
        />
      </div>
      <div className="absolute z-0 w-full -bottom-48 opacity-60">
        <img src={tBalanceBgBlur} alt="Background Image" className="w-full" />
      </div>
      <div className="absolute left-0 z-0 -top-32 opacity-60">
        <img src={tBalanceBgPattern} alt="Background Image" className="w-96" />
      </div>
    </div>
  );
};

export default Banner;
