/* eslint-disable react-hooks/exhaustive-deps */
import React, { JSX, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { selectRewardSlice } from '../../store/Cloudk2/rewardCalculatorSlice';
import { useWalletDataAction } from '../../store/walletData/walletDataAction';
import {
  selectWalletData,
  TokenData,
  TokenInfo,
  TokenInfoArray,
} from '../../store/walletData/walletDataSlice';
import LatestWalletHistory from './LatestWalletHistory';
import TotalBalanceComp from './TotalBalanceComp';
import WalletAllTokens from './WalletAllTokens';
import WalletHeadingSection from './WalletHeadingSection';
import WalletLineChartComp from './WalletLineChartComp';
import WalletPieChartGraph from './WalletPieChartGraph';
import { truncatedValue } from '../../utils/utils';

export default function Wallets(): JSX.Element {
  const {
    getWalletDataWalletsList,
    getWalletGraphData,
    getWalletDataDepositHistory,
    getWalletDataCombinedSwapHistory,
    getWalletDataWithdrawAndTransferHistory,
  } = useWalletDataAction();
  const {
    walletList,
    loading,
    graphLoading,
    walletGraphData,
    getWalletDataDepositHistoryLoading,
    withdrawAndTransferHistory,
    setWalletDataWithdrawAndTransferHistoryLoading,
    depositHistory,
    getWalletDataSwapHistoryLoading,
    combinedSwapHistory,
  } = useSelector(selectWalletData);

  const { LYK } = useSelector(selectRewardSlice);
  const { selectedOptionForLineGraph } = useSelector(selectWalletData);
  const [selected, setSelected] = useState<string>(selectedOptionForLineGraph);
  const pageHasBeenRendered = useRef(false);

  const walletTokenAndBalanceAndColor: TokenInfoArray = walletList?.wallets
    ? walletList?.wallets?.map((item: TokenData) => ({
        _id: item.id,
        token: item.token.name,
        balance: item.tokenBalance,
        color: item.token.color,
        borderColor: item.token.color,
        iconUrl: item.token.iconUrl,
        trxSettings: item.token.trxSettings,
        totalStaked: item?.totalStaked,
        totalAvailableToStake: item?.totalAvailableToStake,
        claimableReward: item?.claimableReward,
        dollarBalance: item?.dollarBalance,
        tokenSymbol: item.token.symbol,
        valueType: item?.token?.valueType,
        showZeroBalance: item?.token?.showZeroBalance,
        conversionType: item?.token?.conversionType,
        customRate: item?.token?.customRate,
      }))
    : [
        {
          token: 'LYK-W',
          balance: 0.0,
          color: '#F7931A',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/lyk.png',
          trxSettings: {
            depositEnabled: false,
            swapEnabled: true,
            withdrawEnabled: true,
          },
          valueType: 'LYK',
        },
        {
          token: 'mLYK',
          balance: 0.0,
          color: '#3B89FF',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/mlyk.png',
          trxSettings: {
            depositEnabled: false,
            swapEnabled: true,
            withdrawEnabled: true,
          },
          valueType: 'LYK',
        },
        {
          token: 'sLYK',
          balance: 0.0,
          color: '#53AE94',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/slyk.png',
          trxSettings: {
            depositEnabled: true,
            swapEnabled: false,
            withdrawEnabled: false,
          },
          valueType: 'LYK',
        },
        {
          token: 'LYK',
          balance: 0.0,
          color: '#F7931C',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/lyk.png',
          trxSettings: {
            depositEnabled: true,
            swapEnabled: false,
            withdrawEnabled: false,
          },
          valueType: 'LYK',
        },
      ];

  useEffect((): void => {
    window.scrollTo(0, 0);
  }, []);

  useEffect((): void => {
    if (!loading && !walletList) {
      getWalletDataWalletsList();
    }

    if (
      !setWalletDataWithdrawAndTransferHistoryLoading &&
      !withdrawAndTransferHistory
    ) {
      getWalletDataWithdrawAndTransferHistory({
        page: 1,
        limit: 10,
      });
    }

    if (!getWalletDataDepositHistoryLoading && !depositHistory) {
      getWalletDataDepositHistory({
        page: 1,
        limit: 10,
      });
    }
    if (!getWalletDataSwapHistoryLoading && !combinedSwapHistory) {
      getWalletDataCombinedSwapHistory({
        page: 1,
        limit: 10,
      });
    }
  }, []);

  useEffect((): void => {
    if (!graphLoading && (!walletGraphData || pageHasBeenRendered.current)) {
      getWalletGraphData(selected);
    }
    pageHasBeenRendered.current = true;
  }, [selected]);

  return (
    <div className="flex flex-col gap-4">
      <WalletHeadingSection />
      <div className="grid grid-cols-12 gap-4 border-box ">
        <TotalBalanceComp
          totalBalance={truncatedValue(walletList?.totalDollarValue)}
        />
        <WalletLineChartComp
          totalBalance={truncatedValue(walletList?.totalDollarValue)}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className="grid grid-cols-12 gap-4 ">
        <WalletPieChartGraph />
        <WalletAllTokens
          walletTokenAndBalanceAndColor={walletTokenAndBalanceAndColor.filter(
            (item: TokenInfo): boolean =>
              (item.balance === 0 && item?.showZeroBalance) || item.balance > 0 || item.balance < 0
          )}
          totalBalance={truncatedValue(walletList?.totalDollarValue)}
          convertionRate={LYK}
        />
      </div>
      <LatestWalletHistory />
    </div>
  );
}
