import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Products from './_components/Products';
import TransactionHistory from './_components/TransactionHistory';
import OrderDetailModal from './_components/OrderDetailModal';
import { selectTBalance } from '../../store/TBalance/TBalanceSlice';
import { useTBalanceActions } from '../../store/TBalance/TBalanceActions';
import Note from './_components/Note';
import Banner from './_components/Banner';
import TokensBar from './_components/TokensBar';

const TBalanace = () => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [type, setType] = useState('CREATE');

  const {
    isTBalanceLoading,
    TBalanceProducts,
    TBalanceWalletList,
    TBalanceSwappedTotal,
    TBalanceProductsHistory,
    TBalance: TBalanceBalance,
    isTBalanceProductsLoading,
    isTBalanceWalletListLoading,
    isTBalanceSwappedTotalLoading,
    isTBalanceProductsHistoryLoading,
  } = useSelector(selectTBalance);

  const {
    getTBalance,
    getTBalanceProducts,
    getTBalanceWalletList,
    getTBalanceSwappedTotal,
    getTBalanceProductsHistory,
  } = useTBalanceActions();

  const openOrderDetailModal = (product: any | null = null) => {
    setSelectedProduct(product);
    setOpen(!open);
  };

  const fetchAPi = () => {
    if (!TBalanceProducts && !isTBalanceProductsLoading) {
      getTBalanceProducts({
        limit: 10,
        page: 1,
      });
    }
    if (!isTBalanceProductsHistoryLoading && !TBalanceProductsHistory) {
      getTBalanceProductsHistory({
        limit: 10,
        page: 1,
      });
    }
    if (!isTBalanceLoading && !TBalanceBalance) {
      getTBalance();
    }
    if (!isTBalanceWalletListLoading && !TBalanceWalletList) {
      getTBalanceWalletList();
    }
    if (!isTBalanceSwappedTotalLoading && !TBalanceSwappedTotal) {
      getTBalanceSwappedTotal();
    }
  };

  useEffect(() => {
    fetchAPi();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Banner />
      <div className="flex flex-col gap-4 px-4 pb-4">
        <TokensBar />
        <Products openOrderDetailModal={openOrderDetailModal} />
        <TransactionHistory
          setType={setType}
          openOrderDetailModal={openOrderDetailModal}
        />
        <Note />
      </div>
      {open && (
        <OrderDetailModal
          autoHeight={'h-auto'}
          onClose={() => setOpen(false)}
          open={open}
          title={''}
          type={type}
          setType={setType}
          editData={selectedProduct}
        />
      )}
    </div>
  );
};

export default TBalanace;
