//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  expand: boolean;
  getSuperNodeTreeChildrenLoading: boolean;
  supernodeTreeChildren: {
    user: {
      _id: string;
      username: string;
      depth: number;
      email: string;
      profilePicture: string | null;
      blockchainId: string;
      firstLineNode: number;
      dateJoined: string;
      isMembership: boolean;
      isBaseReferralActive: boolean;
      isBuilderGenerationActive: boolean;
      uplineId: {
        _id: string;
        email: string;
        blockchainId: string;
        username: string;
        firstName: string;
        lastName: string;
        isSupernodeActive: boolean;
        profilePicture: string | null;
        lastLogin: string;
        createdAt: string;
        updatedAt: string;
        dateJoined: string;
        isBuilderReferralEnabled: boolean;
        isBaseReferralEnabled: boolean;
        isBuilderGeneralEnabled: boolean;
        isBuilderGenerationEnabled: boolean;
        rewardMultiplier: 1;
        isBaseReferralActive: boolean;
        isBuilderGenerationActive: boolean;
        isUserEverPurchasedMachine: boolean;
        products: string[];
        firstLineBaseReferral: number;
        firstLineBuilderGenerational: number;
        totalBaseReferral: number;
        totalBuilderGenarational: number;
        firstLineNode: number;
        totalNode: number;
        uplineBID: string;
        uplineId: string;
        isMembership: boolean;
        referralCode: string;
        depth: number;
        totalUserwithMachine: number;
        totalUserwithMembership: number;
        totalUserwithoutMachine: number;
        totalUserwithoutMembership: number;
        trustpilotReviewed: boolean;
      };
      myProduction: number;
      children: {
        _id: string;
        email: string;
        blockchainId: string;
        username: string;
        profilePicture: null;
        dateJoined: string;
        isBaseReferralActive: boolean;
        isBuilderGenerationActive: boolean;
        firstLineNode: number;
        uplineId: {
          _id: string;
          email: string;
          username: string;
          firstName: string;
          lastName: string;
          profilePicture: string | null;
          builderRefferalBonusEligibility: boolean;
          isSupernodeActive: boolean;
          isBaseReferralActive: boolean;
          isBuilderGenerationActive: boolean;
          firstLineNode: number;
          totalNode: number;
          uplineBID: string;
          uplineId: string;
          isMembership: boolean;
          depth: number;
        };
        isMembership: boolean;
        depth: number;
        myProduction: number;
      }[];
    };
    totalChildren: number;
    page: number;
    limit: number;
    totalPages: number;
  } | null;
  personalAndMachineDetails: {
    machineList: any[];
    personalReWard: {
      rewardsFromStaking: number;
      totalPersonalStaking: number;
    };
  } | null;
  userDetails: {
    _id: string;
    blockchainId: string;
    date_joined: string;
    document_country: string | null;
    email: string;
    first_name: string | null;
    last_name: string | null;
    isBaseReferralActive: boolean;
    isBuilderGenerationActive: boolean;
    membership_expiry: string;
    referral_code: string;
    username: string;
    profile_picture: string;
  } | null;
  getSupernodeTreeChildrenDetailsLoading: boolean;
  getSupernodeTreeChildrenDetails: any;
  supernodeTreeParentInfo: any;
  supernodeTreeSearch: any;
  getSuperNodeTreeSearchLoading: boolean;
  getPersonalAndMachineDetailsLoading: boolean;
  getMachineStakeDetailsLoading: boolean;
}

const initialState: State = {
  expand: false,
  getSuperNodeTreeChildrenLoading: false,
  supernodeTreeChildren: null,
  getSupernodeTreeChildrenDetailsLoading: false,
  getSupernodeTreeChildrenDetails: null,
  supernodeTreeParentInfo: null,
  supernodeTreeSearch: null,
  getSuperNodeTreeSearchLoading: false,
  getPersonalAndMachineDetailsLoading: false,
  personalAndMachineDetails: null,
  getMachineStakeDetailsLoading: false,
  userDetails: null,
};

const MY_TREE_SLICE_NAME = 'myTree2';

const myTreeSlice = createSlice({
  name: MY_TREE_SLICE_NAME,
  initialState,
  reducers: {
    setMyTreeSlice: (state, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },

    setTreeExpand: (state, action: PayloadAction<boolean>) => {
      return { ...state, expand: action.payload };
    },
  },
});

export const { setTreeExpand, setMyTreeSlice } = myTreeSlice.actions;

export const selectMyTreeSlice = (state: { [MY_TREE_SLICE_NAME]: State }) =>
  state[MY_TREE_SLICE_NAME];

export default myTreeSlice.reducer;
