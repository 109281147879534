import { useEffect, useState } from 'react';
import { Favourite } from '../../images/svgs';
import GlobalButton from '../Global/GlobalButton';
import HoverBorderAnimation from '../HoverBorderAnimation';
import { useTranslation } from 'react-i18next';

interface ProductCardProps {
  onClick?: (id: string, isFav: boolean) => void;
  id: string;
  comingSoon?: boolean;
  isPlatorms?: boolean;
  category?: string;
  version?: string;
  logo: string;
  favorite: boolean;
  name: string;
  numberOfUsers?: number;
  loginClick: (id: string) => void;
  loginLoading: boolean;
  loading?: boolean;
}

const ProductCard = ({
  id,
  onClick,
  comingSoon,
  category,
  version,
  logo,
  favorite,
  name,
  numberOfUsers,
  isPlatorms,
  loginClick,
  loginLoading,
  loading,
}: ProductCardProps) => {
  const [isFav, setIsFav] = useState(favorite);
  const { t } = useTranslation();

  const handleFavoriteClick = () => {
    const newFavStatus = !isFav;
    setIsFav(newFavStatus);
    if (onClick) {
      onClick(id, newFavStatus);
    }
  };

  useEffect(() => {
    setIsFav(favorite);
  }, [favorite]);

  return (
    <HoverBorderAnimation className="relative bg-[#FBFBFB] flex flex-col rounded-lg border border-[#FBFBFB] hover:bg-white hover:shadow-lg radius-5px transition-all ease-in-out duration-300 cursor-pointer select-none">
      <div className="h-4">
        {isPlatorms && (
          <div className="w-full text-xs text-center bg-gradient-to-r from-[#FACA6D] to-[#FFA800] rounded-t-lg text-white py-1 uppercase">
            {t('externnalApps.banner.sec1.featured')}
          </div>
        )}
      </div>

      <div className={`relative pt-10 pb-4 `}>
        {/* <div className="absolute left-4 top-4 ">
          <Tooltips
            content={
              <div className="flex flex-col gap-2 p-3 bg-white shadow-md rounded-lg w-72">
                <div className="flex gap-2">
                  <div className="font-medium">About {name}</div>
                  <span className="bg-secondaryblue/5 text-secondaryblue px-2 py-1 text-xs rounded">
                    Web 2.0
                  </span>
                </div>
                <div className="text-xs font-normal">
                  Lorem ipsum dolor sit amet consectetur. Nulla euismod mi nisi
                  consectetur at ac velit sit.
                </div>
                <div className="text-xs font-normal">
                  Scelerisque euismod lorem penatibus bibendum nunc nibh
                  placerat. Elementum nunc non imperdiet tellus sit arcu.
                </div>
                <div className="text-xs font-normal">
                  Eget egestas nullam ullamcorper aliquam viverra sed.
                </div>
              </div>
            }
            placement="right"
          >
            <InfoIcon className="w-5 h-5" />
          </Tooltips>
        </div> */}
        <div onClick={handleFavoriteClick} className="absolute top-4 right-4">
          <Favourite
            className={`w-5 h-5 ${isFav ? 'text-red-700' : 'text-white'} cursor-pointer`}
          />
        </div>
        <div className="gap-4 flex-col flex text-center items-center">
          <div className="bg-white p-3 w-20 h-20 overflow-hidden border rounded-full">
            <img
              src={logo}
              className="w-full h-full object-cover rounded-full"
              alt={logo}
            />
          </div>

          {(category || version) && (
            <div className="flex gap-2">
              {category && (
                <p className="bg-[#FF2727]/20 text-[#FF2727] px-2 py-1 text-xs rounded">
                  {category}
                </p>
              )}
              {version && (
                <p className="bg-secondaryblue/5 text-secondaryblue px-2 py-1 text-xs rounded">
                  {version}
                </p>
              )}
            </div>
          )}
          {name && (
            <p className="text-defaultBlack font-semibold flex justify-center items-center self-center">
              {name}
            </p>
          )}
          <div>
            <GlobalButton
              loading={loginLoading}
              onClick={() => loginClick(id)}
              disabled={comingSoon}
              type="button"
              text={
                comingSoon
                  ? t('externnalApps.banner.sec1.csoon')
                  : t('general.login')
              }
              classNames="!text-xs !font-light !px-4 !py-1"
            />
          </div>
        </div>
      </div>
    </HoverBorderAnimation>
  );
};

export default ProductCard;
