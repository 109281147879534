import React from 'react';
import GlobalHeader from '../../../../../components/Global/GlobalHeader';
import { FileIcon } from '../../../../../images/svgs';
import { FEATURES, LINKS } from '../../../../../utils/constants';
import GlobalButton from '../../../../../components/Global/GlobalButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Heading = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pageContent = {
    route: '/defi-360/cloudk',
    title: t('defi.myCloudk.overView'),
    alert: true,
  };

  const cloudkLogout = () => {
    localStorage.removeItem('cloudkPass');
    navigate('/defi-360/cloudk/auth');
  };
  return (
    <div className=" flex justify-between gap-2 lg:flex-row flex-col">
      <div className="screen370:flex gap-4 screen370:items-center flex item-center justify-between sm:justify-normal flex-wrap sm:flex-nowrap">
        <GlobalHeader title={pageContent.title} />

        <a
          className="bg-primary rounded-md w-max px-3 !py-1 text-white sm:!text-sm !text-xs !min-h-0 flex items-center gap-2"
          href="https://docs.homnifi.com/homnifi-101/welcome-to-homnifi/defi360/welcome-to-cloudk"
          target="_blank"
          rel="noreferrer"
        >
          <FileIcon className="w-4 h-4 " /> {t('defi.myCloudk.how')}
        </a>
        {FEATURES.cloudKPassword && (
          <button
            className="px-4 py-1 bg-white border border-primary rounded-md text-primary text-xs md:text-sm"
            onClick={cloudkLogout}
          >
            Logout
          </button>
        )}
      </div>
      <div className="flex justify-end">
        <GlobalButton
          text={t('defi.myCloudk.transactionHistory')}
          type="button"
          //   disabled={disabled}
          classNames="!w-[212px] rounded-[10px] font-normal !text-base !py-[10px] px-5 min-w-max place-item-end  !bg-[#189FDA] font-medium !text-[#FFFFFF]"
          onClick={() => navigate('/defi-360/cloudk/transaction-history')}
        />
      </div>
    </div>
  );
};

export default Heading;
