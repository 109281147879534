import { toast } from 'react-toastify';
import QrCode from '../../../../components/QrCode';
import useCopyTextHook from '../../../../hooks/useCopyTextHook';
import { DepositAndStackPendingIcon } from '../../../../images/wallets';
import { useState } from 'react';
import { copy, tick } from '../../../../images/others';
import { ReactComponent as InfoIcon } from '../../../../SVGIcons/info-circle-primary.svg';
import GlobalButton from '../../../../components/Global/GlobalButton';
import ListTile from '../../../../components/common/ListTIle';
import Countdown from 'react-countdown';
import useSocketEvent from '../../../../hooks/socketIO/useSocketEvent';
import { DepositTransactionResponse } from '../../../../types/wallet.types';
import useUserCheck from '../../../../hooks/useUserCheck';
import { TransactionEvents } from '../../../../utils/allConstants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface CountDownParams {
  hours: any;
  minutes: any;
  seconds: any;
  completed: any;
}

export interface StakeRequest {
  transaction: {
    _id: string;
    requestId: string;
    network: {
      name: string;
    };
    token: {
      name: string;
      symbol: string;
    };
    depositAndStakeSettings: {
      minDisplayAmount: string;
    };
    depositAddress: string;
    expiredAt: string;
    createdAt: string;
  };
  product: {
    machine: {
      name: string;
      uniqueName: string;
    };
  }[];
}
interface Props {
  request: StakeRequest;
  onCancelRequest: () => void;
  onReset: () => void;
}

const DepositStakeRequest = ({ request, onCancelRequest, onReset }: Props) => {
  const { user } = useUserCheck();
  const { t } = useTranslation();

  const { writeClipboardText } = useCopyTextHook();
  const [isQrCodeCopied, setIsQrCodeCopied] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [deposited, setDeposited] = useState(false);

  const handleCopyClick = (text: string) => {
    writeClipboardText(text);
    toast.success('Copied!', { autoClose: 3000 });
    setIsQrCodeCopied(true);
    setTimeout(() => {
      setIsQrCodeCopied(false);
    }, 2000);
  };

  const handleCancelRequest = () => {
    setCancelLoading(true);
    onCancelRequest();
  };

  useSocketEvent<DepositTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (
        data.body.eventAction.trim() ===
        TransactionEvents.DEPOSIT_AND_STAKE_TRANSACTION_SUCCESS
      ) {
        setDeposited(true);
      }
    },
  });

  const countDownRenderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: CountDownParams) => {
    if (completed) {
      onReset();
      return <span className="text-redDanger">{t('general.expired')}</span>;
    } else if (deposited === true) {
      return (
        <span className="text-primary">
          {t('general.despositedSuccessfully')}
        </span>
      );
    } else {
      const boxClasses =
        ' rounded-lg border border-primary text-xl w-8 flex items-center justify-center';
      return (
        <div>
          <small className="block mb-1">
            {t('dynamic.wallet.deposit.alerts.yourRequestExpiredAfter')}:
          </small>
          <div className="flex items-start justify-center gap-2 text-primary">
            <div className="flex flex-col items-center gap-1">
              <span className={boxClasses}>{hours} </span>
              <span className="font-light text-primary/50">H</span>
            </div>
            <span className="pt-2">:</span>
            <div className="flex flex-col items-center gap-1">
              <span className={boxClasses}>{minutes} </span>
              <span className="font-light text-primary/50">M</span>
            </div>
            <span className="pt-2">:</span>
            <div className="flex flex-col items-center gap-1">
              <span className={boxClasses}>{seconds}</span>
              <span className="font-light text-primary/50">S</span>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <div className="flex flex-col items-center">
        <img
          src={DepositAndStackPendingIcon}
          alt="deposit pending icon"
          width={55}
          height={55}
        />
        <div className="mt-1 text-lg font-medium">
          {t('dynamic.wallet.deposit.label.activeRequest')}
        </div>
        <div>
          <Countdown
            date={request.transaction.expiredAt}
            renderer={countDownRenderer}
          />
        </div>
        <ListTile
          label={t('dynamic.wallet.deposit.label.createdDate')}
          value={moment(request.transaction?.createdAt).format(
            'DD-MM-YYYY HH:mm'
          )}
        />
        <ListTile
          label={t("dynamic.wallet.deposit.label.expiryDate")}
          value={moment(request.transaction?.expiredAt).format(
            'DD-MM-YYYY HH:mm'
          )}
        />
        <ListTile
          label={t('dynamic.wallet.deposit.label.requestId')}
          value={request.transaction?.requestId}
        />
        <ListTile
          label={t('dynamic.wallet.deposit.label.selectedWallet')}
          value={request.transaction.token?.name}
        />
        <ListTile
          label={t('dynamic.wallet.deposit.label.selectedNetwork')}
          value={request.transaction?.network?.name}
        />
        <ListTile
          label={t('dynamic.wallet.deposit.label.selectedProduct')}
          value={
            <div className="flex flex-col items-end">
              <span>{request.product?.[0].machine?.name}</span>
              Machine id: {request.product?.[0].machine?.uniqueName}
            </div>
          }
        />
        <ListTile
          label={t('dynamic.wallet.deposit.label.minDepositAmount')}
          value={request.transaction?.depositAndStakeSettings?.minDisplayAmount}
        />
        {/* <ListTile
          label="Address"
          value={request.transaction?.depositAddress}
          valueClassName="text-sm"
        /> */}
      </div>
      <div className="my-1 text-sm font-medium text-defaultBlack">
        {t('general.depositAddress')} <span className="text-redDanger">*</span>
      </div>
      <div className="mb-2 text-xs text-primary">
        {t('dynamic.wallet.deposit.label.scanQRCopyAddressDeposit')}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-4 lg:justify-start">
        <QrCode value={request.transaction.depositAddress} />
        <div className="">
          <div className="mb-2 text-sm font-medium">
            {t('general.copyAddressToDeposit')}:
          </div>
          <div className="flex items-center gap-2 p-2 text-xs border rounded-lg">
            <div className="truncate whitespace-normal max-w-60">
              {request.transaction.depositAddress}
            </div>
            <img
              src={isQrCodeCopied ? tick : copy}
              alt="icon"
              className="cursor-pointer"
              onClick={() =>
                handleCopyClick(request.transaction.depositAddress ?? '')
              }
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-3 px-4 py-2 my-3 text-xs rounded bg-primary/15 text-primary">
        <InfoIcon />
        <span>
          {t('dynamic.wallet.deposit.label.requestExpiredAndDepositToWallet')}
        </span>
      </div>
      <div className="flex justify-end">
        <GlobalButton
          text={t('dynamic.wallet.deposit.label.cancelRequest')}
          outlined
          onClick={handleCancelRequest}
          loading={cancelLoading}
        />
      </div>
    </div>
  );
};

export default DepositStakeRequest;
