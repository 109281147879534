import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'react-flag-kit';
import GlobalIconWrap from '../Global/GlobalIconWrap';
import { GreenTick, GlobalIcon } from '../../images/svgs';
import { motion } from 'framer-motion';
import OutsideClickHandler from 'react-outside-click-handler';
import { AnimatePresence } from 'framer-motion';

const LanguagePopOver = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const languageRef = React.useRef<HTMLDivElement>(null);

    const languages = [
        {
            name: (
                <div className="flex items-center gap-2 !text-md">
                    <FlagIcon code="GB" size={15} /> English
                </div>
            ),
            value: 'en-US',
        },
        {
            name: (
                <div className="flex items-center gap-2 !text-md">
                    <FlagIcon code="IT" size={15} /> Italiano
                </div>
            ),
            value: 'it',
        },
        {
            name: (
                <div className="flex items-center gap-2 !text-md">
                    <FlagIcon code="FR" size={15} /> Français
                </div>
            ),
            value: 'fr',
        },
        {
            name: (
                <div className="flex items-center gap-2 !text-md">
                    <FlagIcon code="ES" size={15} /> Español
                </div>
            ),
            value: 'es',
        },
    ];

    const handleLanguageChange = (langCode: string) => {
        i18n.changeLanguage(langCode);
        setIsOpen(false);
    };

    return (
        <div className="relative" ref={languageRef}>
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="font-medium text-blue-600"
                >
                    <GlobalIconWrap active={isOpen}>
                        <GlobalIcon className="w-4 h-4" />
                    </GlobalIconWrap>
                </button>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.2 }}
                            className="absolute top-12 right-0 w-48 bg-white border border-borderLightGray shadow-lg rounded-lg"
                        >
                            <div className="flex flex-col w-full">
                                {languages.map((lang, idx) => (
                                    <button
                                        key={idx}
                                        className={`flex items-center justify-between gap-3 w-full p-3 hover:bg-[#E3EBFB] transition-colors ${i18n.language === lang.value ? 'bg-[#E3EBFB]' : ''}`}
                                        onClick={() => handleLanguageChange(lang.value)}
                                    >
                                        {lang.name}
                                        {i18n.language === lang.value && <GreenTick />}
                                    </button>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </OutsideClickHandler>
        </div>
    );
};

export default LanguagePopOver;
