import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useSuperNodeActions } from '../../../store/superNode/superNodeActions';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice';
import { FEATURES } from '../../../utils/constants';
import { InfoIcon, Spinner } from '../../../images/svgs';
import Tooltips from '../../../components/Tooltips';
import { toFixedWithoutRoundingOff } from '../../../utils/utils';
import DueAmountModal from '../../../components/DueAmountModal';
import {
  selectDueAmount,
  selectDueAmountLoading,
} from '../../../store/general/generalSlice';
import { useGeneralActions } from '../../../store/general/generalActions';
import { selectRewardSlice } from '../../../store/Cloudk2/rewardCalculatorSlice';

export interface SuperNodeMainCardProps {
  name: string;
  value: number;
  image: any;
  previous: string;
  previousValue: number;
  token: any;
  trName: string;
}

function SuperNodeMainCard({
  name,
  value,
  image,
  previous,
  previousValue,
  token,
  trName,
}: SuperNodeMainCardProps) {
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false);

  const { claimReward, getRewardAnalytics } = useSuperNodeActions();
  const { LYK } = useSelector(selectRewardSlice);
  const { t } = useTranslation();

  const dueAmount = useSelector(selectDueAmount);

  const { GetDueBalance } = useGeneralActions();
  const isDueBalanceLoading = useSelector(selectDueAmountLoading);

  const { claimRewardLoading, superNodeTotalRewardsClaimed } =
    useSelector(selectSuperNodeSlice);

  const handleClaimButton = async (): Promise<void> => {
    const { data } = await claimReward();

    if (data.status) {
      toast.success(data.data);
      data.status && getRewardAnalytics();
      GetDueBalance();
    }

    handleReviewModalClose();
  };

  const handleReviewModalClose = () => {
    setOpenReviewModal(false);
  };

  useEffect(() => {
    if (!isDueBalanceLoading) {
      GetDueBalance();
    }
  }, []);

  return (
    <div className="min-h-full border border-[#D9E4F1] rounded-[12px] relative p-5 bg-white shadow-md overflow-hidden">
      <div className="relative z-20 flex flex-col justify-start h-full gap-4">
        <h4 className="text-lg font-normal font-rubik text-primary">
          {trName}
        </h4>

        <div className="flex flex-col justify-between w-full gap-2 xl:flex-row">
          <h2 className="font-rubik text-[20px] md:text-[30px] font-medium leading-[28px] md:leading-[30px] lg:leading-[32px] text-wrap">
            {value} {token && <span className="text-sm">{token}</span>}
          </h2>

          {name && name === 'Claimable Rewards' && (
            <div className="hidden xl:flex">
              <Claim
                claimRewardLoading={claimRewardLoading}
                value={value}
                handleClaimButton={() => {
                  if (dueAmount && dueAmount?.balance > 0) {
                    setOpenReviewModal(true);
                  } else {
                    handleClaimButton();
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 xl:flex-row">
          <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
            {previous}
            <span className="font-semibold text-primary">{previousValue}</span>
            {name && name === 'Claimable Rewards' && (
              <Tooltips
                placement="top-end"
                content={
                  <div className="flex flex-col gap-2 p-3 text-xs bg-white rounded-lg shadow-md">
                    <div>
                      <span className="text-sm">
                        {t(
                          'dynamic.myCommunity.superNode.reports.tooltip.Total Claimed'
                        )}
                        :
                      </span>{' '}
                      <span className="font-medium text-primary">
                        {toFixedWithoutRoundingOff(
                          superNodeTotalRewardsClaimed?.totalClaimedRewards ||
                            0,
                          2
                        )}
                      </span>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <div>
                        <span className="text-sm ">
                          {t(
                            'dynamic.myCommunity.superNode.reports.tooltip.Last Claimed'
                          )}
                          :
                        </span>{' '}
                        <span className="font-medium text-primary">
                          {toFixedWithoutRoundingOff(
                            superNodeTotalRewardsClaimed?.lastClaimedReward ||
                              0,
                            2
                          )}
                        </span>
                      </div>
                      <div className="font-normal text-gray-500">
                        {superNodeTotalRewardsClaimed?.lastClaimedDate}
                      </div>
                    </div>
                  </div>
                }
              >
                <InfoIcon className={`w-4 mb-[2px] h-4 cursor-pointer`} />
              </Tooltips>
            )}
          </div>

          <div className="flex xl:hidden">
            {name && name === 'Claimable Rewards' && (
              <Claim
                claimRewardLoading={claimRewardLoading}
                value={value}
                handleClaimButton={() => {
                  if (dueAmount && dueAmount?.balance > 0) {
                    setOpenReviewModal(true);
                  } else {
                    handleClaimButton();
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      <img
        src={image}
        alt={name}
        className="absolute right-0 top-0 h-full opacity-[.5] z-10"
      />

      <DueAmountModal
        loading={claimRewardLoading}
        amountText="Claim Amount"
        open={openReviewModal}
        onClose={handleReviewModalClose}
        buttonText="Proceed"
        withdrawAmount={value * LYK}
        withdrawTokenAmount={value}
        handleSubmit={handleClaimButton}
      />
    </div>
  );
}

export default SuperNodeMainCard;

const Claim = ({
  claimRewardLoading,
  value,
  handleClaimButton,
}: {
  claimRewardLoading: any;
  value: any;
  handleClaimButton: () => void;
}) => {
  const { t } = useTranslation();
  return claimRewardLoading ? (
    <div className="flex items-center flex-1 h-full text-primary">
      <div className="items-center self-center justify-center">
        <div className="flex flex-col gap-5">
          <Spinner className="h-6 animate-spin" />
        </div>
      </div>
    </div>
  ) : (
    <button
      disabled={FEATURES.enableClaimButton ? Number(value) <= 0 : true}
      onClick={handleClaimButton}
      className={`!rounded-sm px-4 py-2 flex items-center justify-center text-white font-normal text-xs marker: ${
        FEATURES.enableClaimButton
          ? Number(value) <= 0
            ? '!bg-gray-400 cursor-not-allowed'
            : 'bg-gradient-primary-blue'
          : '!bg-gray-400 cursor-not-allowed'
      }`}
    >
      {t('general.Claim')}
    </button>
  );
};
