import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import GlobalButton from '../../../components/Global/GlobalButton';
import { selectTBalance } from '../../../store/TBalance/TBalanceSlice';
import { useTBalanceActions } from '../../../store/TBalance/TBalanceActions';
import GlobalSkeleton from '../../../components/Global/GlobalSkeleton';
import NoData from '../../../components/Table/NoData';

const Products = ({
  openOrderDetailModal,
}: {
  openOrderDetailModal: (product: any) => void;
}) => {
  const containerRef = useRef(null);

  const { TBalanceProducts, isTBalanceProductsLoading } =
    useSelector(selectTBalance);

  const { getTBalanceProducts } = useTBalanceActions();

  const [currentPage, setCurrentPage] = useState<number>(
    TBalanceProducts?.currentPage ?? 1
  );

  const handleScroll = () => {
    const container: any = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      if (TBalanceProducts && currentPage < TBalanceProducts?.totalPages) {
        setCurrentPage(currentPage + 1);
        const res = getTBalanceProducts({
          limit: 10,
          page: currentPage + 1,
        });
      }
    }
  };

  const skeleton = (
    <div className="flex flex-col w-full h-full text-xl bg-white rounded-lg border border-[#6659a2] shadow-md">
      <GlobalSkeleton
        animationValue="wave"
        variant="rectangular"
        width={'100%'}
        height={91}
      />
      <div className="flex flex-col gap-4 p-4 mt-2">
        <div className="flex items-center justify-center gap-4">
          <GlobalSkeleton
            animationValue="wave"
            variant="text"
            sx={{ fontSize: '20px' }}
            width={'30%'}
            height={40}
          />
        </div>
        <GlobalSkeleton
          animationValue="wave"
          variant="rectangular"
          sx={{ borderRadius: '7px' }}
          width={'100%'}
          height={40}
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-3 border border-[#E6E6E6] bg-white rounded-lg">
      <h3 className="px-4 pt-4 text-lg font-medium">Products:</h3>

      <div
        className="relative min-h-[20vh] w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 px-4 overflow-y-scroll custom-scrollbar max-h-[44vh] pb-4 "
        ref={containerRef}
        onScroll={handleScroll}
      >
        {isTBalanceProductsLoading && !TBalanceProducts && (
          <>
            {Array(4)
              .fill('')
              .map((_, i) => (
                <div key={i}>{skeleton}</div>
              ))}
          </>
        )}
        {TBalanceProducts && (
          <>
            {!isTBalanceProductsLoading &&
            TBalanceProducts?.list.length <= 0 ? (
              <div className="absolute w-full mx-auto -top-9">
                <NoData text="No Products Found" visible />
              </div>
            ) : (
              <>
                {TBalanceProducts?.list?.map((product, index) => (
                  <div
                    key={index}
                    className="flex flex-col w-full h-auto gap-4 overflow-hidden text-xl bg-white rounded-lg border border-[#6659a2] shadow-md"
                  >
                    <div className="py-8 bg-gradient-to-r from-gray-800 via-[#6659a2] to-gray-800 w-full text-white flex items-center justify-center">
                      {product.name}
                    </div>
                    <div className="flex flex-col gap-4 p-4">
                      <div className="flex items-center justify-center gap-4">
                        <span>{product.price}</span>
                      </div>
                      <GlobalButton
                        outlined
                        text={'Select'}
                        onClick={() => openOrderDetailModal(product)}
                      />
                    </div>
                  </div>
                ))}
                {isTBalanceProductsLoading && TBalanceProducts && (
                  <>{skeleton}</>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
