import { createSlice } from '@reduxjs/toolkit';
import { IDashbaordInitialState } from '../../types/dashboard.types';

const initialState: IDashbaordInitialState = {
  userCompletionSteps: null,
  isUserCompletionStepsLoading: false,
};
export const DASHBOARD_SLICE_NAME = 'dashbaord';

const dashboardSlice = createSlice({
  name: DASHBOARD_SLICE_NAME,
  initialState,
  reducers: {
    setDashboardSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setDashboardSlice } = dashboardSlice.actions;

export const selectDashboard = (state: {
  [DASHBOARD_SLICE_NAME]: IDashbaordInitialState;
}) => state[DASHBOARD_SLICE_NAME];
export default dashboardSlice.reducer;
